import {useDeselectAll, useResetViewPosition, useSelectAll} from "./commands/index.ts";
import {NodeDropZone, useTempRef} from "#lib/components/index.ts";
import {useInstance, useObservable} from "#lib/qlab/index.ts";
import React, {KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {GameSignals, Node, NodeId, PlayerRef, Scene, SceneFn, SceneOperation} from "common/legends/index.ts";
import {HSLA, Point} from "common/types/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {SceneView} from "./scene-view.tsx";
import {measurementType} from "common/legends/measurement/index.ts";
import {pipe} from "common/pipe";
import {distinct, listIdentity, map, subscribe} from "common/observable";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useEditorSelectedNodeIDs} from "../character/index.ts";
import {contextMenuType} from "../../../routes/game/context-menu/context-menu.ts";
import {usePlayer} from "../../../routes/game/use-player.ts";
import {useToolHandlers} from "../../../routes/game/use-tool-handlers.ts";
import {useSceneFocus} from "../token/use-scene-focus.ts";
import {LegendsWebGL2Canvas, useGame} from "../../../routes/game/index.ts";
import {SelectedNodeIDsProvider} from "../common/context/selected-node-i-ds.ts";
import {ScenePingView} from "../common/hud-pass/scene-ping.tsx";
import {MeasurementView} from "../common/hud-pass/measurement/measurement-view.tsx";
import {SceneMeasurementView} from "../common/hud-pass/scene-measurement.tsx";
import {useTypedResource} from "#lib/qlab/react/hooks/resource/use-typed-resource.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {SceneViewportPropertiesSignals} from "./scene-viewport-properties.ts";
import {useSceneViewportProperties} from "./use-scene-viewport-properties.ts";
import {ToolView} from "../common/hud-pass/tool/tool-view.tsx";
import {useSuspendPresent} from "../../common/use-optional-signal.ts";
import {MutableRef} from "common/ref";
import {CurrentSceneProvider} from "./current-scene-provider.ts";
import {GridProvider} from "../common/context/grid-context.ts";
import {SceneSelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {useEditorVision} from "../../panel/nav/editor/use-editor-vision.ts";
import {useEditorTool} from "../../panel/nav/common/tool/tool-selector/use-editor-tool.ts";
import {useGoToNode} from "../../panel/nav/editor/use-go-to-node.ts";
import {EditorProvider, useEditor} from "../../panel/nav/editor/editor-context.ts";
import {EditorContextMenu} from "../../panel/nav/editor/editor-context-menu/editor-context-menu.tsx";
import {ProjectionProvider, ViewProvider} from "../common/context/pvm-context.ts";
import {useVision} from "../common/context/use-vision.ts";
import {VisionFn} from "common/legends/asset/token/vision/vision.ts";
import {useActiveNodeID} from "../common/context/use-active-node-id.ts";
import {ControllerNodeIDProvider} from "../common/context/controller-node-context.ts";

export const SceneViewport = React.memo(function SceneViewport() {
  const sceneEditorRef = useSuspendPresent(useSceneViewportProperties());
  const sceneReference = useComputedValue(sceneEditorRef, (t) => t.sceneReference);
  const scene = useSuspendPresent(useTypedResource("scene", sceneReference?.sceneID));
  return <LoadedSceneViewport scene={scene}/>;
});

type LoadedSceneViewportProps = {
  scene: MutableRef<Scene, SceneOperation[]>;
};

function LoadedSceneViewport({scene}: LoadedSceneViewportProps) {
  const sceneEditorRef = useSuspendPresent(useSceneViewportProperties());
  const sceneEditor = useRefValue(sceneEditorRef);
  const [size, setSize] = useState<[number, number]>([0, 0]);
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const element = divRef?.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver(() => {
      setSize([element.clientWidth, element.clientHeight]);
    });
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  }, [setSize]);

  const sceneValue = useRefValue(scene);
  const {children} = useMemo(() => SceneFn.expand(scene), [scene]);

  const {view} = useMemo(() => SceneViewportPropertiesSignals(sceneEditorRef), [sceneEditorRef]);

  const selectedNodeIDsRef = useEditorSelectedNodeIDs();

  const rootSelectionRef = useMemo((): SceneSelectionRef => ({
    type: "scene",
    storeId: sceneEditor.sceneReference.gameID,
    resourceId: sceneEditor.sceneReference.sceneID
  }), [sceneEditor.sceneReference]);

  const instance = useInstance();
  const ping = useCallback((p: Point, focus: boolean, color: HSLA) => {
    instance.stream(sceneEditor.sceneReference.gameID, {type: "ping", data: {
      type: "scene",
      sceneID: sceneEditor.sceneReference.sceneID,
      position: p,
      focus,
      color
    }}).catch(console.error);
  }, [instance, sceneEditor.sceneReference]);
  const measurement = useTempRef(measurementType, undefined);

  const editorVisionRef = useEditorVision();

  const activeNodeIDRef = useActiveNodeID(editorVisionRef, selectedNodeIDsRef);
  const accessNodeIDs = useObservable(pipe(
    activeNodeIDRef.observe,
    map((nodeID): NodeId[] => nodeID !== undefined ? [nodeID] : []),
    distinct(listIdentity)
  ), [], [activeNodeIDRef]);
  const visionRef = useVision(editorVisionRef, activeNodeIDRef);

  const toolMode = useEditorTool();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const contextMenu = useTempRef(contextMenuType, undefined);

  const player = usePlayer(useUserID()!);
  const {color} = useMemo(() => PlayerRef(player), [player]);
  const isAccessible = useCallback((node: Node) => {
    return node.type === "wall" || node.type === "area" || (node.data.selectionMask & editorVisionRef.value.mask) > 0 || accessNodeIDs.includes(node.data.id) || (node.type === "token" && node.data.ownerIDs.length > 0);
  }, [editorVisionRef, accessNodeIDs]);

  const vision = useRefValue(visionRef);
  const isVisible = useCallback((node: Node) => visionRef.value.some(vision => VisionFn.canSee(vision, node)), [visionRef]);
  const {onDrop, onWheel, onTouchStart, onTouchMove, onTouchEnd, ...toolHandlers} = useToolHandlers(
    canvasRef,
    rootSelectionRef,
    view,
    measurement,
    sceneValue.grid,
    children,
    selectedNodeIDsRef,
    activeNodeIDRef,
    toolMode,
    ping,
    useRefValue(color),
    contextMenu,
    isAccessible,
    isVisible
  );

  const selectAll = useSelectAll(rootSelectionRef, sceneValue, sceneEditorRef.apply);
  const deselectAll = useDeselectAll(sceneEditorRef.apply);
  const resetViewPosition = useResetViewPosition(sceneEditorRef.apply);

  const onKeyUp = useCallback((ev: KeyboardEvent<HTMLCanvasElement>) => {
    if (!toolHandlers.onKeyUp(ev)) return false;
    const {ctrlKey, code} = ev;
    if (ctrlKey && code === "KeyA") {
      ev.preventDefault();
      selectAll();
    } else if (ctrlKey && code === "KeyD") {
      ev.preventDefault();
      deselectAll();
    } else if (code === "Escape") {
      ev.preventDefault();
      deselectAll();
    } else if (code === "Space") {
      ev.preventDefault();
      resetViewPosition();
    }
  }, [selectAll, deselectAll, resetViewPosition, toolHandlers.onKeyUp]);

  const projection = useMemo<Matrix4f>(() => Matrix4f.orthogonal(
    -size[0]/2, size[0]/2,
    size[1]/2, -size[1]/2,
    -100, 100
  ), [size]);

  useSceneFocus(sceneEditor.sceneReference.gameID, sceneEditor.sceneReference.sceneID, view.apply);

  const game = useGame();
  const {turnTracker} = useMemo(() => GameSignals(game), [game]);
  const goToNode = useGoToNode();
  useEffect(() => pipe(
    turnTracker.observe,
    map(resolvedTurnTracker => {
      if (resolvedTurnTracker.currentTurnID === undefined) return undefined;
      const turn = resolvedTurnTracker.turns[resolvedTurnTracker.currentTurnID];
      if (turn?.type === "node") return turn.data.nodeID;
      return undefined
    }),
    distinct(),
    subscribe(nodeID => nodeID && goToNode(nodeID))
  ), [turnTracker, goToNode]);

  useEffect(() => {
    const onBodyWheel = (ev: WheelEvent) => {
      if (ev.ctrlKey) return onWheel(ev);
      return true;
    };
    document.body.addEventListener("wheel", onBodyWheel, {passive: false});
    canvasRef.current?.addEventListener("wheel", onWheel, {passive: false});
    canvasRef.current?.addEventListener("touchstart", onTouchStart, {passive: false});
    canvasRef.current?.addEventListener("touchend", onTouchEnd, {passive: false});
    canvasRef.current?.addEventListener("touchcancel", onTouchEnd, {passive: false});
    canvasRef.current?.addEventListener("touchmove", onTouchMove, {passive: false});
    return () => {
      document.body.removeEventListener("wheel", onBodyWheel);
      canvasRef.current?.removeEventListener("wheel", onWheel);
      canvasRef.current?.removeEventListener("touchstart", onTouchStart);
      canvasRef.current?.removeEventListener("touchend", onTouchEnd);
      canvasRef.current?.removeEventListener("touchmove", onTouchMove);
      canvasRef.current?.removeEventListener("touchcancel", onTouchEnd);
    }
  }, [canvasRef, onWheel, onTouchStart, onTouchMove, onTouchEnd]);

  return (<div className="flex-1 overflow-hidden" ref={divRef}>
    <CurrentSceneProvider value={sceneEditor.sceneReference.sceneID}>
      <NodeDropZone storeId={sceneEditor.sceneReference.gameID} onDrop={onDrop} contextMenu={contextMenu}>
        <LegendsWebGL2Canvas width={size[0]} height={size[1]} ref={canvasRef} {...toolHandlers} tabIndex={0} onKeyUp={onKeyUp}>
          <EditorProvider value={useEditor()}>
            <ControllerNodeIDProvider value={useRefValue(activeNodeIDRef)}>
              <SelectedNodeIDsProvider value={selectedNodeIDsRef}>
                <GridProvider value={sceneValue.grid}>
                  <ProjectionProvider value={projection}>
                    <ViewProvider value={sceneEditor.view}>
                      <SceneView
                        vision={vision}
                        scene={sceneValue} />
                      <ScenePingView gameID={sceneEditor.sceneReference.gameID} sceneID={sceneEditor.sceneReference.sceneID} />
                      <MeasurementView measurementRef={measurement} />
                      <SceneMeasurementView gameID={sceneEditor.sceneReference.gameID} sceneID={sceneEditor.sceneReference.sceneID} scene={sceneValue} vision={vision} />
                      <ToolView value={toolMode} nodes={children} />
                    </ViewProvider>
                  </ProjectionProvider>
                </GridProvider>
              </SelectedNodeIDsProvider>
            </ControllerNodeIDProvider>
          </EditorProvider>
        </LegendsWebGL2Canvas>
      </NodeDropZone>
      <EditorContextMenu value={contextMenu} onDrop={onDrop} />
    </CurrentSceneProvider>
  </div>);
}
