import React, {PropsWithChildren, useContext, useMemo} from "react";
import {Observable, Observer} from "common/observable";
import {useRenderingContext} from "#lib/gl-react/index.ts";
import {FileReference} from "common/types/index.ts";

export class VideoTextureManager {
  private textures: {[url: NonNullable<FileReference>]: Promise<[WebGLTexture, () => void]>} = {};
  private observers: {[url: NonNullable<FileReference>]: Observer<WebGLTexture>[]} = {};

  constructor(private readonly context: WebGL2RenderingContext) {}

  videoTextureObservable(url: NonNullable<FileReference>): Observable<WebGLTexture> {
    return (observer) => {
      if (!this.textures[url]) this.textures[url] = this.loadImage(url, (texture) => {
        this.observers[url].forEach(observer => setTimeout(() => observer.next(texture), 0))
      });
      this.textures[url].then(([texture]) => observer.next(texture));

      if (!this.observers[url]) this.observers[url] = [];
      this.observers[url].push(observer);

      return () => {
        const index = this.observers[url].indexOf(observer);
        if (index === -1) return;
        this.observers[url].splice(index, 1);
        if (this.observers[url].length === 0) {
          // Clean up asset if it isn't used for 5 seconds
          setTimeout(() => {
            if (this.observers[url] === undefined || this.observers[url].length !== 0) return;
            this.textures[url].then(([_, close]) => close());
            delete this.textures[url];
            delete this.observers[url];
          }, 5000);
        }
      };
    };
  }

  private loadImage(url: NonNullable<FileReference>, onUpdate: (texture: WebGLTexture) => void): Promise<[WebGLTexture, () => void]> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = url;
      video.autoplay = true;
      video.loop = true;
      video.muted = true;
      video.playsInline = true;
      video.crossOrigin = "anonymous";
      video.play();

      const texture = this.context.createTexture()!;
      const bindImageToTexture = () => {
        this.context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, texture);
        this.context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_WRAP_S, WebGL2RenderingContext.REPEAT);
        this.context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_WRAP_T, WebGL2RenderingContext.REPEAT);
        this.context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MIN_FILTER, WebGL2RenderingContext.LINEAR);
        this.context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MAG_FILTER, WebGL2RenderingContext.NEAREST);

        let updateHandler = -1;
        const update = () => {
          this.context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, texture);
          this.context.texImage2D(WebGL2RenderingContext.TEXTURE_2D, 0, WebGL2RenderingContext.RGBA, WebGL2RenderingContext.RGBA, WebGL2RenderingContext.UNSIGNED_BYTE, video);
          onUpdate(texture);
          updateHandler = requestAnimationFrame(update);
        };
        updateHandler = requestAnimationFrame(update);

        resolve([texture, () => {
          cancelAnimationFrame(updateHandler);
          this.context.deleteTexture(texture);
        }]);
      }
      video.addEventListener('canplay', bindImageToTexture);
      video.addEventListener('error', reject);
    });
  }
}

const VideoTextureManagerContext = React.createContext<VideoTextureManager | undefined>(undefined);
export function VideoTextureManagerProvider({children}: PropsWithChildren<object>) {
  const context = useRenderingContext();
  const textureManager = useMemo(() => new VideoTextureManager(context), [context]);
  return <VideoTextureManagerContext.Provider value={textureManager}>
    {children}
  </VideoTextureManagerContext.Provider>
}

export function useVideoTextureManager() {
  return useContext(VideoTextureManagerContext)!;
}
