import {HSLA, Transform} from "common/types/index.ts";
import React from "react";
import {AreaNode} from "common/legends/node/area-node.ts";
import {ModelProvider, useModel} from "../../context/pvm-context.ts";
import {OpacityProvider, useOpacity} from "../../context/opacity-context.ts";
import {ElementHUDPass} from "./element-h-u-d-pass.tsx";
import {AreaShader} from "../../shader/shape/area-shader.tsx";
import {SplineFn} from "common/types/generic/spline/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {useIsTokenController} from "../../context/use-is-token-controller.ts";

export function AreaElementHUDPass({value}: {
  value: AreaNode;
}) {
  const isTokenController = useIsTokenController();
  const valueOpacity = value.opacity * useOpacity();
  const model = useModel();
  let valueModel = Transform.divide(value.transform, model);
  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        {!isTokenController && value.areas.map((area, index) =>
          <AreaShader key={index} origin={value.origin} spline={SplineFn.map(area, (spline) => Vector2.multiplyMatrix4x4(spline, Transform.toMatrix4x4(value.transform)))} scale={1} color={[value.color[0], value.color[1], value.color[2], valueOpacity] as HSLA} />
        )}
        {[...value.children].reverse().map((child) => <ElementHUDPass key={child.data.id} value={child} />)}
      </ModelProvider>
    </OpacityProvider>
  </binder>);
}