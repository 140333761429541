import React, {useMemo} from "react";
import {Color, HSLA, Point} from "common/types/generic/index.ts";
import {LightShapeSprite} from "common/legends/node/light/light-shape-sprite.ts";
import {ImageLightShader} from "../../../shader/light/image-light-shader.tsx";
import {useOpacity} from "../../../context/opacity-context.ts";
import {useImageTexture} from "../../../context/use-image-texture.ts";
import {WallShader} from "../../../shader/wall-shader.tsx";
import {WallLightBlend} from "./wall-light-blend.tsx";
import {WallTintShader} from "../../../shader/wall-tint-shader.tsx";
import {LightTextureShader} from "./light-texture-shader.tsx";

export function LightShapeSpriteLightPass({value, origin}: {
  value: LightShapeSprite,
  origin: Point
}) {
  const [loaded, texture] = useImageTexture(value.file);
  const opacity = useOpacity();
  const tint = useMemo(() => [value.color[0], value.color[1], value.color[2], value.color[3] * opacity * value.intensity] as HSLA, [value.color, value.intensity, opacity]);
  if (!loaded) return <></>;
  return (<LightTextureShader>
    <ImageLightShader size={value.size} origin={origin} texture={texture} tint={tint}/>
    <WallTintShader origin={[0, 0]} />
    <WallLightBlend>
      <WallShader origin={[0, 0]} color={Color.CLEAR} />
    </WallLightBlend>
  </LightTextureShader>);
}

