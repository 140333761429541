import {Menu} from "@headlessui/react";
import {Button, IconButton} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import ReactDOM from "react-dom";
import {Popper} from "#lib/components/popper/popper.tsx";
import {usePortal} from "#lib/container/react/external-window/external-portal.tsx";
import React, {useCallback, useState} from "react";
import {ListOperation} from "common/types/generic/index.ts";
import {generateNodeConditionID} from "common/legends/node/condition/node-condition-i-d.ts";
import {usePopper} from "react-popper";
import {MutableRef} from "common/ref";
import {NodeCondition, NodeConditionOperation} from "common/legends/node/condition/node-condition.ts";

export function AddConditionButton({valueRef}: {
  valueRef: MutableRef<NodeCondition[], ListOperation<NodeCondition, NodeConditionOperation>[]>;
}) {
  const addCondition = useCallback((condition: NodeCondition) => valueRef.apply(prev => ListOperation.insert(prev.length, condition)), [valueRef]);
  const addEffectCondition = useCallback(() => addCondition({
    type: "effect",
    data: {
      conditionID: generateNodeConditionID(),
      effectID: undefined
    }
  }), [addCondition]);
  const addSelfCondition = useCallback(() => addCondition({
    type: "self",
    data: {
      conditionID: generateNodeConditionID()
    }
  }), [addCondition]);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    placement: "bottom-end"
  });

  return (<Menu>
    <Menu.Button as={IconButton} ref={ref => setReferenceElement(ref)} title="Add Condition"><FaPlus/></Menu.Button>
    {ReactDOM.createPortal(<Menu.Items as={Popper} ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper}>
      <Menu.Item as={Button} onClick={addEffectCondition}>Visible on Effect</Menu.Item>
      <Menu.Item as={Button} onClick={addSelfCondition}>Visible to Self Only</Menu.Item>
    </Menu.Items>, usePortal())}
  </Menu>)
}