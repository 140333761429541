import {MutableRef} from "common/ref";
import {LightShapeGlobal, LightShapeGlobalOperation, LightShapeGlobalRef} from "common/legends/node/light/light-shape-global.ts";
import {InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {useMemo} from "react";
import {ColorField} from "#lib/components/input/color-field.tsx";

export function LightShapeGlobalFields({valueRef}: {
  valueRef: MutableRef<LightShapeGlobal, LightShapeGlobalOperation[]>
}) {
  const {colorRef, intensityRef} = useMemo(() => LightShapeGlobalRef(valueRef), [valueRef]);

  return <>
    <InputGroup>
      <InputGroupLabel>Color</InputGroupLabel>
      <ColorField className="flex-1" value={colorRef} />
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>Intensity</InputGroupLabel>
      <InputNumber value={intensityRef} />
    </InputGroup>
  </>
}