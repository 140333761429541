import {MapFn, MapSignals, NumberFn, ValueFn, ValueOperation} from "common/types/index.ts";
import {FaBackward, FaBroadcastTower, FaPlus, FaTrash} from "react-icons/fa";
import {Button, ButtonBar, IconButton} from "#lib/components/index.ts";
import {useCallback, useMemo} from "react";
import {GameSignals} from "common/legends/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {generateTurnID, TurnID, TurnTracker, TurnTrackerFn, TurnTrackerOperation, TurnTrackerSignals} from "common/legends/game/turn-tracker/index.ts";
import {FaDiceD20} from "react-icons/fa6";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {useEndTurn} from "../../../common/turn-tracker/use-end-turn.ts";
import {useRequestInitiative} from "../../../../routes/game/use-request-initiative.ts";
import {useSelectedNodeID, useSelectedSheet} from "../../sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {useRollAbilityCheck} from "../../sheet/editor/dnd-5e-character/dnd-5e-action/use-roll-ability-check.ts";
import {EditorTurnView} from "./turn/editor-turn-view.tsx";
import {useGame} from "../../../../routes/game/index.ts";
import {useRefValue} from "#lib/signal/index.ts";

export function EditorTurnTrackerView() {
  const game = useGame();
  const {turnTracker} = useMemo(() => GameSignals(game), [game]);
  const {turns} = useMemo(() => TurnTrackerSignals(turnTracker), [turnTracker]);
  const turnEntities = useRefValue(useMemo(() => MapSignals.expand(turns), [turns]));
  const resolvedTurnTracker = useRefValue(turnTracker);

  const stopTrackingTurns = useCallback(() => {
    turnTracker.apply(prev => {
      return ValueFn.set(prev, {
        round: 0,
        turns: {},
        currentTurnID: undefined
      });
    });
  }, [turnTracker]);
  const resetTracker = useCallback(() => {
    turnTracker.apply(prev => {
      return ValueFn.apply([
        {type: "update-round", operations: NumberFn.set(prev.round, 0)},
        {type: "update-current-turn-i-d", operations: ValueFn.set(prev.currentTurnID, undefined)}
      ]);
    });
  }, [turnTracker])

  const orderedTurns = TurnTrackerFn.orderedTurnIDs(resolvedTurnTracker?.turns || {});
  const nextTurn = useEndTurn(turnTracker);
  const removeTurn = useCallback((turnID: TurnID) => {
    turnTracker.apply(prev => {
      const operations: ValueOperation<TurnTracker, TurnTrackerOperation>[] = [];
      if (prev.currentTurnID === turnID) {
        const orderedTurns = TurnTrackerFn.orderedTurnIDs(prev.turns);
        const index = orderedTurns.indexOf(turnID);
        const nextIndex = (index + 1) % orderedTurns.length;
        if (nextIndex === 0) {
          operations.push(...ValueFn.apply<TurnTracker, TurnTrackerOperation>([{
            type: "update-round",
            operations: NumberFn.increment(1)
          }]));
        }
        if (index === nextIndex) {
          operations.push(...ValueFn.apply<TurnTracker, TurnTrackerOperation>([{
            type: "update-current-turn-i-d",
            operations: ValueFn.set(turnID, undefined)
          }, {
            type: "update-round",
            operations: NumberFn.set(prev.round, 0)
          }]));
        } else {
          operations.push(...ValueFn.apply<TurnTracker, TurnTrackerOperation>([{
            type: "update-current-turn-i-d",
            operations: ValueFn.set(turnID, orderedTurns[nextIndex])
          }]));
        }
      }
      operations.push(...ValueFn.apply<TurnTracker, TurnTrackerOperation>([{
        type: "update-turns",
        operations: MapFn.delete(turnID, prev.turns[turnID]!)
      }]));
      return operations;
    });
  }, [turnTracker]);

  const addMarker = useCallback(() => turnTracker.apply(prev => {
    if (prev === undefined) return [];
    const turnID = generateTurnID();
    return ValueFn.apply([{
      type: "update-turns",
      operations: [{
        type: "put",
        key: turnID,
        item: {
          type: "marker",
          data: {
            label: "Marker",
            order: 20
          }
        }
      }]
    }]);
  }), [turnTracker]);

  const requestInitiative = useRequestInitiative();

  const callForInitiative = useCallback(() => {
    requestInitiative();
  }, [requestInitiative]);

  const selectedCharacter = useSelectedSheet();
  const isCharacterSelected = useObservable(pipe(selectedCharacter.observe, map(c => c !== undefined), distinct()), false, [selectedCharacter.observe]);
  const rollAbility = useRollAbilityCheck(useSelectedNodeID(), selectedCharacter);

  const makeActiveTurn = useCallback((turnID: TurnID) => {
    turnTracker.apply(prev => ValueFn.apply([{
      type: "update-current-turn-i-d", operations: ValueFn.set(prev.currentTurnID, turnID)
    }]));
  }, [turnTracker]);

  return <div className="flex flex-col flex-1">
    <ButtonBar className="m-2 rounded-lg overflow-hidden backdrop-blur-sm pointer-events-auto min-w-[170px]">
      <IconButton size="small" title="Add Marker" onClick={() => addMarker()}><FaPlus /></IconButton>
      <IconButton disabled={!isCharacterSelected} size="small" onClick={ev => rollAbility("initiative", ev.shiftKey, ev.ctrlKey)} title="Roll Initiative"><FaDiceD20 /></IconButton>
      <IconButton size="small" title="Request Initiative" onClick={() => callForInitiative()}><FaBroadcastTower /></IconButton>
      {resolvedTurnTracker.round === 0
        ? <span className="flex-1 flex items-center justify-center font-bold whitespace-nowrap overflow-hidden"></span>
        : <span className="flex-1 flex items-center justify-center font-bold whitespace-nowrap overflow-hidden">Round {resolvedTurnTracker.round}</span>}
      <IconButton size="small" disabled={resolvedTurnTracker.currentTurnID === undefined} onClick={resetTracker} title="Reset Tracker"><FaBackward /></IconButton>
      <IconButton size="small" variant="destructive" disabled={Object.keys(resolvedTurnTracker.turns).length === 0} onClick={stopTrackingTurns} title="Clear Tracker"><FaTrash /></IconButton>
    </ButtonBar>

    <div className="flex-1 tab-content gap-1 flex flex-col pl-2">
      {orderedTurns
        .filter(TurnTrackerFn.isInRound(orderedTurns, resolvedTurnTracker.currentTurnID))
        .map((turnID) => <EditorTurnView isCurrentTurn={turnID === resolvedTurnTracker.currentTurnID} key={turnID} value={turnEntities[turnID]!} remove={() => removeTurn(turnID)} makeActiveTurn={() => makeActiveTurn(turnID)} />)}
      <div className="pointer-events-auto backdrop-blur-sm shrink-0 h-4 bg-zinc-900/80 flex items-center justify-center text-xs italic text-white/60 rounded-lg">Round {resolvedTurnTracker.round+1}</div>
      {orderedTurns
        .filter(TurnTrackerFn.isNextRound(orderedTurns, resolvedTurnTracker.currentTurnID))
        .map((turnID) => <EditorTurnView isCurrentTurn={false} key={turnID} value={turnEntities[turnID]!} remove={() => removeTurn(turnID)} makeActiveTurn={() => makeActiveTurn(turnID)} />)}
    </div>
    <div className="m-2 flex flex-row gap-2">
      <Button className="w-full rounded-lg" onClick={nextTurn} disabled={orderedTurns.length === 0}>{resolvedTurnTracker.currentTurnID === undefined ? "Start" : "End Turn"}</Button>
    </div>
  </div>;
}