import React, {useMemo} from "react";
import {Dnd5eSkill, DND_5E_SKILL_TITLE} from "common/legends/index.ts";
import {SkillProficiency} from "./skill-proficiency/index.ts";
import {ButtonBar, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {useSheetReference} from "../../../../common/sheet/sheet-reference-context.ts";
import {useRollAbilityCheck} from "./dnd-5e-action/use-roll-ability-check.ts";
import {useSelectedNodeID} from "./use-selected-sheet.ts";
import {
  dnd5eSheetAbilityCheckHasAdvantage,
  dnd5eSheetAbilityCheckHasDisadvantage,
  dnd5eSheetAbilityCheckProficiency
} from "../dnd-5e/common/dnd-5e-sheet-ability-check-proficiency.ts";
import {useGlobalFeatures} from "./dnd-5e-action/use-global-features.ts";
import {dnd5eSheetAbilityCheckModifiers} from "../dnd-5e/common/dnd-5e-sheet-ability-check-modifiers.ts";
import {Dice} from "common/dice/index.ts";
import {DiceButton} from "#lib/components/button/dice-button.tsx";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {sheetVariablesSignal} from "common/legends/asset/sheet/dnd-5e/dnd-5e-variable/sheet-variable-signal.ts";

export type SkillFieldProps = {
  skill: Dnd5eSkill;
};

export function SkillField({skill}: SkillFieldProps) {
  const sheet = useSheetSignal(useSheetReference());
  const globalEffects = useGlobalFeatures();
  const proficiency = useObservable(dnd5eSheetAbilityCheckProficiency(sheet, globalEffects.observe, skill), "untrained", [sheet, globalEffects, skill]);
  const hasAdvantage = useObservable(dnd5eSheetAbilityCheckHasAdvantage(sheet, globalEffects.observe, skill), false, [sheet, globalEffects, skill]);
  const hasDisadvantage = useObservable(dnd5eSheetAbilityCheckHasDisadvantage(sheet, globalEffects.observe, skill), false, [sheet, globalEffects, skill]);

  const onClick = useRollAbilityCheck(useSelectedNodeID(), sheet);
  const skillModifier = useObservable(dnd5eSheetAbilityCheckModifiers(sheet, globalEffects.observe, skill), undefined, [sheet, globalEffects, skill]);
  const contextSignal = useMemo(() => sheetVariablesSignal(sheet, globalEffects), [sheet, globalEffects]);
  const contextValue = useRefValue(contextSignal);
  const resolvedSkillModifier = useMemo(() => skillModifier ? Dice.toResolvedExpression(skillModifier, contextValue) : undefined, [skillModifier, contextValue]);

  const range = useMemo((): [number, number] => skillModifier ? Dice.getRollRange(skillModifier, contextValue) : [0, 0], [skillModifier, contextValue]);

  return (<ButtonBar>
    <InputGroup size="small" className="flex-1 px-2 gap-2">
      <SkillProficiency value={proficiency}/>
      <InputGroupLabel className="flex-1 basis-0 shrink text-ellipsis overflow-hidden whitespace-nowrap text-sm">{DND_5E_SKILL_TITLE[skill]}</InputGroupLabel>
      <span className="shrink-0 basis-6 text-xs italic font-bold text-white/50 text-right whitespace-nowrap" title={resolvedSkillModifier ? resolvedSkillModifier : "0"}>
        {Dice.toRangeDisplay(range)}
      </span>
    </InputGroup>
    <DiceButton hasAdvantage={hasAdvantage} hasDisadvantage={hasDisadvantage} onClick={ev => onClick(skill, ev.shiftKey, ev.ctrlKey)} />
  </ButtonBar>);
}
