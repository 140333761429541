import {KeyboardEvent, MouseEvent, RefObject, useCallback} from "react";
import {HSLA, Optional, Point, SetOperation, Transform, TransformOperation, TreeOperation} from "common/types/index.ts";
import {usePanHandlers} from "./use-pan-handlers.ts";
import {usePingHandlers} from "./use-ping-handlers.ts";
import {useZoomHandlers} from "./use-zoom-tool-handlers.ts";
import {useDropHandlers} from "./use-drop-handlers.ts";
import {useTouchHandlers} from "./use-touch-handlers.ts";
import {onMovementHandlers} from "./use-movement-handlers.ts";
import {Grid, Node, NodeId, NodeOperation} from "common/legends/index.ts";
import {MutableRef, Ref} from "common/ref";
import {NodeSelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";

export function usePanToolHandlers(
  canvasRef: RefObject<HTMLCanvasElement>,
  view: MutableRef<Transform, TransformOperation[]>,
  grid: Grid,
  nodes: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>,
  activeNodeIdRef: Ref<Optional<NodeId>>,
  selectedNodeIds: MutableRef<NodeSelectionRef[], SetOperation<NodeSelectionRef>[]>,
  ping: (p: Point, focus: boolean, color: HSLA) => void,
  color: HSLA,
  isAccessible: (node: Node) => boolean,
  isVisible: (node: Node) => boolean
) {
  const {onPanMouseDown, onPanMouseMove, onPanMouseUp, onPanMouseEnter, onPanWheel, onPanContextMenu} = usePanHandlers(canvasRef, view, buttons => buttons !== 0);
  const {onPingMouseDown, onPingMouseMove, onPingMouseUp} = usePingHandlers(canvasRef, view, ping, color);
  const {onZoomWheel, onZoomKeyDown} = useZoomHandlers(canvasRef, view);
  const {onTouchStart, onTouchMove, onTouchEnd} = useTouchHandlers(canvasRef, view);
  const {onMovementKeyUp} = onMovementHandlers(activeNodeIdRef, selectedNodeIds, grid, nodes, isAccessible, isVisible, view);

  const onMouseDown = useCallback((ev: MouseEvent<HTMLCanvasElement>) => {
    if (!onPanMouseDown(ev)) return false;
    return true;
  }, [onPanMouseDown, onPingMouseDown]);
  const onMouseMove = useCallback((ev: MouseEvent<HTMLCanvasElement>) => {
    if (!onPanMouseMove(ev)) return false;
    return true;
  }, [canvasRef, onPanMouseMove, onPingMouseMove, onPanMouseMove]);
  const onMouseUp = useCallback((ev: MouseEvent<HTMLCanvasElement>) => {
    if (!onPanMouseUp(ev)) return false;
    return true;
  }, [onPingMouseUp, onPanMouseUp]);
  const onKeyDown = useCallback((ev: KeyboardEvent) => {
    if (!onZoomKeyDown(ev)) return false;
    return true;
  }, [onMovementKeyUp]);
  const onKeyUp = useCallback((_: KeyboardEvent) => {
    return true;
  }, [onMovementKeyUp]);
  const onContextMenu = useCallback((ev: MouseEvent) => {
    if (!onPanContextMenu(ev)) return false;
  }, [onPanContextMenu]);
  const onWheel = useCallback((ev: WheelEvent) => {
    if (!onZoomWheel(ev)) return false;
    if (!onPanWheel(ev)) return false;
    return true;
  }, [onZoomWheel, onPanWheel]);

  const {onDrop} = useDropHandlers(view, nodes);
  return {
    onMouseUp,
    onMouseMove,
    onMouseDown,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onContextMenu,
    onMouseEnter: onPanMouseEnter,
    onWheel,
    onKeyDown,
    onKeyUp,
    onDrop
  };
}
