import {TokenVisionLimit, TokenVisionLimitFn, TokenVisionLimitOperation} from "common/legends/asset/token/token-vision-limit.ts";
import {MutableRef} from "common/ref";
import {InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {useMemo} from "react";

export function VisionLimitFields({valueRef}: {
  valueRef: MutableRef<TokenVisionLimit, TokenVisionLimitOperation[]>
}) {
  const {distanceRef, falloffRef, falloffStrengthRef} = useMemo(() => TokenVisionLimitFn.expand(valueRef), [valueRef])

  return <>
    <InputGroup>
      <InputGroupLabel>Distance</InputGroupLabel>
      <InputNumber value={distanceRef} />
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>Falloff Distance</InputGroupLabel>
      <InputNumber value={falloffRef} />
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>Falloff Strength</InputGroupLabel>
      <InputNumber value={falloffStrengthRef} />
    </InputGroup>
  </>;
}
