import {useCallback} from "react";
import {SceneID} from "common/legends/index.ts";
import {Transform, ValueFn} from "common/types/index.ts";
import {AccessMaskFn} from "common/legends/visibility/index.ts";
import {useDatabase, useStoreID} from "../../../../routes/game/model/store-context.tsx";
import {ToolFn} from "../../../common/tool/tool.ts";
import {useEditor} from "../../../panel/nav/editor/editor-context.ts";

export function useNavigateToSceneEditor() {
  const databaseRef = useDatabase();
  const editor = useEditor();
  const gameID = useStoreID();
  return useCallback(async (sceneID: SceneID) => {
    const scene = databaseRef.value.resources[sceneID];
    if (scene?.type !== "scene") return;
    editor.state.apply(state => ValueFn.set(state, {
      type: "scene",
      data: {
        sceneReference: {gameID, sceneID},
        toolMode: state?.data.toolMode || ToolFn.DEFAULT,
        selectedNodeIds: [],
        vision: state?.data.vision || {mask: AccessMaskFn.DEFAULT, override: false},
        view: Transform.DEFAULT
      }
    }));
  }, [editor, databaseRef, gameID])
}
