import React, {Suspense, useMemo} from "react";
import {QLabStoreID} from "common/qlab/index.ts";
import {EditorGameContainer} from "./editor-game-container/editor-game-container.tsx";
import {EditorController} from "./editor-controller.ts";
import {EditorProvider} from "./editor-context.ts";
import {GameSignals} from "common/legends/index.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {WallEditorContainer} from "../common/tool/wall-editor/wall-editor-container.tsx";
import {AreaEditorContainer} from "../common/tool/area-editor/area-editor-container.tsx";
import {useGame} from "../../../../routes/game";
import {Beyond20Integration} from "../../../../routes/game/view/main/beyond-20-integration.tsx";
import {SceneViewport} from "../../../viewport/scene";
import {AssetViewport} from "../../../viewport/character";

export type EditorViewProps = {
  storeId: QLabStoreID;
};

const TOOL_MODE_CONTAINER: {[type: string]: React.ElementType} = {
  "wall": WallEditorContainer,
  "area": AreaEditorContainer
};

export function EditorView({storeId}: EditorViewProps) {
  const editor = useMemo(() => new EditorController(storeId), [storeId]);
  const type = useRefValue(editor.state)?.type;
  const game = useGame();
  const {xCard} = useMemo(() => GameSignals(game), [game]);
  const xCardActivated = useRefValue(xCard);

  const toolType = useComputedValue(editor.state, state => state?.data.toolMode.type);
  const Container = TOOL_MODE_CONTAINER[toolType || "selection"] ?? EditorGameContainer;

  if (type === "scene") {
    return (
      <EditorProvider value={editor}>
        <Beyond20Integration/>
        <Container>
          <Suspense fallback={<></>}>
            {!xCardActivated && <SceneViewport />}
            {xCardActivated && <span className="flex-1 text-red-600 font-bold text-[80vh] flex items-center justify-center text-center select-none">
            X
          </span>}
          </Suspense>
        </Container>
      </EditorProvider>
    );
  } else if (type === "asset") {
    return (
      <EditorProvider value={editor}>
        <Beyond20Integration/>
        <Container>
          <Suspense fallback={<></>}>
            {!xCardActivated && <AssetViewport />}
            {xCardActivated && <span className="flex-1 text-red-600 font-bold text-[80vh] flex items-center justify-center text-center select-none">
              X
            </span>}
          </Suspense>
        </Container>
      </EditorProvider>
    );
  } else {
    return (
      <EditorProvider value={editor}>
        <Beyond20Integration/>
        {!xCardActivated && <Container />}
        {xCardActivated && <span className="flex-1 text-red-600 font-bold text-[80vh] flex items-center justify-center text-center select-none">
            X
        </span>}
      </EditorProvider>
    );
  }
}
