import {Transform} from "common/types/index.ts";
import React, {useCallback, useMemo} from "react";
import {LightNode} from "common/legends/node/light/light-node.ts";
import {LightShapeGlobalLightPass} from "./light-shape-global-light-pass.tsx";
import {LightShapeSpotlightLightPass} from "./light-shape-spotlight-light-pass.tsx";
import {LightShapeSpriteLightPass} from "./light-shape-sprite-light-pass.tsx";
import {LightShapeFreeformLightPass} from "./light-shape-freeform-light-pass.tsx";
import {ElementLightPass} from "../element-light-pass.tsx";
import {ModelProvider, useModel} from "../../../context/pvm-context.ts";
import {VisibilityProvider} from "../../../context/visibility-context.ts";
import {AccessMaskFn} from "common/legends/visibility/index.ts";
import {Node} from "common/legends/node/index.ts";
import {useIsLightElementOnScreen} from "../../../context/use-is-light-element-on-screen.ts";


export const LightElementLightPass = React.memo(function LightElementLightPass({value}: {
  value: LightNode;
}) {
  const model = useModel();
  let valueModel = useMemo(() => Transform.divide(value.transform, model), [value.transform, model]);
  const isElementVisible = useCallback((element: Node) => AccessMaskFn.canSee(value.accessMask, element.data.visibilityLayer), [value.accessMask]);
  const isOnScreen = useIsLightElementOnScreen(value, valueModel);
  return (<ModelProvider value={valueModel}>
    {isOnScreen && <VisibilityProvider value={isElementVisible}>
      {value.shape?.type === "global" && <LightShapeGlobalLightPass value={value.shape.data}/>}
      {value.shape?.type === "sprite" && <LightShapeSpriteLightPass value={value.shape.data} origin={value.origin}/>}
      {value.shape?.type === "spotlight" && <LightShapeSpotlightLightPass value={value.shape.data} origin={value.origin} />}
      {value.shape?.type === "freeform" && <LightShapeFreeformLightPass value={value.shape.data} origin={value.origin} />}
    </VisibilityProvider>}

    <binder>
      {[...value.children].reverse().map((element) => <ElementLightPass key={element.data.id} value={element}/>)}
    </binder>
  </ModelProvider>);
});
