import {defaultLocalNode, LocalNode, LocalNodeOperation, LocalNodeSignals, localNodeTypePropTypes} from "../local-node.ts";
import {LightShape, LightShapeOperation, lightShapeType} from "./light-shape.ts";
import {ObjectType, Optional, OptionalType, Type, ValueOperation, ValueType} from "../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {AccessMask, AccessMaskFn, AccessMaskOperation, accessMaskType} from "../../visibility/index.ts";


export type LightNode = LocalNode & {
  accessMask: AccessMask,
  shape: Optional<LightShape>
};
export type LightNodeOperation =
  | LocalNodeOperation
  | {type: "update-access-mask", operations: AccessMaskOperation[]}
  | {type: "update-shape", operations: ValueOperation<Optional<LightShape>, LightShapeOperation>[] }
  ;

export type LightNodeType = Type<LightNode, LightNodeOperation>;

export const lightNodeType: LightNodeType = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  accessMask: accessMaskType,
  shape: new ValueType(new OptionalType(lightShapeType))
}));

export function defaultLightNode(): LightNode {
  return {
    ...defaultLocalNode(),
    name: "Light",
    accessMask: AccessMaskFn.DEFAULT,
    selectionMask: 0,
    shape: undefined
  }
}

export function LightNodeRef(valueRef: MutableRef<LightNode, LightNodeOperation[]>) {
  return {
    ...LocalNodeSignals(valueRef),
    accessMaskRef: valueRef.map<AccessMask, AccessMaskOperation[]>(value => value.accessMask, (_, operations) => [{type: "update-access-mask", operations}]).distinct(),
    shapeRef: valueRef.map<Optional<LightShape>, ValueOperation<Optional<LightShape>, LightShapeOperation>[]>(value => value.shape, (_, operations) => [{type: "update-shape", operations}]).distinct()
  }
}
