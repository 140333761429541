import {MutableRef} from "common/ref";
import {LightShapeFreeform, LightShapeFreeformOperation, LightShapeFreeformRef} from "common/legends/node/light/light-shape-freeform.ts";
import {Button, InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {useCallback, useMemo} from "react";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {FaWrench} from "react-icons/fa6";
import {ValueFn} from "common/types/generic/index.ts";
import {NodeId} from "common/legends/node/index.ts";
import {useEditorTool} from "../../../nav/common/tool/tool-selector/use-editor-tool.ts";

export function LightShapeFreeformFields({nodeID, valueRef}: {
  nodeID: NodeId,
  valueRef: MutableRef<LightShapeFreeform, LightShapeFreeformOperation[]>
}) {
  const {colorRef, intensityRef, falloffRef, falloffStrengthRef} = useMemo(() => LightShapeFreeformRef(valueRef), [valueRef]);

  const toolRef = useEditorTool();
  const setAreaTool = useCallback(() => {
    toolRef.apply(tool => ValueFn.set(tool, {
      type: "area",
      data: {nodeID, mode: {type: "create", data: undefined}}
    }));
  }, [toolRef, valueRef, nodeID]);

  return <>
    <InputGroup>
      <InputGroupLabel>Color</InputGroupLabel>
      <ColorField className="flex-1" value={colorRef} />
    </InputGroup>
    <div className="flex flex-row gap-0.5">
      <InputGroup className="flex-1">
        <InputGroupLabel>Intensity</InputGroupLabel>
        <InputNumber value={intensityRef} />
      </InputGroup>
      <InputGroup className="flex-1">
        <InputGroupLabel>Falloff Strength</InputGroupLabel>
        <InputNumber value={falloffStrengthRef} />
      </InputGroup>
    </div>

    <InputGroup className="flex-1">
      <InputGroupLabel>Falloff Radius</InputGroupLabel>
      <InputNumber value={falloffRef} />
    </InputGroup>

    <Button onClick={setAreaTool}>
      <FaWrench /> Edit Area
    </Button>
  </>
}