import {KeyboardEvent, MouseEvent, RefObject, useCallback, useRef} from "react";
import {HSLA, Optional, Point, PointFn, Transform, TransformOperation, ValueFn} from "common/types/index.ts";
import {Grid, Node} from "common/legends/index.ts";
import {generateMeasureID, Measurement, MeasurementOperation} from "common/legends/measurement/index.ts";
import {useGetScreenPosition} from "./use-get-screen-pos.ts";
import {useGetWorldPositionFromScreenPosition} from "./use-get-world-pos.ts";
import {MutableRef} from "common/ref";
import {Tool, ToolOperation} from "../../common/tool/tool.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {ulid} from "ulid";
import {VisibilityLayerFn} from "common/legends/visibility/index.ts";
import {ScenePath} from "common/legends/reference/scene-path.ts";
import {AssetTokenPath} from "common/legends/reference/asset-token-path.ts";
import {useAddElement} from "../../common/use-add-element.ts";
import {AssetTokenSelectionRef, SceneSelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";

function measurementToElement(grid: Grid, measurement: Measurement): Optional<Node> {
  if (measurement?.type === "rectangle") {
    const [width, height] = Vector2.abs(Vector2.subtract(measurement.endPoint, measurement.startPoint));
    const snapPoint = Grid.snap(grid, measurement.startPoint);
    const origin = Vector2.subtract(snapPoint, measurement.startPoint);
    return ({type: "shape", data: {
      id: ulid(),
      name: "Shape",
      tags: [],
      children: [],
      conditions: [],
      selectionMask: VisibilityLayerFn.DEFAULT,
      visibilityLayer: VisibilityLayerFn.DEFAULT,
      transform: {
        position: Vector2.add([
          Math.min(measurement.startPoint[0], measurement.endPoint[0]),
          Math.min(measurement.startPoint[1], measurement.endPoint[1])
        ], origin),
        scale: 1,
        rotation: 0
      },
      origin,
      pivot: [width / 2, height / 2],
      fillColor: measurement.color,
      shape: {
        type: "rectangle",
        data: {
          width: Math.abs(width),
          height: Math.abs(height)
        }
      }
    }});
  } else if (measurement?.type === "circle") {
    const snapPoint = Grid.snap(grid, measurement.origin);
    const origin = Vector2.subtract(snapPoint, measurement.origin);
    return ({type: "shape", data: {
      id: ulid(),
      name: "Shape",
      tags: [],
      children: [],
      conditions: [],
      selectionMask: VisibilityLayerFn.DEFAULT,
      visibilityLayer: VisibilityLayerFn.DEFAULT,
      transform: {
        position: snapPoint,
        scale: 1,
        rotation: 0
      },
      origin,
      pivot: [0, 0],
      fillColor: measurement.color,
      shape: {
        type: "arc",
        data: {
          radius: measurement.radius,
          startAngle: 0,
          endAngle: 360
        }
      }
    }});
  } else if (measurement?.type === "cone") {
    const direction = Vector2.subtract(measurement.endPoint, measurement.startPoint);
    const rotation = Math.atan2(direction[1], direction[0]) - Math.PI / 2;
    const snapPoint = Grid.snap(grid, measurement.startPoint);
    const origin = Vector2.rotate(
      Vector2.subtract(snapPoint, measurement.startPoint),
      -rotation
    );
    return ({type: "shape", data: {
      id: ulid(),
      name: "Shape",
      tags: [],
      children: [],
      conditions: [],
      selectionMask: VisibilityLayerFn.DEFAULT,
      visibilityLayer: VisibilityLayerFn.DEFAULT,
      transform: {
        position: snapPoint,
        scale: 1,
        rotation: rotation / Math.PI * 180
      },
      origin,
      pivot: [0, 0],
      fillColor: measurement.color,
      shape: {
        type: "cone",
        data: {
          sizeAngle: measurement.sizeAngle,
          length: Vector2.distance(measurement.startPoint, measurement.endPoint)
        }
      }
    }});
  } else if (measurement?.type === "beam") {
    const direction = Vector2.subtract(measurement.endPoint, measurement.startPoint);
    const rotation = Math.atan2(direction[1], direction[0]) - Math.PI / 2;
    const pivot: Point = [measurement.width / 2, 0];
    const snapPoint = Grid.snap(grid, measurement.startPoint);
    const origin = Vector2.add(
      Vector2.rotate(Vector2.subtract(snapPoint, measurement.startPoint), -rotation),
      pivot
    );
    return ({type: "shape", data: {
        id: ulid(),
        name: "Shape",
        tags: [],
        children: [],
        conditions: [],
        selectionMask: VisibilityLayerFn.DEFAULT,
        visibilityLayer: VisibilityLayerFn.DEFAULT,
        transform: {
          position: snapPoint,
          scale: 1,
          rotation: rotation / Math.PI * 180
        },
        origin,
        pivot,
        fillColor: measurement.color,
        shape: {
          type: "rectangle",
          data: {
            width: measurement.width,
            height: Vector2.distance(measurement.endPoint, measurement.startPoint)
          }
        }
      }});
  }
  return undefined;
}


export function useMeasureHandlers(
  canvasRef: RefObject<HTMLCanvasElement>,
  rootReference: SceneSelectionRef | AssetTokenSelectionRef,
  view: MutableRef<Transform, TransformOperation[]>,
  grid: Grid,
  toolRef: MutableRef<Tool, ToolOperation[]>,
  measurementRef: MutableRef<Measurement, MeasurementOperation[]>,
  color: HSLA
) {
  const getScreenPos = useGetScreenPosition(canvasRef);
  const getWorldPos = useGetWorldPositionFromScreenPosition(view);
  const startRef = useRef<Point | undefined>(undefined);
  const lastRightRef = useRef<Point | undefined>(undefined);

  const addPointToMeasurement = useCallback(() => {
    measurementRef.apply(prevValue => {
      if (prevValue?.type === "path") {
        return ValueFn.set(prevValue, {
          ...prevValue,
          type: "path",
          nodes: [
            ...prevValue.nodes.slice(0, prevValue.nodes.length),
            prevValue.nodes[prevValue.nodes.length - 1]
          ]
        });
      }
      return [];
    });
  }, [measurementRef]);

  const clearMeasurement = useCallback(() => {
    startRef.current = undefined;
    measurementRef.apply(prevValue => prevValue !== undefined ? ValueFn.set(prevValue, {
      type: "noop",
      resourceRef: prevValue.resourceRef,
      id: prevValue.id
    }) : []);
  }, [measurementRef, startRef])

  const onMeasureMouseDown = useCallback((ev: MouseEvent) => {
    const worldPos = getWorldPos(getScreenPos([ev.clientX, ev.clientY]));
    if (ev.button === 2) {
      lastRightRef.current = [ev.screenX, ev.screenY];
    }
    if (ev.button !== 0) return true;
    startRef.current = !ev.shiftKey ? Grid.snap({...grid, subdivisions: grid.subdivisions + 1}, worldPos) : worldPos;
    return true;
  }, [startRef, grid, getWorldPos, getScreenPos]);
  const updateMeasurement = useCallback((worldPos: Point) => {
    if (startRef.current === undefined) return;
    measurementRef.apply((prevValue): MeasurementOperation[] => {
      if (prevValue?.type === undefined || prevValue?.type === "noop") {
        const tool = toolRef.value;
        if (tool.type !== "measurement") return [];
        const mode = tool.data.type;
        if (mode === "path") {
          return ValueFn.set<Measurement, never>(prevValue, {
            id: generateMeasureID(),
            type: "path",
            resourceRef: rootReference.type === "scene"
              ? {type: "scene", gameID: rootReference.storeId, sceneID: rootReference.resourceId}
              : {type: "asset-token", gameID: rootReference.storeId, assetID: rootReference.resourceId, tokenID: rootReference.tokenId},
            nodeID: undefined,
            color,
            nodes: [
              startRef.current!,
              worldPos
            ]
          });
        } else if (mode === "cone") {
          return ValueFn.set<Measurement, never>(prevValue, {
            id: generateMeasureID(),
            type: "cone",
            resourceRef: rootReference.type === "scene"
              ? {type: "scene", gameID: rootReference.storeId, sceneID: rootReference.resourceId}
              : {type: "asset-token", gameID: rootReference.storeId, assetID: rootReference.resourceId, tokenID: rootReference.tokenId},
            sizeAngle: tool.data.data.sizeAngle,
            color: tool.data.data.fillColor,
            startPoint: startRef.current!,
            endPoint: worldPos
          });
        } else if (mode === "rectangle") {
          return ValueFn.set<Measurement, never>(prevValue, {
            id: generateMeasureID(),
            type: "rectangle",
            resourceRef: rootReference.type === "scene"
              ? {type: "scene", gameID: rootReference.storeId, sceneID: rootReference.resourceId}
              : {type: "asset-token", gameID: rootReference.storeId, assetID: rootReference.resourceId, tokenID: rootReference.tokenId},
            color: tool.data.data.fillColor,
            startPoint: startRef.current!,
            endPoint: worldPos
          });
        } else if (mode === "circle") {
          return ValueFn.set<Measurement, never>(prevValue, {
            id: generateMeasureID(),
            type: "circle",
            resourceRef: rootReference.type === "scene"
              ? {type: "scene", gameID: rootReference.storeId, sceneID: rootReference.resourceId}
              : {type: "asset-token", gameID: rootReference.storeId, assetID: rootReference.resourceId, tokenID: rootReference.tokenId},
            color: tool.data.data.fillColor,
            origin: startRef.current!,
            radius: Vector2.distance(startRef.current!, worldPos)
          });
        } else if (mode === "beam") {
          return ValueFn.set<Measurement, never>(prevValue, {
            id: generateMeasureID(),
            type: "beam",
            resourceRef: rootReference.type === "scene"
              ? {type: "scene", gameID: rootReference.storeId, sceneID: rootReference.resourceId}
              : {type: "asset-token", gameID: rootReference.storeId, assetID: rootReference.resourceId, tokenID: rootReference.tokenId},
            color: tool.data.data.fillColor,
            width: tool.data.data.width,
            startPoint: startRef.current!,
            endPoint: worldPos
          });
        }
      } else if (prevValue?.type === "path") {
        const prevWorldPos = prevValue.nodes[prevValue.nodes.length - 1];
        if (PointFn.equals(prevWorldPos, worldPos)) return [];
        return ValueFn.set(prevValue, {
          ...prevValue,
          nodes: [
            ...prevValue.nodes.slice(0, prevValue.nodes.length - 1),
            worldPos
          ]
        });
      } else if (prevValue?.type === "cone") {
        if (PointFn.equals(prevValue.endPoint, worldPos)) return [];
        return ValueFn.set(prevValue, {
          ...prevValue,
          endPoint: worldPos
        });
      } else if (prevValue?.type === "rectangle") {
        if (PointFn.equals(prevValue.endPoint, worldPos)) return [];
        return ValueFn.set(prevValue, {
          ...prevValue,
          endPoint: worldPos
        });
      } else if (prevValue?.type === "circle") {
        const radius = Vector2.distance(prevValue.origin, worldPos);
        if (prevValue.radius === radius) return [];
        return ValueFn.set(prevValue, {
          ...prevValue,
          radius
        });
      } else if (prevValue?.type === "beam") {
        if (PointFn.equals(prevValue.endPoint, worldPos)) return [];
        return ValueFn.set(prevValue, {
          ...prevValue,
          endPoint: worldPos
        });
      }
      return [];
    });
  }, [startRef, measurementRef.apply, color]);

  const addElement = useAddElement();
  const onMeasureMouseMove = useCallback((ev: MouseEvent) => {
    const worldPos = getWorldPos(getScreenPos([ev.clientX, ev.clientY]));
    updateMeasurement(!ev.shiftKey ? Grid.snap({...grid, subdivisions: grid.subdivisions + 1}, worldPos) : worldPos);
    return true;
  }, [getWorldPos, getScreenPos, grid, updateMeasurement]);
  const onMeasureMouseUp = useCallback((ev: MouseEvent) => {
    if (ev.button !== 0) return true;
    startRef.current = undefined;
    const measurement = measurementRef.value;
    const tool = toolRef.value;
    if (tool.type === "measurement") {
      const path: ScenePath | AssetTokenPath = rootReference.type === "scene"
        ? {type: "scene", data: {sceneID: rootReference.resourceId}}
        : {type: "asset-token", data: {assetID: rootReference.resourceId, tokenID: rootReference.tokenId}};
      if (
        (measurement?.type === "rectangle" && tool.data.type === "rectangle" && tool.data.data.sticky) ||
        (measurement?.type === "circle" && tool.data.type === "circle" && tool.data.data.sticky) ||
        (measurement?.type === "cone" && tool.data.type === "cone" && tool.data.data.sticky) ||
        (measurement?.type === "beam" && tool.data.type === "beam" && tool.data.data.sticky)
      ) {
        const shape = measurementToElement(grid, measurement);
        if (shape) addElement(path, shape);
      }
    }

    measurementRef.apply(prevValue => prevValue !== undefined ? ValueFn.set(prevValue, {
      type: "noop",
      resourceRef: prevValue.resourceRef,
      id: prevValue.id
    }) : []);
    return true;
  }, [getWorldPos, getScreenPos, measurementRef, startRef, addElement, rootReference]);
  const onMeasureMouseLeave = useCallback((_: MouseEvent) => {
    if (startRef.current !== undefined) return true;
    clearMeasurement();
    return true;
  }, [clearMeasurement, startRef]);

  const onMeasureKeyUp = useCallback((ev: KeyboardEvent) => {
    if (startRef.current === undefined) return true;
    if (ev.key === "q") {
      addPointToMeasurement();
      return false;
    } else if (ev.key === "Escape") {
      clearMeasurement();
      return false;
    }
    return true;
  }, [addPointToMeasurement, clearMeasurement]);

  const onMeasureContextMenu = useCallback((ev: MouseEvent) => {
    if (startRef.current === undefined) return true;
    if (lastRightRef.current === undefined) return true;

    if (lastRightRef.current[0] === ev.screenX && lastRightRef.current[1] === ev.screenY) {
      addPointToMeasurement();
      ev.preventDefault();
      return false;
    }

    return true;
  }, [startRef, addPointToMeasurement]);

  return {
    onMeasureMouseDown,
    onMeasureMouseMove,
    onMeasureMouseUp,
    onMeasureMouseLeave,
    onMeasureKeyUp,
    onMeasureContextMenu
  };
}