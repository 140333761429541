import {InputListItemProps} from "#lib/components/list/input-list.tsx";
import {
  Dnd5eActionTemplateSegmentText,
  Dnd5eActionTemplateSegmentTextOperation,
  Dnd5eActionTemplateSegmentTextSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/dnd-5e-action-template-segment-text.ts";
import {ConnectDragPreview, ConnectDragSource} from "react-dnd";
import React, {useMemo} from "react";
import {ButtonBar, ExpandableLabel, IconButton, useToggle} from "#lib/components/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {FaCopy, FaTrash} from "react-icons/fa";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {InputRichText} from "#lib/components/input/input-rich-text.tsx";

export function Dnd5eActionTemplateSegmentTextView({item, remove, duplicate, dragRefPreview, dragHandlerRef}: InputListItemProps<Dnd5eActionTemplateSegmentText, Dnd5eActionTemplateSegmentTextOperation> & {
  dragHandlerRef: ConnectDragSource | ConnectDragPreview,
  dragRefPreview: ConnectDragSource | ConnectDragPreview
}) {
  const {contentRef} = useMemo(() => Dnd5eActionTemplateSegmentTextSignals(item), [item])
  const [expanded, toggleExpanded] = useToggle(false);

  return <div role="list-item" className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>

      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 cursor-pointer" onClick={toggleExpanded}>Text</SectionHeader>
      </ExpandableLabel>

      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Effect" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>
    {expanded && <Fieldset>
      <InputRichText value={contentRef} />
    </Fieldset>}
  </div>;
}
