import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {InputListItemProps} from "#lib/components/list/input-list.tsx";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {ConnectDragPreview, ConnectDragSource} from "react-dnd";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import React, {useMemo} from "react";
import {FaCopy, FaTrash} from "react-icons/fa";
import {Dnd5eActionTemplateSegmentRollModifiersView} from "./dnd-5e-action-template-segment-roll-modifiers-view.tsx";
import {Dnd5eActionTemplateAddRollModifierButton} from "./dnd-5e-action-template-add-roll-modifier-button.tsx";
import {InputEffectID} from "../input-effect-i-d.tsx";
import {useConditionEffectIDRef} from "./use-condition-effect-i-d-ref.ts";
import {
  Dnd5eActionTemplateSegmentRollConditionModifier,
  Dnd5eActionTemplateSegmentRollConditionModifierOperation,
  Dnd5eActionTemplateSegmentRollConditionModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/damage/dnd-5e-action-template-segment-roll-condition-modifier.ts";

export function Dnd5eActionTemplateSegmentDiceRollConditionModifierListItem({item, remove, duplicate, dragRefPreview, dragHandlerRef}: InputListItemProps<Dnd5eActionTemplateSegmentRollConditionModifier, Dnd5eActionTemplateSegmentRollConditionModifierOperation> & {
  dragHandlerRef: ConnectDragSource | ConnectDragPreview,
  dragRefPreview: ConnectDragSource | ConnectDragPreview
}) {
  const [expanded, toggleExpanded] = useToggle(false);

  const {conditionRef, modifiersRef} = useMemo(() => Dnd5eActionTemplateSegmentRollConditionModifierSignals(item), [item]);
  const effectIDRef = useConditionEffectIDRef(conditionRef);

  return <div className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 cursor-pointer" onClick={toggleExpanded}>Condition</SectionHeader>
      </ExpandableLabel>
      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Effect" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>

    {expanded && <Fieldset>
      <InputGroup>
        <InputGroupLabel>Effect</InputGroupLabel>
        <InputEffectID valueRef={effectIDRef} />
      </InputGroup>

      <div className="flex flex-col gap-1">
        <ButtonBar>
          <SectionHeader className="flex-1">Modifiers</SectionHeader>
          <Dnd5eActionTemplateAddRollModifierButton valueRef={modifiersRef} />
        </ButtonBar>
        <Dnd5eActionTemplateSegmentRollModifiersView valueRef={modifiersRef} />
      </div>
    </Fieldset>}
  </div>
}