import {forwardRef, PropsWithChildren, Ref} from "react";
import {FaChevronDown, FaChevronRight} from "react-icons/fa";
import {twMerge} from "tailwind-merge";
import {ButtonSize} from "#lib/components/index.ts";

export type ExpandableLabelProps = PropsWithChildren<{
  className?: string;
  size?: ButtonSize;
  expanded: boolean;
  hasChildren?: boolean;
  toggleExpand?: () => void;
}>;

export const ExpandableLabel = forwardRef<HTMLDivElement, ExpandableLabelProps>(function ExpandableLabel({expanded, toggleExpand, hasChildren = true, className, children}: ExpandableLabelProps, ref: Ref<HTMLDivElement>) {
  return (
    <div ref={ref} className={twMerge(
      "text-white/80 p-0 outline-none w-full",
      "flex flex-row h-10 gap-0.5 items-center",
      className
    )}>
      <button className={twMerge(
        "bg-zinc-900/80 w-10 h-10 shrink-0 flex items-center justify-center pointer-events-auto",
        toggleExpand === undefined ? "cursor-default text-white/25" : "cursor-pointer",
        hasChildren ? "text-white/80" : "text-white/25"
      )} onClick={toggleExpand}>
        {toggleExpand !== undefined ?
          (expanded && <FaChevronDown/>) || <FaChevronRight />
          : <></>
        }
      </button>
      {children}
    </div>
  );
});
