import React from "react";
import {Dnd5eCondition, DND_5E_CONDITIONS} from "common/legends/index.ts";
import {Optional, SetFn} from "common/types/index.ts";
import {ButtonBar, IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {FaHeartCrack} from "react-icons/fa6";
import {Menu} from "@headlessui/react";
import {FaPlus} from "react-icons/fa";
import {twMerge} from "tailwind-merge";
import {SheetReference} from "../../../../../common/sheet/sheet-reference.ts";
import {useSheetConditions} from "../../../../sheet/editor/dnd-5e-character/dnd-5e-action/use-sheet-conditions.ts";
import {useSheetConditionImmunities} from "../../../../sheet/editor/dnd-5e-character/dnd-5e-action/use-sheet-condition-immunities.ts";
import {CONDITION_IMAGE} from "../../../../sheet/editor/dnd-5e/dnd-5e-condition/dnd-5e-conditions-view.tsx";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {useSheetSignal} from "../../../../../common/sheet/use-sheet-signal.ts";
import {Ref} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";

export function QuickAccessConditionsMenu({sheetReferenceRef}: {
  sheetReferenceRef: Ref<Optional<SheetReference>>;
}) {
  const sheet = useSheetSignal(sheetReferenceRef);
  const conditionsRef = useSheetConditions(sheet);
  const conditions = useRefValue(conditionsRef);

  const conditionImmunitiesRef = useSheetConditionImmunities(sheet);
  const conditionImmunities = useRefValue(conditionImmunitiesRef);

  const toggleCondition = (condition: Dnd5eCondition) => {
    conditionsRef.apply(prev => prev.includes(condition) ? SetFn.delete(condition) : SetFn.insert(condition));
  };

  return (<Menu as="div">
    <Menu.Button as={InputGroup} className="pr-0" title="Conditions">
      <InputGroupIcon><FaHeartCrack /></InputGroupIcon>
      <InputGroupLabel>Conditions</InputGroupLabel>
      <div className="flex-1 flex flex-row gap-1 pr-2">
        {conditions.filter(c => !conditionImmunities.includes(c)).map(condition => <div
          key={condition}
          className="shrink-0 basis-6 inline-flex items-center h-8"
          title={condition}>
          <img className="w-6 h-6" alt={condition} src={CONDITION_IMAGE[condition]} />
        </div>)}
      </div>
      <IconButton><FaPlus /></IconButton>
    </Menu.Button>
    <Menu.Items as="div" className="p-2 flex flex-col text-white gap-0.5">
      {DND_5E_CONDITIONS.filter(c => !conditionImmunities.includes(c)).map(condition => <Menu.Item as={ButtonBar} key={condition} className="bg-transparent pl-0">
        <InputGroup className="flex-1 px-0 rounded-full backdrop-blur bg-zinc-800/80" onClick={() => toggleCondition(condition)}>
          <InputGroupLabel className="flex-1 flex items-center gap-2 pl-0 pr-2 mx-0">
            <img className={twMerge(
              "w-8 h-8",
              !conditions.includes(condition) ? "opacity-50" : ""
            )} alt={condition} src={CONDITION_IMAGE[condition]} />
            <span className="flex-1">{condition.toUpperCase()}</span>
          </InputGroupLabel>
        </InputGroup>
      </Menu.Item>)}
    </Menu.Items>
  </Menu>);
}
