import {QLabAsset} from "common/qlab/index.ts";
import {Point} from "common/types/index.ts";

export function getImageSize(asset: QLabAsset): Promise<Point> {
  return new Promise<Point>((resolve, reject) => {
    const video = document.createElement("video");
    function bindImageToTexture() {
      resolve([video.videoWidth, video.videoHeight]);
    }
    video.addEventListener('loadedmetadata', bindImageToTexture);
    video.addEventListener('error', reject)
    video.src = `data:${asset.type};base64,${asset.data}`;
  });
}
