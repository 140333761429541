import {Color, HSLA, Point} from "common/types/index.ts";
import {AreaPolyShader} from "./area-shader/area-poly-shader.tsx";
import {AreaLineShader} from "./area-shader/area-line-shader.tsx";
import React, {useMemo, useState} from "react";
import {Spline, SplineFn} from "common/types/generic/spline/index.ts";
import {ColorMaskShader} from "../color-mask-shader.tsx";
import {Framebuffer} from "../framebuffer.tsx";

export function AreaShader({spline, color, scale, origin}: {
  spline: Spline;
  scale: number;
  color: HSLA;
  origin: Point;
}) {
  const lines = useMemo(() => SplineFn.getLines(spline), [spline]);
  const [texture, setTexture] = useState<WebGLTexture | null>(null);
  return <binder>
    <Framebuffer ref={setTexture}>
      <AreaPolyShader origin={origin} spline={spline} color={Color.WHITE} />
      <AreaLineShader origin={origin} lines={lines} scale={scale} color={Color.WHITE}/>
    </Framebuffer>
    {texture && <ColorMaskShader texture={texture} color={color}/>}
  </binder>;
}