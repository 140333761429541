import {Button, ButtonBar, Checkbox, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {FaCopy, FaPlus, FaTrash} from "react-icons/fa";
import {BooleanFn, ListOperation} from "common/types/index.ts";
import React, {useCallback, useMemo} from "react";
import {InputString} from "#lib/components/input/input-string.tsx";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {MutableRef} from "common/ref";
import {
  Dnd5eActionTemplateEffect,
  Dnd5eActionTemplateEffectFn,
  Dnd5eActionTemplateEffectOperation,
  Dnd5eActionTemplateEffectSignal
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/effect/dnd-5e-action-template-effect.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {Dnd5eActionTemplateModifiersView} from "../modifier/dnd-5e-action-template-modifiers-view.tsx";
import {
  Dnd5eActionTemplateEffectID,
  generateDnd5eActionTemplateEffectID
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/effect/dnd-5e-action-template-effect-id.ts";

function Dnd5eActionTemplateEffectView({item, remove, toggle, duplicate}: InputListItemProps<Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation> & {
  toggle: (actionEffectID: Dnd5eActionTemplateEffectID) => void;
}) {
  const {enabledRef, abbreviationRef, nameRef, modifiersRef} =  useMemo(() => Dnd5eActionTemplateEffectSignal(item), [item]);
  const enabled = useRefValue(enabledRef);
  const [expanded, toggleExpanded] = useToggle(false);

  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/action-template-effect", item, remove);
  return <div role="list-item" className="flex flex-col">
    <ButtonBar className="flex-1" ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <InputGroup>
          <Checkbox checked={enabled} onChange={() => toggle(item.value.actionEffectID)} />
        </InputGroup>
        <SectionHeader className="flex-1 cursor-pointer" onClick={toggleExpanded}>{useRefValue(nameRef)}</SectionHeader>
      </ExpandableLabel>
      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Effect" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>
    {expanded && <Fieldset>
      <InputGroup className="flex-[3]">
        <InputGroupLabel>Name</InputGroupLabel>
        <InputString type="text" value={nameRef} />
      </InputGroup>
      <InputGroup className="flex-1">
        <InputGroupLabel>Abbreviation</InputGroupLabel>
        <InputString type="text" value={abbreviationRef} />
      </InputGroup>
      <Dnd5eActionTemplateModifiersView valueRef={modifiersRef} />
    </Fieldset>}
  </div>;
}

function defaultActionEffect(): Dnd5eActionTemplateEffect {
  return {
    actionEffectID: generateDnd5eActionTemplateEffectID(),
    enabled: false,
    name: "Great Weapon Master",
    abbreviation: "GWM",
    modifiers: []
  };
}

export function useToggleActionTemplateEffect(effects: MutableRef<Dnd5eActionTemplateEffect[], ListOperation<Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation>[]>) {
  return useCallback((actionEffectID: Dnd5eActionTemplateEffectID) => {
    effects.apply(prev => {
      const effect = prev.find(e => e.actionEffectID === actionEffectID);
      if (effect === undefined) return [];
      const group = Dnd5eActionTemplateEffectFn.getGroup(effect);
      if (group === undefined) {
        return ListOperation.apply(prev.indexOf(effect), [{
          type: "update-enabled",
          operations: BooleanFn.set(effect.enabled, !effect.enabled)
        }]);
      } else {
        if (effect.enabled) {
          return ListOperation.apply(prev.indexOf(effect), [{
            type: "update-enabled",
            operations: BooleanFn.set(effect.enabled, !effect.enabled)
          }]);
        } else {
          const deactivateEffects = prev.filter(e => Dnd5eActionTemplateEffectFn.getGroup(e) === group).filter(e => e.enabled);
          const activateEffects = prev.filter(e => Dnd5eActionTemplateEffectFn.getGroup(e) === group).filter(e => e.actionEffectID === actionEffectID);
          return [
            ...deactivateEffects.flatMap(effect => ListOperation.apply(prev.indexOf(effect), [{
              type: "update-enabled",
              operations: BooleanFn.set(effect.enabled, !effect.enabled)
            }])) satisfies ListOperation<Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation>[],
            ...activateEffects.flatMap(effect => ListOperation.apply(prev.indexOf(effect), [{
              type: "update-enabled",
              operations: BooleanFn.set(effect.enabled, !effect.enabled)
            }])) satisfies ListOperation<Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation>[]
          ];
        }
      }
    });
  }, [effects]);
}

export function Dnd5eActionTemplateEffectsView({valueRef}: {
  valueRef: MutableRef<Dnd5eActionTemplateEffect[], ListOperation<Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation>[]>;
}) {
  const addActionEffect = useCallback((actionEffect: Dnd5eActionTemplateEffect) => valueRef.apply(prev => ListOperation.insert(prev.length, actionEffect)), [valueRef.apply]);
  const toggle = useToggleActionTemplateEffect(valueRef);

  return (<div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5">
      <SectionHeader className="flex-1">Effects</SectionHeader>
      <ButtonBar>
        <Button onClick={() => addActionEffect(defaultActionEffect())}>
          <FaPlus /> Add Effect
        </Button>
      </ButtonBar>
    </div>

    <InputList<Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation>
      accept="legends/action-template-effect"
      items={valueRef}
      itemKey={Dnd5eActionTemplateEffectFn.getActionTemplateEffectID}
      copy={Dnd5eActionTemplateEffectFn.copyActionEffect}
      ListItem={useCallback((props) => <Dnd5eActionTemplateEffectView {...props} toggle={toggle} />, [toggle])} />
  </div>);
}