import {Button} from "#lib/components/index.ts";
import {TokenVision, TokenVisionOperation} from "common/legends/asset/token/token-vision.ts";
import {ListOperation} from "common/types/generic/index.ts";
import {MutableRef} from "common/ref";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {useCallback} from "react";
import {generateTokenVisionID} from "common/legends/asset/token/token-vision-i-d.ts";

export function AddVisionButton({valueRef}: {
  valueRef: MutableRef<TokenVision[], ListOperation<TokenVision, TokenVisionOperation>[]>
}) {
  const onAddVision = useCallback(() => {
    valueRef.apply(prev => ListOperation.insert(prev.length, {
      tokenVisionID: generateTokenVisionID(),
      name: "Vision",
      offset: [0, 0],
      accessMask: 1,
      grayscale: false,
      limit: undefined
    }))
  }, [valueRef])

  return <Button onClick={onAddVision}>
    <FontAwesomeIcon icon={faPlus} />
    Add Vision
  </Button>
}