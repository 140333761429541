import React, {useState} from "react";
import {Button, ButtonBar, ExpandableLabel, IconButton, Input, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {FaEllipsis} from "react-icons/fa6";
import {twMerge} from "tailwind-merge";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {FaSearch, FaTimes} from "react-icons/fa";
import {fuzzySearch} from "#lib/components/fuzzy-search/index.ts";
import {BareIconButton} from "#lib/components/bare-icon-button/index.ts";
import {SheetReference} from "../../../../common/sheet/sheet-reference.ts";
import {getActionSignal, useSheetActions} from "./dnd-5e-action/get-action-signal.ts";
import {LoadingDnd5eCharacterCombatAction} from "./combat/actions/dnd-5e-character-combat-actions.tsx";
import {useSheetReference} from "../../../../common/sheet/sheet-reference-context.ts";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {Optional} from "common/types/index.ts";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {Ref} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";
import {Dnd5eActionType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/dnd-5e-action-type.ts";
import {Dnd5eActionTemplate} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";


export type Dnd5eCharacterActionsSubMenuViewProps = {
  sheetRef: Ref<Optional<SheetReference>>;
  root: string;
  options: (Dnd5eActionType|"other")[];
  searchTerm: string;
  expanded: boolean;
};
export function Dnd5eCharacterActionsSubMenuView({sheetRef, root, options, searchTerm, expanded}: Dnd5eCharacterActionsSubMenuViewProps) {
  const sheet = useSheetSignal(sheetRef);
  const actions = useRefValue(useSheetActions(sheet))
    .filter(action => action.data.name.startsWith(root))
    .filter(action => fuzzySearch(searchTerm.toLowerCase(), action.data.name.toLowerCase()))
    .filter(action => isActionType(action, options));

  const subSections = new Set(actions
    .filter(action => action.data.name.substring(root.length).includes("/"))
    .map(action => {
      let remaining = action.data.name.substring(root.length);
      remaining = remaining.substring(0, remaining.indexOf("/"));
      return remaining;
    }));

  const [localExpanded, toggleLocalExpanded] = useToggle(expanded);

  if (actions.length === 0) return <></>;
  return (<div className="flex flex-col">
    <ExpandableLabel expanded={localExpanded} toggleExpand={toggleLocalExpanded}>
      <Button className="flex-1" onClick={toggleLocalExpanded}>{root.substring(root.lastIndexOf("/", root.lastIndexOf("/") - 1) + 1, root.lastIndexOf("/")).trim()}</Button>
    </ExpandableLabel>
    {localExpanded && <Fieldset>
      {[...subSections.values()]
        .map(subSection => <Dnd5eCharacterActionsSubMenuView key={subSection} sheetRef={sheetRef} root={root + subSection + "/"} options={options} searchTerm={searchTerm} expanded={expanded} />)}
      {actions
        .filter(action => !(action.data.name.substring(root.length).includes("/")))
        .map(action => <LoadingDnd5eCharacterCombatAction key={action.data.actionTemplateID} sheetRef={sheet} actionRef={getActionSignal(sheet, action.data.actionTemplateID)} />)}
    </Fieldset>}
  </div>);
}

function isActionType(action: Dnd5eActionTemplate, options: (Dnd5eActionType | "other")[]) {
  return (
    (options.includes("action") && action.data.actionType === "action") ||
    (options.includes("bonus action") && action.data.actionType === "bonus action") ||
    (options.includes("reaction") && action.data.actionType === "reaction") ||
    (options.includes("other") && !(["action", "bonus action", "reaction"].includes(action.data.actionType)))
  );
}

export type Dnd5eCharacterActionsViewProps = {
};
export function Dnd5eCharacterActionsView({}: Dnd5eCharacterActionsViewProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const sheetRef = useSheetReference();
  const sheet = useSheetSignal(sheetRef);
  const actions = useRefValue(useSheetActions(sheet))
    .filter(action => action.data.name.startsWith(""));

  const favoriteActions = actions.filter(action => action.data.favorite);
  const subSections = new Set(actions
    .filter(action => !action.data.favorite)
    .filter(action => fuzzySearch(searchTerm.toLowerCase(), action.data.name.toLowerCase()))
    .filter(action => action.data.name.substring("".length).includes("/")).map(action => {
      let remaining = action.data.name.substring("".length);
      remaining = remaining.substring(0, remaining.indexOf("/"));
      return remaining;
    }));

  const [options, setOptions] = useState<(Dnd5eActionType | "other")[]>(["action", "bonus action", "reaction", "other"]);
  const toggleOption = (option: (Dnd5eActionType | "other")) => {
    setOptions(prev => prev.includes(option) ? prev.filter(a => a !== option) : [...prev, option]);
  };
  const isSelected = (option: (Dnd5eActionType | "other")) => {
    return options.includes(option);
  };

  if (actions.length === 0) return <></>;
  return (<div className="flex flex-col gap-1">
    <SectionHeader>Actions</SectionHeader>
    <div className="flex flex-col gap-0.5">
      <ButtonBar>
        <IconButton title="Action" className={twMerge("shrink-0", isSelected("action") && "text-white bg-blue-950 hover:bg-blue-900 focus:bg-blue-900")} onClick={() => toggleOption("action")}>A</IconButton>
        <IconButton title="Bonus Action" className={twMerge("shrink-0", isSelected("bonus action") && "text-white bg-blue-950 hover:bg-blue-900 focus:bg-blue-900")} onClick={() => toggleOption("bonus action")}>BA</IconButton>
        <IconButton title="Reaction" className={twMerge("shrink-0", isSelected("reaction") && "text-white bg-blue-950 hover:bg-blue-900 focus:bg-blue-900")} onClick={() => toggleOption("reaction")}>R</IconButton>
        <IconButton title="Other" className={twMerge("shrink-0", isSelected("other") && "text-white bg-blue-950 hover:bg-blue-900 focus:bg-blue-900")} onClick={() => toggleOption("other")}><FaEllipsis /></IconButton>
        <InputGroup title="Search" className="px-2 gap-2 flex-1">
          <InputGroupLabel className="px-0"><FaSearch /></InputGroupLabel>
          <Input type="text" placeholder="Search..." value={searchTerm} onChange={ev => setSearchTerm(ev.target.value)} />
          <BareIconButton title="Clear Search" disabled={searchTerm === ""} onClick={() => setSearchTerm("")}><FaTimes /></BareIconButton>
        </InputGroup>
      </ButtonBar>

      {favoriteActions
        .filter(action => isActionType(action, options))
        .filter(action => fuzzySearch(searchTerm.toLowerCase(), action.data.name.toLowerCase()))
        .map(action => <LoadingDnd5eCharacterCombatAction key={action.data.actionTemplateID} sheetRef={sheet} actionRef={getActionSignal(sheet, action.data.actionTemplateID)} />)}

      {[...subSections.values()]
        .map(subSection => <Dnd5eCharacterActionsSubMenuView key={subSection} sheetRef={sheetRef} root={subSection + "/"} options={options} searchTerm={searchTerm} expanded={searchTerm.length > 0} />)}
      {actions
        .filter(action => !action.data.name.includes("/"))
        .filter(action => !action.data.favorite)
        .filter(action => isActionType(action, options))
        .filter(action => fuzzySearch(searchTerm.toLowerCase(), action.data.name.toLowerCase()))
        .map(action => <LoadingDnd5eCharacterCombatAction key={action.data.actionTemplateID} sheetRef={sheet} actionRef={getActionSignal(sheet, action.data.actionTemplateID)} />)}
    </div>
  </div>)
}


