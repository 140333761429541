import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {NameField} from "../name-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {OpacityField} from "../opacity-field.tsx";
import {SelectionMaskField} from "../selection-mask-field.tsx";
import {InputTransform} from "./transform/input-transform.tsx";
import {SizeField} from "../size-field.tsx";
import {useMemo} from "react";
import {InputFile} from "#lib/components/input/input-file.tsx";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {OriginField} from "./origin-field.tsx";
import {ConditionsField} from "./conditions/conditions-field.tsx";
import {MountableField} from "../mountable-field.tsx";
import {AttachableField} from "../attachable-field.tsx";
import {MutableRef} from "common/ref";
import {PivotField} from "./pivot-field.tsx";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";
import {VideoNode, VideoNodeOperation, VideoNodeSignals} from "common/legends/node/video-node.ts";

export function VideoNodeProperties({valueRef, reference, pinned}: {
  valueRef: MutableRef<VideoNode, VideoNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const {name, fileRef, normalRef, sizeRef, opacityRef, selectionMaskRef, transformRef, originRef, pivotRef, mountableRef, attachableRef, visibilityLayerRef, conditionsRef} = useMemo(() => VideoNodeSignals(valueRef), [valueRef]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <BaseComponent><NameField value={name} reference={reference} pinned={pinned} /></BaseComponent>
    <BaseComponent className="flex-row">
      <InputGroup><InputGroupLabel>Video</InputGroupLabel></InputGroup>
      <InputFile accept="video/*" className="flex-1" value={fileRef} />
    </BaseComponent>
    <BaseComponent className="flex-row">
      <InputGroup><InputGroupLabel>Normal</InputGroupLabel></InputGroup>
      <InputFile accept="video/*" className="flex-1" value={normalRef} />
    </BaseComponent>
    <BaseComponent><VisibilityLayerField value={visibilityLayerRef} /></BaseComponent>
    <BaseComponent><OpacityField value={opacityRef} /></BaseComponent>
    <BaseComponent><SelectionMaskField value={selectionMaskRef} /></BaseComponent>
    <BaseComponent><InputTransform value={transformRef} /></BaseComponent>
    <BaseComponent><OriginField valueRef={originRef} /></BaseComponent>
    <BaseComponent><PivotField valueRef={pivotRef} /></BaseComponent>
    <BaseComponent><SizeField value={sizeRef} /></BaseComponent>
    <BaseComponent><MountableField value={mountableRef} /></BaseComponent>
    <BaseComponent><AttachableField value={attachableRef} /></BaseComponent>
    <BaseComponent><ConditionsField value={conditionsRef} /></BaseComponent>
  </div>
}