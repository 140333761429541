import {Transform} from "common/types/generic/index.ts";
import {ConeMeasurement} from "common/legends/measurement/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {TextShader} from "#lib/gl-react/component/text-shader.tsx";
import {Grid} from "common/legends/scene/index.ts";
import {useMemo} from "react";
import {useGrid} from "../../context/grid-context.ts";
import {ConeShader} from "../../shader/shape/cone-shader.tsx";
import {ModelProvider, usePVM} from "../../context/pvm-context.ts";

export function ConeMeasurementView({measurement: {startPoint, endPoint, sizeAngle, color}}: {
  measurement: ConeMeasurement
}) {
  const {projection, view} = usePVM();
  const grid = useGrid();
  const direction = useMemo(() => Vector2.subtract(endPoint, startPoint), [startPoint, endPoint]);
  const model: Transform = useMemo(() => ({
    scale: 1,
    position: startPoint,
    rotation: Math.atan2(direction[1], direction[0]) / Math.PI * 180 - 90
  }), [startPoint, direction]);
  const textModel: Transform = useMemo(() => ({
    scale: 1 / view.scale,
    position: endPoint,
    rotation: Math.atan2(direction[1], direction[0]) / Math.PI * 180 - 90
  }), [direction, endPoint, view.scale]);
  const cone = useMemo(() => ({
    length: Vector2.distance(startPoint, endPoint),
    sizeAngle: sizeAngle
  }), [startPoint, endPoint, sizeAngle]);

  const distance = Grid.distance({...grid, subdivisions: grid.subdivisions + 1}, [0, 0], [Vector2.distance(startPoint, endPoint), 0]);
  return <ModelProvider value={model}>
    <ConeShader origin={[0,0]} color={color} cone={cone} />
    <TextShader projection={projection} view={view} model={textModel} value={`${distance} ${grid.unit[1]}`} size={32} vTextAlign="bottom" />
  </ModelProvider>;
}