import {
  ListPropertyRef,
  NumberOperation,
  numberType,
  ObjectType,
  Optional,
  OptionalOperation,
  OptionalType,
  Point,
  PointOperation,
  PropertyRef,
  Size,
  SizeOperation,
  sizeType,
  Transform,
  TransformOperation,
  Type,
  ValueOperation,
  ValueType
} from "#common/types/index.ts";
import {VisibilityLayer, VisibilityLayerOperation} from "#common/legends/visibility/index.ts";
import {NodeCondition, NodeConditionOperation} from "#common/legends/node/condition/node-condition.ts";
import {Mask, MaskOperation} from "#common/types/generic/mask/mask.ts";
import {MutableRef} from "#common/ref";
import {defaultLocalNode, LocalNode, LocalNodeOperation, LocalNodeSignals, localNodeTypePropTypes, localNodeUpdater} from "./local-node.ts";
import {ColorOperation, colorType, HSLA} from "../../types/index.ts";

export type GridNode = LocalNode & {
  size: Optional<Size>;
  opacity: number;
  thickness: number;
  noise: number;
  color: HSLA;
};

export type GridNodeOperation =
  | LocalNodeOperation
  | {type: "update-opacity", operations: NumberOperation[]}
  | {type: "update-size", operations: ValueOperation<Optional<Size>, OptionalOperation<SizeOperation>>[]}
  | {type: "update-thickness", operations: NumberOperation[]}
  | {type: "update-noise", operations: NumberOperation[]}
  | {type: "update-color", operations: ColorOperation[]}
  ;

export const gridNodeType: Type<GridNode, GridNodeOperation> = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  opacity: numberType,
  size: new ValueType(new OptionalType(sizeType)),
  thickness: numberType,
  noise: numberType,
  color: colorType
}), (value) => {
  value = localNodeUpdater(value);
  if (!value.conditions) value.conditions = [];
  if (value.selectable) {
    value.selectionMask = value.selectable ? 1 : 0;
    delete value["selectable"];
  }
  if (!value.selectionMask) value.selectionMask = 0;
  if (value.color.length === 3) value.color = [...value.color, value.opacity];
  return value;
});

export function defaultGridNode(): GridNode {
  return {
    ...defaultLocalNode(),
    name: "Grid",
    thickness: 0,
    noise: 0,
    color: [0, 0, 0.1, 0.25] as HSLA,
    opacity: 0.25,
    size: undefined
  };
}

export function GridNodeSignals(value: MutableRef<GridNode, GridNodeOperation[]>) {
  return {
    ...LocalNodeSignals(value),
    visibilityLayer: PropertyRef<GridNode, GridNodeOperation, VisibilityLayer, VisibilityLayerOperation>(
      value => value.visibilityLayer,
      operations => [{type: "update-visibility-layer", operations}]
    )(value),
    opacity: PropertyRef<GridNode, GridNodeOperation, number, NumberOperation>(
      value => value.opacity,
      operations => [{type: "update-opacity", operations}]
    )(value),
    selectionMask: PropertyRef<GridNode, GridNodeOperation, Mask, MaskOperation>(
      value => value.selectionMask,
      operations => [{type: "update-selection-mask", operations}]
    )(value),
    transform: PropertyRef<GridNode, GridNodeOperation, Transform, TransformOperation>(
      value => value.transform,
      operations => [{type: "update-transform", operations}]
    )(value),
    size: PropertyRef<GridNode, GridNodeOperation, Optional<Size>, ValueOperation<Optional<Size>, OptionalOperation<SizeOperation>>>(
      value => value.size,
      operations => [{type: "update-size", operations}]
    )(value),
    origin: PropertyRef<GridNode, GridNodeOperation, Point, PointOperation>(
      value => value.origin,
      operations => [{type: "update-origin", operations}]
    )(value),
    thickness: PropertyRef<GridNode, GridNodeOperation, number, NumberOperation>(
      value => value.thickness,
      operations => [{type: "update-thickness", operations}]
    )(value),
    noise: PropertyRef<GridNode, GridNodeOperation, number, NumberOperation>(
      value => value.noise,
      operations => [{type: "update-noise", operations}]
    )(value),
    color: PropertyRef<GridNode, GridNodeOperation, HSLA, ColorOperation>(
      value => value.color,
      operations => [{type: "update-color", operations}]
    )(value),
    conditions: ListPropertyRef<GridNode, GridNodeOperation, NodeCondition, NodeConditionOperation>(
      value => value.conditions,
      operations => [{type: "update-conditions", operations}]
    )(value)
  };
}
