import {AccessMask, AccessMaskOperation, accessMaskType} from "common/legends/visibility/index.ts";
import {BooleanOperation, booleanType, ObjectType} from "common/types/generic/index.ts";
import {Type} from "common/types/type/index.ts";
import {MutableRef} from "common/ref";

export type EditorVision = {
  mask: AccessMask;
  override: boolean;
};

export type EditorVisionOperation =
  | {type: "update-mask", operations: AccessMaskOperation[]}
  | {type: "update-override", operations: BooleanOperation[]}
  ;

export const editorVisionType: Type<EditorVision, EditorVisionOperation> = new ObjectType({
  mask: accessMaskType,
  override: booleanType
});

export const EditorVisionFn = {
  expand(valueRef: MutableRef<EditorVision, EditorVisionOperation[]>) {
    return {
      maskRef: valueRef.map<AccessMask, AccessMaskOperation[]>(value => value.mask, (_, operations) => [{type: "update-mask", operations}]),
      overrideRef: valueRef.map<boolean, BooleanOperation[]>(value => value.override, (_, operations) => [{type: "update-override", operations}]),
    }
  }
};