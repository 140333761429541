import {GridNode, GridNodeOperation, GridNodeSignals} from "common/legends/index.ts";
import {ApplyAction, ConstantOperation, Optional, OptionalOperation, Size, SizeOperation, ValueFn, ValueOperation} from "common/types/index.ts";
import {useMemo} from "react";
import {ButtonBar, ExpandableCheckbox, InputGroup, InputGroupLabel, InputGroupRange} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {pipe} from "common/pipe";
import {distinct, filter, map} from "common/observable";
import {useObservable} from "#lib/qlab/index.ts";
import {twMerge} from "tailwind-merge";
import {NameField} from "../name-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {InputTransform} from "./transform/input-transform.tsx";
import {OpacityField} from "../opacity-field.tsx";
import {SizeField} from "../size-field.tsx";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {MutableRef} from "common/ref";
import {ColorAlphaField} from "#lib/components/input/color-alpha-field.tsx";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";

export type GridNodePropertiesProps = {
  value: MutableRef<GridNode, GridNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};

export function GridNodeProperties({value, reference, pinned}: GridNodePropertiesProps) {
  const {name, visibilityLayer, color, opacity, size, thickness, noise, transform} = useMemo(() => GridNodeSignals(value), [value]);

  return (<div className="tab-content flex flex-col py-2 gap-1">
    <BaseComponent><NameField value={name} reference={reference} pinned={pinned} /></BaseComponent>
    <BaseComponent><VisibilityLayerField value={visibilityLayer} /></BaseComponent>
    <BaseComponent><ColorAlphaField valueRef={color} /></BaseComponent>
    <BaseComponent><InputTransform value={transform} /></BaseComponent>
    <BaseComponent><LimitsField value={size} /></BaseComponent>
    <BaseComponent><InputGroup>
      <InputGroupLabel>Thickness</InputGroupLabel>
      <InputGroupRange min={0} max={1} step={1/32} value={thickness} />
    </InputGroup></BaseComponent>
    <BaseComponent><InputGroup>
      <InputGroupLabel>Noise</InputGroupLabel>
      <InputGroupRange min={0} max={1} step={1/32} value={noise} />
    </InputGroup></BaseComponent>
    <BaseComponent><OpacityField value={opacity} /></BaseComponent>
  </div>);
}

export type LimitsFieldProps = {
  value: MutableRef<Optional<Size>, ValueOperation<Optional<Size>, OptionalOperation<SizeOperation>>[]>;
};

export function LimitsField({value}: LimitsFieldProps) {
  const size = useMemo((): MutableRef<Size, SizeOperation[]> => new MutableRef<Size, SizeOperation[]>({
    value() {
      return value.value || [0, 0]
    },
    observe: pipe(
      value.observe,
      filter(value => value !== undefined),
      map(value => value as Size),
      distinct()
    ),
    apply: (fn: ApplyAction<Size, SizeOperation[]>): Promise<Size> => value.apply(prev => {
      if (prev === undefined) return [];
      const operations = fn(prev);
      if (operations.length === 0) return [];
      return [{type: "apply", operations: fn(prev)}]
    }).then(response => response!)
  }), [value]);

  const expanded = useObservable(pipe(
    value.observe,
    map(v => v !== undefined),
    distinct()
  ), false, [value.observe]);
  const toggleExpanded = () => {
    value.apply(prev => {
      if (prev === undefined) return ValueFn.set(prev, [64, 64])
      return ValueFn.set(prev, undefined);
    });
  };

  return (<div className="flex flex-col">
    <ButtonBar className={twMerge(expanded && "rounded-b-none")}>
      <ExpandableCheckbox className="w-full" size="small" expanded={expanded} toggleExpand={toggleExpanded}>
        <span className="flex-1 px-4 pointer-events-auto backdrop-blur-sm cursor-pointer h-full flex items-center" onClick={toggleExpanded}>Limit Size?</span>
      </ExpandableCheckbox>
    </ButtonBar>
    {expanded && <Fieldset>
      <SizeField className="mx-0 rounded-none" value={size} />
    </Fieldset>}
  </div>)
}