import {
  Dnd5eActionTemplateSegmentRollExpressionModifier,
  Dnd5eActionTemplateSegmentRollExpressionModifierOperation,
  Dnd5eActionTemplateSegmentRollExpressionModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/damage/dnd-5e-action-template-segment-roll-expression-modifier.ts";
import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, Select, useToggle} from "#lib/components/index.ts";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {InputListItemProps} from "#lib/components/list/input-list.tsx";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {ConnectDragPreview, ConnectDragSource} from "react-dnd";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import React, {useMemo} from "react";
import {FaCopy, FaTrash} from "react-icons/fa";
import {InputDiceExpression} from "../../../../dice-expression/input-dice-expression.tsx";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {twMerge} from "tailwind-merge";
import {DAMAGE_TYPE_IMAGES, DAMAGE_TYPE_UNKNOWN} from "../../../../../../../common/systems/dnd5e/damage-types";
import {ValueFn} from "common/types/generic/index.ts";
import {DND_5E_DAMAGE_TYPE_TITLES, DND_5E_DAMAGE_TYPES, isDamageType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {useRefValue} from "#lib/signal/index.ts";

export function Dnd5eActionTemplateSegmentRollExpressionModifierListItem({item, remove, duplicate, dragRefPreview, dragHandlerRef}: InputListItemProps<Dnd5eActionTemplateSegmentRollExpressionModifier, Dnd5eActionTemplateSegmentRollExpressionModifierOperation> & {
  dragHandlerRef: ConnectDragSource | ConnectDragPreview,
  dragRefPreview: ConnectDragSource | ConnectDragPreview
}) {
  const [expanded, toggleExpanded] = useToggle(false);
  const {rollTypeRef, expressionRef} = useMemo(() => Dnd5eActionTemplateSegmentRollExpressionModifierSignals(item), [item]);
  const rollType = useRefValue(rollTypeRef);

  return <div className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 cursor-pointer" onClick={toggleExpanded}>Roll</SectionHeader>
      </ExpandableLabel>
      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Effect" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>

    {expanded && <Fieldset>
      <InputGroup>
        <InputGroupIcon>
            <img alt={rollType?.toUpperCase() || "Unknown"} className={twMerge(
              "w-8 h-8"
            )} title={"Roll Type"}
                 src={rollType ? DAMAGE_TYPE_IMAGES[rollType] : DAMAGE_TYPE_UNKNOWN}/>
        </InputGroupIcon>
        <Select value={rollType || ""} onChange={(ev) => {
          const next = ev.target.value;
          rollTypeRef.apply(prev => ValueFn.set(prev, isDamageType(next) ? next : undefined));
        }}>
          <option value={""}>N/A</option>
          {DND_5E_DAMAGE_TYPES.map(damageType => <option key={damageType} value={damageType}>{DND_5E_DAMAGE_TYPE_TITLES[damageType]}</option>)}
        </Select>
      </InputGroup>

      <InputGroup className="flex-1">
        <InputGroupLabel>Expression</InputGroupLabel>
        <InputDiceExpression value={expressionRef} />
      </InputGroup>
    </Fieldset>}
  </div>
}