import {Button, InputGroup, InputGroupLabel, TagListInput, useTempRef} from "#lib/components/index.ts";
import React, {useMemo} from "react";
import {Scene, SceneFn, SceneID, SceneTreeFile, SceneTreeFileFn, sceneTreeFileType} from "common/legends/index.ts";
import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {ulid} from "ulid";
import {InputString} from "#lib/components/input/input-string.tsx";
import {toPromise} from "common/observable";

export type CreateSceneModalProps = {
  onCreate: (file: SceneTreeFile, content: Scene) => void;
  onCancel: () => void;
};

export function CreateSceneModal({onCancel, onCreate}: CreateSceneModalProps) {
  const file = useTempRef(sceneTreeFileType, () => ({
    id: ulid() as SceneID,
    name: "",
    tags: []
  }));

  const {name, tags} = useMemo(() => SceneTreeFileFn(file), [file]);

  return (<Modal onClose={onCancel}>
    <ModalTitle>Create Scene</ModalTitle>
    <ModalBody>
      <InputGroup>
        <InputGroupLabel>Name</InputGroupLabel>
        <InputString autoFocus value={name} />
      </InputGroup>
      <TagListInput value={tags} />
    </ModalBody>
    <Button className="flex-1" variant="primary" type="submit" onClick={async () => {
      const f = await toPromise(file.observe);
      onCreate(f, SceneFn.DEFAULT);
    }}>Create Scene</Button>
  </Modal>);
}