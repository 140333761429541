import {z} from "zod";
import {
  BooleanOperation,
  booleanType,
  ConstantOperation,
  constantType,
  ObjectType,
  Optional,
  OptionalType, Point, PointOperation, pointType,
  StringOperation,
  stringType,
  ValueOperation,
  ValueType
} from "../../../types/index.ts";
import {AccessMask, AccessMaskOperation, accessMaskType} from "../../visibility/index.ts";
import {generateTokenVisionID, TokenVisionID} from "./token-vision-i-d.ts";
import {MutableRef} from "#common/ref";
import {TokenVisionLimit, TokenVisionLimitOperation, tokenVisionLimitType} from "./token-vision-limit.ts";

export const TokenVision = z.object({
  tokenVisionID: TokenVisionID,
  name: z.string(),
  offset: Point,
  accessMask: AccessMask,
  grayscale: z.boolean(),
  limit: z.optional(TokenVisionLimit)
});
export type TokenVision = z.infer<typeof TokenVision>;

export const TokenVisionOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-token-vision-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-name"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-offset"), operations: z.array(PointOperation)}),
  z.object({type: z.literal("update-access-mask"), operations: z.array(AccessMaskOperation)}),
  z.object({type: z.literal("update-grayscale"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-limit"), operations: z.array(ValueOperation(z.optional(TokenVisionLimit), TokenVisionLimitOperation))})
]);
export type TokenVisionOperation = z.infer<typeof TokenVisionOperation>;

export const tokenVisionType = new ObjectType({
  tokenVisionID: constantType,
  name: stringType,
  offset: pointType,
  accessMask: accessMaskType,
  grayscale: booleanType,
  limit: new ValueType(new OptionalType(tokenVisionLimitType))
}, v => {
  if (v.offset === undefined) v.offset = [0, 0];
  return v;
});

export const TokenVisionFn = {
  expand(valueRef: MutableRef<TokenVision, TokenVisionOperation[]>) {
    return {
      nameRef: valueRef.map<string, StringOperation[]>(value => value.name, (_, operations) => [{type: "update-name", operations}]),
      offsetRef: valueRef.map<Point, PointOperation[]>(value => value.offset, (_, operations) => [{type: "update-offset", operations}]),
      accessMaskRef: valueRef.map<AccessMask, AccessMaskOperation[]>(value => value.accessMask, (_, operations) => [{type: "update-access-mask", operations}]),
      grayscaleRef: valueRef.map<boolean, BooleanOperation[]>(value => value.grayscale, (_, operations) => [{type: "update-grayscale", operations}]),
      limitRef: valueRef.map<Optional<TokenVisionLimit>, ValueOperation<Optional<TokenVisionLimit>, TokenVisionLimitOperation>[]>(
        value => value.limit,
        (_, operations) => [{type: "update-limit", operations}]
      )
    }
  },
  copy(value: TokenVision): TokenVision {
    return ({
      ...value,
      tokenVisionID: generateTokenVisionID()
    });
  }
}
