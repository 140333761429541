import {ApplyAction, BooleanOperation, booleanType, ConstantOperation, NumberOperation, numberType, ValueFn, ValueOperation} from "common/types/index.ts";
import {ImageNode, ImageNodeOperation, ImageNodeSignals} from "common/legends/index.ts";
import {NameField} from "../name-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {OpacityField} from "../opacity-field.tsx";
import {SelectionMaskField} from "../selection-mask-field.tsx";
import {InputTransform} from "./transform/input-transform.tsx";
import {SizeField} from "../size-field.tsx";
import {useMemo} from "react";
import {InputFile} from "#lib/components/input/input-file.tsx";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {OriginField} from "./origin-field.tsx";
import {ConditionsField} from "./conditions/conditions-field.tsx";
import {MountableField} from "../mountable-field.tsx";
import {useObservable} from "#lib/qlab/index.ts";
import {Nullable} from "common/types/generic/nullable/index.ts";
import {AttachableField} from "../attachable-field.tsx";
import {MutableRef} from "common/ref";
import {PivotField} from "./pivot-field.tsx";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";

type RepeatInputProps = {
  title: string;
  value: MutableRef<Nullable<number>, ValueOperation<Nullable<number>, NumberOperation>[]>;
};

function RepeatInput({title, value}: RepeatInputProps) {
  const isInfinite = useMemo((): MutableRef<boolean, BooleanOperation[]> => {
    return new MutableRef<boolean, BooleanOperation[]>({
      value() {
        return value === null;
      },
      observe: pipe(value.observe, map(value => value === null), distinct()),
      apply: (fn: ApplyAction<boolean, BooleanOperation[]>) => value.apply((prev): ValueOperation<number | null, NumberOperation>[] => {
        const current = prev === null;
        const operations = fn(current);
        if (operations.length === 0) return [];
        const final = operations.reduce(booleanType.apply, current);
        if (current === final) return [];
        return ValueFn.set<number | null, NumberOperation>(prev, final ? null : 1);
      }).then(value => value === null)
    });
  }, [value]);

  const numberValue = useMemo((): MutableRef<number, NumberOperation[]> => {
    return new MutableRef({
      value() {
        const v = value.value;
        return v === null ? Number.POSITIVE_INFINITY : v;
      },
      observe: pipe(value.observe, map(value => value === null ? Number.POSITIVE_INFINITY : value), distinct()),
      apply: (fn: ApplyAction<number, NumberOperation[]>) => value.apply(prev => {
        if (prev !== null) {
          const operations = fn(prev);
          return ValueFn.apply(operations);
        } else {
          const operations = fn(1);
          const final = operations.reduce(numberType.apply, 1);
          return ValueFn.set(prev, final);
        }
      }).then(value => value === null ? Number.POSITIVE_INFINITY : value)
    })
  }, [value]);

  const isInfiniteValue = useObservable(isInfinite.observe, false, [isInfinite]);
  return (<InputGroup className="pr-0" title={title}>
    <InputGroupLabel>{title}</InputGroupLabel>
    <InputNumber disabled={isInfiniteValue} value={numberValue} placeholder={isInfiniteValue ? "Infinite" : ""} />
    <InputCheckbox value={isInfinite} />
  </InputGroup>)
}

export type ImageNodePropertiesProps = {
  value: MutableRef<ImageNode, ImageNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};
export function ImageNodeProperties({value, reference, pinned}: ImageNodePropertiesProps) {
  const {name, fileRef, normalRef, sizeRef, opacityRef, selectionMaskRef, transformRef, originRef, pivotRef, mountableRef, attachableRef, visibilityLayerRef, repeatXRef, repeatYRef, conditionsRef} = useMemo(() => ImageNodeSignals(value), [value]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <BaseComponent><NameField value={name} reference={reference} pinned={pinned} /></BaseComponent>
    <BaseComponent className="flex-row">
      <InputGroup><InputGroupLabel>Image</InputGroupLabel></InputGroup>
      <InputFile className="flex-1" value={fileRef} />
    </BaseComponent>
    <BaseComponent className="flex-row">
      <InputGroup><InputGroupLabel>Normal</InputGroupLabel></InputGroup>
      <InputFile className="flex-1" value={normalRef} />
    </BaseComponent>
    <BaseComponent><VisibilityLayerField value={visibilityLayerRef} /></BaseComponent>
    <BaseComponent><OpacityField value={opacityRef} /></BaseComponent>
    <BaseComponent><SelectionMaskField value={selectionMaskRef} /></BaseComponent>
    <BaseComponent><InputTransform value={transformRef} /></BaseComponent>
    <BaseComponent><OriginField valueRef={originRef} /></BaseComponent>
    <BaseComponent><PivotField valueRef={pivotRef} /></BaseComponent>
    <BaseComponent><SizeField value={sizeRef} /></BaseComponent>
    <BaseComponent><MountableField value={mountableRef} /></BaseComponent>
    <BaseComponent><AttachableField value={attachableRef} /></BaseComponent>
    <BaseComponent><RepeatInput title="Repeat X" value={repeatXRef} /></BaseComponent>
    <BaseComponent><RepeatInput title="Repeat Y" value={repeatYRef} /></BaseComponent>
    <BaseComponent><ConditionsField value={conditionsRef} /></BaseComponent>
  </div>
}