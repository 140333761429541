import {
  useArrayBuffer,
  useAttribLocation,
  useBindVertexArribArray,
  useElementArrayBuffer,
  useProgram,
  useShader,
  useUniformLocation,
  useVertexBuffer
} from "#lib/gl-react/index.ts";
import React, {useMemo} from "react";
import {Point, Size} from "common/types/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {useResolution} from "#lib/gl-react/hooks/resolution-context.ts";
import {Nullable} from "common/types/generic/nullable/index.ts";
import {usePVM} from "../context/pvm-context.ts";


const vertexShader = `#version 300 es
precision highp float;

in vec2 a_position;
in vec2 a_tex_coord;

uniform mat4 u_projection;
uniform mat4 u_view;
uniform mat4 u_model;

out vec2 v_world_pos;
out vec2 v_tex_coord;

void main()
{
  gl_Position = u_projection * u_view * u_model * vec4(a_position, 0, 1);
  v_world_pos = (u_model * vec4(a_position, 0, 1)).xy;
  v_tex_coord = a_tex_coord;
}
`;

const fragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

in vec2 v_world_pos;
in vec2 v_tex_coord;
uniform float rot;
uniform float u_opacity;
uniform sampler2D u_diffuse_texture;
uniform sampler2D u_normal_texture;
uniform bool u_normal_provided;

layout(location = 0) out vec4 color;
layout(location = 1) out vec4 normal;

void main() {
  vec2 f = fwidth(v_tex_coord);
  vec4 a = texture(u_diffuse_texture, v_tex_coord + vec2(0.0, 0.0));
  vec4 b = texture(u_diffuse_texture, v_tex_coord + vec2(f.x, 0.0)/2.)*sqrt(0.5);
  vec4 c = texture(u_diffuse_texture, v_tex_coord - vec2(f.x, 0.0)/2.)*sqrt(0.5);
  vec4 d = texture(u_diffuse_texture, v_tex_coord + vec2(0.0, f.y)/2.)*sqrt(0.5);
  vec4 e = texture(u_diffuse_texture, v_tex_coord - vec2(0.0, f.y)/2.)*sqrt(0.5);

  color = vec4(((a+b+c+d+e)/(1.+4.*sqrt(0.5))).rgb, a.a * u_opacity);
  if (u_normal_provided) {
    // float rot = atan(fwidth(v_world_pos.y), fwidth(v_world_pos.x));
    normal = texture(u_normal_texture, v_tex_coord);
    vec2 n = (normal.xy * 2.0) - 1.0;
    n = vec2(
      n.x * cos(rot) - n.y * sin(rot),
      n.x * sin(rot) + n.y * cos(rot)
    );
    n = (n + 1.0) / 2.0;
    normal = vec4(n.xy, normal.zw);
  } else {
    normal = vec4(0.5, 0.5, 1.0, 1.0);
  }
}
`;

export type ImageShaderProps = {
  albedoTexture: WebGLTexture;
  normalTexture?: WebGLTexture;
  origin: Point;
  size: Size;
  opacity: number;
  repeatX: Nullable<number>;
  repeatY: Nullable<number>;
};

export function ImageShader({origin, size, albedoTexture, normalTexture, opacity, repeatX, repeatY}: ImageShaderProps) {
  const {projection, view, model} = usePVM();
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );
  const projectionLocation = useUniformLocation(program, "u_projection");
  const viewLocation = useUniformLocation(program, "u_view");
  const modelLocation = useUniformLocation(program, "u_model");
  const opacityLocation = useUniformLocation(program, "u_opacity");
  const [sw, sh] = useResolution();


  const vbo = useArrayBuffer(useMemo(() => {
    const [x, y] = origin;
    const [w, h] = size;
    const m = Matrix4f.invert(Matrix4f.transform(model));
    const mv = Matrix4f.multiplyMatrix(m, Matrix4f.invert(Matrix4f.transform(view)));
    const mvp = Matrix4f.multiplyMatrix(mv, Matrix4f.invert(projection));

    const iptl = Matrix4f.multiplyVector(mvp, [-1, -1, 0, 1]);
    const iptr = Matrix4f.multiplyVector(mvp, [ 1, -1, 0, 1]);
    const ipbr = Matrix4f.multiplyVector(mvp, [ 1,  1, 0, 1]);
    const ipbl = Matrix4f.multiplyVector(mvp, [-1,  1, 0, 1]);
    const ittl = [(iptl[0]-x)/w, -(iptl[1]-y)/h, 0, 1];
    const ittr = [(iptr[0]-x)/w, -(iptr[1]-y)/h, 0, 1];
    const itbl = [(ipbl[0]-x)/w, -(ipbl[1]-y)/h, 0, 1];
    const itbr = [(ipbr[0]-x)/w, -(ipbr[1]-y)/h, 0, 1];

    const ipl = Matrix4f.multiplyVector(mvp, [-1,  0, 0, 1]);
    const ipr = Matrix4f.multiplyVector(mvp, [ 1,  0, 0, 1]);
    const itl = [(ipl[0]-x)/w, -(ipl[1]/h-y), 0, 1];

    const ipb = Matrix4f.multiplyVector(mvp, [ 0,  1, 0, 1]);
    const ipt = Matrix4f.multiplyVector(mvp, [ 0, -1, 0, 1]);
    const itb = [(ipb[0]-x)/w, -(ipb[1]/h-y), 0, 1];

    if (repeatX !== null && repeatY !== null) {
      const rptl = [        0 - x, 0         - y];
      const rptr = [w*repeatX - x, 0         - y];
      const rpbl = [        0 - x, h*repeatY - y];
      const rpbr = [w*repeatX - x, h*repeatY - y];

      const rttl = [      0, repeatY];
      const rttr = [repeatX, repeatY];
      const rtbr = [repeatX,       0];
      const rtbl = [      0,       0];
      return new Float32Array([
        rptl[0], rptl[1], rttl[0], rttl[1],
        rptr[0], rptr[1], rttr[0], rttr[1],
        rpbr[0], rpbr[1], rtbr[0], rtbr[1],
        rpbl[0], rpbl[1], rtbl[0], rtbl[1]
      ]);
    } else if (repeatX === null && repeatY === null) {
      return new Float32Array([
        iptl[0], iptl[1], ittl[0], ittl[1],
        iptr[0], iptr[1], ittr[0], ittr[1],
        ipbr[0], ipbr[1], itbr[0], itbr[1],
        ipbl[0], ipbl[1], itbl[0], itbl[1]
      ]);
    } else if (repeatX === null && repeatY !== null) {
      const repeatX = Math.ceil(Math.max(
        Math.pow(Math.pow(ipt[0] - ipb[0], 2) + Math.pow(ipt[1] - ipb[1], 2), 0.5),
        Math.pow(Math.pow(ipl[0] - ipr[0], 2) + Math.pow(ipl[1] - ipr[1], 2), 0.5),
      )) / w;

      const rptl = [ipl[0] - w*repeatX, h*repeatY - y];
      const rptr = [ipl[0] + w*repeatX, h*repeatY - y];
      const rpbr = [ipl[0] + w*repeatX,         0 - y];
      const rpbl = [ipl[0] - w*repeatX,         0 - y];

      const rttl = [itl[0] - repeatX, 0];
      const rttr = [itl[0] + repeatX, 0];
      const rtbr = [itl[0] + repeatX, repeatY];
      const rtbl = [itl[0] - repeatX, repeatY];

      return new Float32Array([
        rptl[0], rptl[1], rttl[0], rttl[1],
        rptr[0], rptr[1], rttr[0], rttr[1],
        rpbr[0], rpbr[1], rtbr[0], rtbr[1],
        rpbl[0], rpbl[1], rtbl[0], rtbl[1]
      ]);
    } else if (repeatX !== null && repeatY === null) {
      const repeatY = Math.ceil(Math.max(
        Math.pow(Math.pow(ipt[0] - ipb[0], 2) + Math.pow(ipt[1] - ipb[1], 2), 0.5),
        Math.pow(Math.pow(ipl[0] - ipr[0], 2) + Math.pow(ipl[1] - ipr[1], 2), 0.5),
      )) / h;

      const rptl = [        0 - x, ipb[1] - y - h*repeatY];
      const rptr = [w*repeatX - x, ipb[1] - y - h*repeatY];
      const rpbr = [w*repeatX - x, ipb[1] - y + h*repeatY];
      const rpbl = [        0 - x, ipb[1] - y + h*repeatY];

      const rttl = [      0, itb[1] + repeatY];
      const rttr = [repeatX, itb[1] + repeatY];
      const rtbr = [repeatX, itb[1] - repeatY];
      const rtbl = [      0, itb[1] - repeatY];
      return new Float32Array([
        rptl[0], rptl[1], rttl[0], rttl[1],
        rptr[0], rptr[1], rttr[0], rttr[1],
        rpbr[0], rpbr[1], rtbr[0], rtbr[1],
        rpbl[0], rpbl[1], rtbl[0], rtbl[1]
      ]);
    } else {
      throw new Error("Not Supported.");
    }
  }, [size, origin, repeatX, repeatY, sw, sh, projection, view, model]));
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_tex_coord"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 2 * 4);

  const ebo = useElementArrayBuffer(useMemo(() => new Uint16Array([
    0, 1, 2,
    2, 3, 0
  ]), []));

  const projectionMatrix4f = useMemo(() => new Float32Array(projection), [projection]);
  const viewMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(view)), [view]);
  const modelMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(model)), [model]);
  const diffuseTextureLocation = useUniformLocation(program, "u_diffuse_texture");
  const normalTextureLocation = useUniformLocation(program, "u_normal_texture");
  const normalProvidedLocation = useUniformLocation(program, "u_normal_provided");

  return (<program value={program}>
    <uniformMat4fv location={projectionLocation} transpose data={projectionMatrix4f}/>
    <uniformMat4fv location={viewLocation} transpose data={viewMatrix4f}/>
    <uniformMat4fv location={modelLocation} transpose data={modelMatrix4f}/>
    <uniform1f location={useUniformLocation(program, "rot")} data={(model.rotation / 180 * Math.PI)}/>
    <uniform1f location={opacityLocation} data={opacity}/>

    <activeTexture texture={WebGL2RenderingContext.TEXTURE0}/>
    <texture2d value={albedoTexture}>
      <uniform1i location={diffuseTextureLocation} data={0}/>
      <binder
        onBind={(context: WebGL2RenderingContext) => {
          if (normalTexture) {
            context.activeTexture(WebGL2RenderingContext.TEXTURE1);
            context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, normalTexture);
            context.uniform1i(normalTextureLocation, 1);
            context.uniform1i(normalProvidedLocation, 1);
          } else {
            context.uniform1i(normalProvidedLocation, 0);
          }
        }}
        onUnbind={(context: WebGL2RenderingContext) => {
          if (normalTexture) {
            context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, null);
            context.activeTexture(WebGL2RenderingContext.TEXTURE0);
          }
        }}
      >
        <vertexArray value={vao}>
          <elementArrayBuffer value={ebo}>
            <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={6}/>
          </elementArrayBuffer>
        </vertexArray>
      </binder>
    </texture2d>
  </program>);
}
