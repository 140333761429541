import {NameField} from "../name-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {InputTransform} from "./transform/input-transform.tsx";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {useMemo} from "react";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {TextNode, TextNodeOperation, TextNodeSignals} from "common/legends/node/text-node.ts";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {MutableRef} from "common/ref";
import {InputString} from "#lib/components/input/input-string.tsx";
import {InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {InputHorizontalAlignment} from "./input-horizontal-alignment.tsx";
import {InputVerticalAlignment} from "./input-vertical-alignment.tsx";
import {SelectionMaskField} from "../selection-mask-field.tsx";
import {PivotField} from "./pivot-field.tsx";
import {ConditionsField} from "./conditions/conditions-field.tsx";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";

export type TextNodePropertiesProps = {
  value: MutableRef<TextNode, TextNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};
export function TextNodeProperties({value, reference, pinned}: TextNodePropertiesProps) {
  const {name, pivotRef, textRef, sizeRef, visibilityLayerRef, transformRef, selectionMaskRef, fillColorRef, outlineColorRef, hTextAlignRef, vTextAlignRef, conditionsRef} = useMemo(() => TextNodeSignals(value), [value]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <BaseComponent><NameField value={name} reference={reference} pinned={pinned} /></BaseComponent>
    <BaseComponent><VisibilityLayerField value={visibilityLayerRef} /></BaseComponent>
    <BaseComponent><SelectionMaskField value={selectionMaskRef} /></BaseComponent>
    <BaseComponent><InputTransform value={transformRef} /></BaseComponent>
    <BaseComponent><PivotField valueRef={pivotRef} /></BaseComponent>
    <BaseComponent><InputGroup>
      <InputGroupLabel>Text</InputGroupLabel>
      <InputString value={textRef} />
    </InputGroup></BaseComponent>
    <BaseComponent><InputGroup>
      <InputGroupLabel>Size</InputGroupLabel>
      <InputNumber value={sizeRef} />
    </InputGroup></BaseComponent>
    <BaseComponent><InputHorizontalAlignment value={hTextAlignRef} /></BaseComponent>
    <BaseComponent><InputVerticalAlignment value={vTextAlignRef} /></BaseComponent>
    <BaseComponent><ColorField label="Fill Color" value={fillColorRef} /></BaseComponent>
    <BaseComponent><ColorField label="Outline Color" value={outlineColorRef} /></BaseComponent>
    <BaseComponent><ConditionsField value={conditionsRef} /></BaseComponent>
  </div>
}