import {ListOperation, ListSignal} from "common/types/index.ts";
import {Button, ButtonBar, IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import React, {useCallback, useState} from "react";
import {FaDiceD20} from "react-icons/fa6";
import {twMerge} from "tailwind-merge";
import {useSheetReference} from "../../../../../common/sheet/sheet-reference-context.ts";
import {useSelectedNodeIDRef} from "../../dnd-5e-character/use-selected-sheet.ts";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {useSheetSignal} from "../../../../../common/sheet/use-sheet-signal.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {ImportButton} from "#lib/components/button/import-button.tsx";
import {usePresent} from "../../../../../common/use-optional-signal.ts";
import {
  Dnd5eActionTemplate,
  Dnd5eActionTemplateFn,
  Dnd5eActionTemplateOperation,
  dnd5eActionTemplateType
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {Dnd5eActionTemplateID, generateDnd5eActionTemplateID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template-i-d.ts";
import {Dnd5eActionTemplateEditor} from "./dnd-5e-action-template-editor.tsx";
import {useRollActionTemplate} from "../../dnd-5e-character/dnd-5e-action/use-roll-template.ts";
import {useActionTemplateByID} from "./use-action-template-by-i-d.ts";

function Dnd5eActionTemplateListItem({item, remove}: InputListItemProps<Dnd5eActionTemplate, Dnd5eActionTemplateOperation>) {
  const action = useRefValue(item);
  const [edit, setEdit] = useState(false);
  const sheet = useSheetSignal(useSheetReference());
  const rollAction = useRollActionTemplate(useSelectedNodeIDRef(), sheet!, item);

  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/action", item, remove);
  return <div role="list-item" ref={dragRefPreview} className={twMerge("flex flex-col")}>
    <ButtonBar className="rounded-none" key={action.data.actionTemplateID}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroup className="flex-1 cursor-pointer" onClick={() => setEdit(true)}>
        <InputGroupLabel>{action.data.name}</InputGroupLabel>
      </InputGroup>
      <IconButton size="small" title="Roll" onClick={ev => rollAction(ev.shiftKey, ev.ctrlKey)}><FaDiceD20/></IconButton>
    </ButtonBar>
    {edit && <Dnd5eActionTemplateEditor valueRef={item} onClose={() => setEdit(false)} remove={remove}/>}
  </div>;
}

export function Dnd5eActionTemplatesView({valueRef, label = "Actions", className}: {
  valueRef: ListSignal<Dnd5eActionTemplate, Dnd5eActionTemplateOperation>;
  label?: string;
  className?: string;
}) {
  const [editActionTemplateID, setEditActionTemplateID] = useState<Dnd5eActionTemplateID | undefined>(undefined);

  const addCustomActionTemplate = useCallback(() => {
    const actionTemplateID = generateDnd5eActionTemplateID();
    valueRef.apply(prev => ListOperation.insert(prev.length, {
      type: "custom",
      data: {
        actionTemplateID: actionTemplateID,
        actionType: "action",
        favorite: false,
        name: "Action",
        abbreviation: "",
        segments: [],
        modifiers: [],
        effects: []
      }
    })).then(() => setEditActionTemplateID(actionTemplateID));
  }, [valueRef, setEditActionTemplateID]);

  const editSignal = usePresent(useActionTemplateByID(valueRef, editActionTemplateID));
  const remove = useCallback(() => valueRef.apply(prev => {
    if (editActionTemplateID === undefined) return [];
    const index = prev.findIndex(item => item.data.actionTemplateID === editActionTemplateID);
    if (index === -1) return [];
    return ListOperation.delete(index, prev[index]);
  }), [valueRef, editActionTemplateID]);

  return <div className={twMerge("flex flex-col gap-1", className)}>
    <div className="flex flex-row gap-0.5">
      <SectionHeader className="flex-1 items-center">{label}</SectionHeader>
      <Button onClick={addCustomActionTemplate}><FaPlus/> Add Action</Button>
      <ExpandOptions>
        <ImportButton<Dnd5eActionTemplate> type={dnd5eActionTemplateType} onImport={(next) => {
          valueRef.apply(prev => ListOperation.insert(prev.length, Dnd5eActionTemplateFn.copyActionTemplate(next)));
        }}>Import Action</ImportButton>
      </ExpandOptions>
    </div>

    <InputList<Dnd5eActionTemplate, Dnd5eActionTemplateOperation>
      accept="legends/action-template"
      items={valueRef}
      itemKey={Dnd5eActionTemplateFn.getActionTemplateID}
      copy={Dnd5eActionTemplateFn.copyActionTemplate}
      ListItem={Dnd5eActionTemplateListItem} />

    {editSignal && <Dnd5eActionTemplateEditor valueRef={editSignal} onClose={() => setEditActionTemplateID(undefined)} remove={remove} />}
  </div>
}
