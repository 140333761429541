import {
  Dnd5eActionTemplateSegmentAbilityCheckConditionModifier,
  Dnd5eActionTemplateSegmentAbilityCheckConditionModifierOperation,
  Dnd5eActionTemplateSegmentAbilityCheckConditionModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/ability-check/dnd-5e-action-template-segment-ability-check-condition-modifier.ts";
import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, Select, useToggle} from "#lib/components/index.ts";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {InputListItemProps} from "#lib/components/list/input-list.tsx";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {ConnectDragPreview, ConnectDragSource} from "react-dnd";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import React, {ChangeEvent, useMemo} from "react";
import {FaCopy, FaTrash} from "react-icons/fa";
import {Dnd5eActionTemplateSegmentAbilityCheckModifiersView} from "./dnd-5e-action-template-segment-ability-check-modifiers-view.tsx";
import {Dnd5eEffectID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-effect/index.ts";
import {Optional, ValueFn} from "common/types/generic/index.ts";
import {useActionRef} from "../../action-context.ts";
import {Dnd5eActionTemplateSignals} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {Dnd5eActionTemplateAddAbilityCheckModifierButton} from "./dnd-5e-action-template-add-ability-check-modifier-button.tsx";

export function Dnd5eActionTemplateSegmentAbilityCheckConditionModifierListItem({item, remove, duplicate, dragRefPreview, dragHandlerRef}: InputListItemProps<Dnd5eActionTemplateSegmentAbilityCheckConditionModifier, Dnd5eActionTemplateSegmentAbilityCheckConditionModifierOperation> & {
  dragHandlerRef: ConnectDragSource | ConnectDragPreview,
  dragRefPreview: ConnectDragSource | ConnectDragPreview
}) {
  const [expanded, toggleExpanded] = useToggle(false);

  const {conditionRef, modifiersRef} = useMemo(() => Dnd5eActionTemplateSegmentAbilityCheckConditionModifierSignals(item), [item]);
  const setEffectID = (effectID: Optional<Dnd5eEffectID>) => {
    conditionRef.apply(prev => ValueFn.set(prev, {
      type: "effect", data: {effectID}
    }));
  }

  const actionRef = useActionRef()!;
  const {effectsRef} = useMemo(() => Dnd5eActionTemplateSignals(actionRef), [actionRef]);
  const effects = useRefValue(effectsRef);
  const effectID = useRefValue(conditionRef).data.effectID;



  return <div className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 cursor-pointer" onClick={toggleExpanded}>Condition</SectionHeader>
      </ExpandableLabel>
      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>

    {expanded && <Fieldset>
        <InputGroup>
            <InputGroupLabel>Effect</InputGroupLabel>
            <Select value={effectID} onChange={(ev: ChangeEvent<HTMLSelectElement>) => {
              const effectID = (ev.target.value === "" ? undefined : ev.target.value) as Optional<Dnd5eEffectID>;
              setEffectID(effectID);
            }}>
                <option>N/A</option>
              {effects.map((effect) => <option key={effect.actionEffectID} value={effect.actionEffectID}>{effect.name}</option>)}
            </Select>
        </InputGroup>

        <div className="flex flex-col gap1">
          <ButtonBar>
            <SectionHeader className="flex-1">Modifiers</SectionHeader>
            <Dnd5eActionTemplateAddAbilityCheckModifierButton valueRef={modifiersRef}/>
          </ButtonBar>
          <Dnd5eActionTemplateSegmentAbilityCheckModifiersView valueRef={modifiersRef}/>
        </div>
    </Fieldset>}
  </div>
}