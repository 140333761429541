import {DiceExpression} from "common/dice/index.ts";
import {generateMessageID, generateRollRequestID, QLabMessageID, RollRequestID, RollRequests, RollVariables} from "common/qlab/index.ts";
import {Observable, toPromise} from "common/observable";
import {useCallback} from "react";
import {NodeId, Sheet, SheetOperation} from "common/legends/index.ts";
import {useInstance} from "#lib/qlab/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {encryptValue, Optional} from "common/types/index.ts";
import {Dnd5eAbilityCheckType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {useGetNodeIcon} from "../../../../../common/use-get-node-icon.ts";
import {useGlobalFeatures} from "./use-global-features.ts";
import {getActiveVariables} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";
import {useSeedTime} from "../../../../../../routes/game/use-seed-time.ts";
import {MutableRef} from "common/ref";
import {useMessageID} from "../../../../chat/message-context.ts";
import {getBaseAbilityCheck} from "./ability-check/get-base-ability-check.ts";
import {useStoreID} from "../../../../../../routes/game/model/store-context.tsx";

export function useSendAbilityCheck() {
  const instance = useInstance();
  const storeID = useStoreID();
  const userID = useUserID()!;
  const getNodeIcon = useGetNodeIcon();
  const time = useSeedTime();

  return async (nodeID: NodeId | undefined, abilityCheckType: Dnd5eAbilityCheckType, formula: DiceExpression, referenceMessageID: Optional<QLabMessageID>, variables?: RollVariables) => {
    const icon = await getNodeIcon(nodeID);
    const messageID = generateMessageID(time());
    const rollID = generateRollRequestID();
    const rollRequests = await encryptValue({}, () => Object.entries({
      [rollID]: formula
    }).reduce((rollRequests, [rollId, expression]) => {
      rollRequests[rollId as RollRequestID] = {
        expression: expression,
        variables: variables || {},
        visibility: {
          audience: {},
          default: {
            canViewExpression: true,
            canViewResult: true
          }
        }
      };
      return rollRequests;
    }, {} as RollRequests));

    return instance.applyToMessage(storeID, messageID, _ => [{
      type: "set",
      prevValue: undefined,
      nextValue: {
        type: "dnd-5e-ability-check-message",
        data: {
          userID,
          nodeID,
          icon,
          referenceMessageID,
          abilityCheckType: abilityCheckType,
          abilityRollID: rollID,
          rollRequests: rollRequests,
          rollResults: undefined
        }
      }
    }]);
  };
}

export function useRollAbilityCheck(nodeID: Observable<NodeId | undefined>, sheetRef: MutableRef<Optional<Sheet>, SheetOperation[]>) {
  const sendAbilityCheck = useSendAbilityCheck();
  const globalFeaturesRef = useGlobalFeatures();
  const referenceMessageID = useMessageID();
  return useCallback(async (abilityCheckType: Dnd5eAbilityCheckType, forceAdvantage: boolean, forceDisadvantage: boolean) => {
    const resolvedNodeID = await toPromise(nodeID);
    const globalFeatures = globalFeaturesRef.value;
    const sheet = sheetRef.value;
    if (sheet === undefined) return;

    const variables = getActiveVariables(sheet, globalFeatures);
    const formula = getBaseAbilityCheck(sheet, globalFeatures, abilityCheckType, forceAdvantage, forceDisadvantage);
    await sendAbilityCheck(resolvedNodeID, abilityCheckType, formula, referenceMessageID, variables);
  }, [nodeID, sheetRef.observe, sendAbilityCheck, referenceMessageID]);
}
