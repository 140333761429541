import React from "react";
import {Color, Transform} from "common/types/index.ts";
import {ElementHUDPass} from "./element-h-u-d-pass.tsx";
import {SelectionIndicator} from "./selection-indicator.tsx";
import {ModelProvider, usePVM} from "../../context/pvm-context.ts";
import {OpacityProvider, useOpacity} from "../../context/opacity-context.ts";
import {useIsSelected} from "../../context/use-is-selected.ts";
import {VideoNode} from "common/legends/node/video-node.ts";

export function VideoElementHUDPass({value}: {
  value: VideoNode;
}) {
  const {projection, view, model} = usePVM();
  const valueModel = Transform.divide(value.transform, model);
  const valueOpacity = value.opacity * useOpacity();
  const isSelected = useIsSelected(value.id);

  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        {[...value.children].reverse().map((child) => <ElementHUDPass key={child.data.id} value={child} />)}
        {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={value.origin} size={value.size} color={Color.WHITE} repeatX={1} repeatY={1} />}
        {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={[value.origin[0] - value.pivot[0]+2, value.origin[1] - value.pivot[1]+2]} size={[4,4]} color={Color.GREEN} repeatX={1} repeatY={1} />}
      </ModelProvider>
    </OpacityProvider>
  </binder>);
}