import {Matrix4f} from "#lib/math/index.ts";
import {Transform} from "common/types/index.ts";
import React, {useMemo} from "react";
import {ElementRenderPass} from ".//index.ts";
import {ParallaxNode} from "common/legends/node/parallax-node.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {useView, ViewProvider} from "../../context/pvm-context.ts";

export function ParallaxElementRenderPass({value}: {
  value: ParallaxNode;
}) {
  const view = useView();
  const viewParallax = useMemo((): Transform => {
    const offset = Matrix4f.invert(Transform.toMatrix4x4(value.transform));
    return {
      ...view,
      position: Vector2.multiplyMatrix4x4(view.position, offset)
    };
  }, [view, value.transform]);

  return (<binder>
    <ViewProvider value={viewParallax}>
      {[...value.children].reverse().map((element) => <ElementRenderPass key={element.data.id} value={element} />)}
    </ViewProvider>
  </binder>);
}