import {NameField} from "../name-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {OpacityField} from "../opacity-field.tsx";
import {SelectionMaskField} from "../selection-mask-field.tsx";
import {InputTransform} from "./transform/input-transform.tsx";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {TokenNode, TokenNodeOperation, TokenNodeSignals} from "common/legends/index.ts";
import {ActiveTokenField} from "./active-token-field.tsx";
import {useCallback, useMemo} from "react";
import {OwnersInput} from "../../asset-navigator/owners-input.tsx";
import {MountableField} from "../mountable-field.tsx";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {AttachableField} from "../attachable-field.tsx";
import {MutableRef} from "common/ref";
import {IconButton, InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";
import {useNavigateToAssetEditor} from "../../../viewport/character";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMap} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";

export type TokenNodePropertiesProps = {
  value: MutableRef<TokenNode, TokenNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};
export function TokenNodeProperties({value, reference, pinned}: TokenNodePropertiesProps) {
  const {name, visibilityLayerRef, ownerIDsRef, transformRef, opacityRef, selectionMaskRef, mountableRef, attachableRef, tokenReferenceRef, interfaceScaleRef} = useMemo(() => TokenNodeSignals(value), [value]);
  const navigateToAsset = useNavigateToAssetEditor();
  const navigateToToken = useCallback(() => {
    const tokenReference = tokenReferenceRef.value;
    if (!tokenReference) return;
    return navigateToAsset(tokenReference.assetID, tokenReference.tokenID);
  }, [tokenReferenceRef, navigateToAsset]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <BaseComponent className="flex flex-row">
      <NameField className="flex-1" value={name} reference={reference} pinned={pinned} />
      <IconButton title="Go to Token Editor" onClick={navigateToToken}>
        <FontAwesomeIcon icon={faMap} />
      </IconButton>
    </BaseComponent>
    <BaseComponent><VisibilityLayerField value={visibilityLayerRef} /></BaseComponent>
    <BaseComponent><OpacityField value={opacityRef} /></BaseComponent>
    <BaseComponent><SelectionMaskField value={selectionMaskRef} /></BaseComponent>
    <BaseComponent><InputTransform value={transformRef} /></BaseComponent>
    <BaseComponent><MountableField value={mountableRef} /></BaseComponent>
    <BaseComponent><AttachableField value={attachableRef} /></BaseComponent>
    <BaseComponent><OwnersInput value={ownerIDsRef} /></BaseComponent>
    <BaseComponent><ActiveTokenField valueRef={value} /></BaseComponent>
    <BaseComponent><InputGroup>
      <InputGroupLabel>Interface Scale</InputGroupLabel>
      <InputNumber value={interfaceScaleRef} />
    </InputGroup></BaseComponent>
  </div>
}