import {InputListItemProps} from "#lib/components/list/input-list.tsx";
import {
  Dnd5eActionTemplateSegmentSection,
  Dnd5eActionTemplateSegmentSectionOperation,
  Dnd5eActionTemplateSegmentSectionSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/section/dnd-5e-action-template-segment-section.ts";
import {ConnectDragPreview, ConnectDragSource} from "react-dnd";
import React, {useMemo} from "react";
import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {FaCopy, FaTrash} from "react-icons/fa";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {InputString} from "#lib/components/input/input-string.tsx";
import {Dnd5eActionTemplateSegmentsView} from "../dnd-5e-action-template-segments-view.tsx";
import {Dnd5eActionTemplateAddSegmentButton} from "./dnd-5e-action-template-add-segment-button.tsx";

export function Dnd5eActionTemplateSegmentSectionView({item, remove, duplicate, dragRefPreview, dragHandlerRef}: InputListItemProps<Dnd5eActionTemplateSegmentSection, Dnd5eActionTemplateSegmentSectionOperation> & {
  dragHandlerRef: ConnectDragSource | ConnectDragPreview,
  dragRefPreview: ConnectDragSource | ConnectDragPreview
}) {
  const {nameRef, segmentsRef} = useMemo(() => Dnd5eActionTemplateSegmentSectionSignals(item), [item])
  const [expanded, toggleExpanded] = useToggle(false);

  return <div role="list-item" className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>

      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 cursor-pointer" onClick={toggleExpanded}>Section</SectionHeader>
      </ExpandableLabel>

      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Effect" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>
    {expanded && <Fieldset>
      <InputGroup>
        <InputGroupLabel>Name</InputGroupLabel>
        <InputString value={nameRef} />
      </InputGroup>

      <ExpandableLabel expanded={true}>
          <SectionHeader className="flex-1">Segments</SectionHeader>
          <Dnd5eActionTemplateAddSegmentButton valueRef={segmentsRef} />
      </ExpandableLabel>
      <Dnd5eActionTemplateSegmentsView valueRef={segmentsRef} />
    </Fieldset>}
  </div>;
}
