import {useCallback} from "react";
import {Transform, ValueFn} from "common/types/index.ts";
import {AssetID, TokenID} from "common/legends/index.ts";
import {AccessMaskFn} from "common/legends/visibility/index.ts";
import {useDatabase, useStoreID} from "../../../../routes/game/model/store-context.tsx";
import {ToolFn} from "../../../common/tool/tool.ts";
import {useEditor} from "../../../panel/nav/editor/editor-context.ts";

export function useNavigateToAssetEditor() {
  const database = useDatabase();
  const editor = useEditor();
  const gameID = useStoreID();
  return useCallback((assetID: AssetID, tokenID?: TokenID) => {
    const asset = database.value.resources[assetID];
    if (asset?.type !== "asset") return;
    return editor.state.apply(prev => ValueFn.set(prev, ({
      type: "asset",
      data: {
        tokenReference: {gameID: gameID, assetID: assetID, tokenID: tokenID ?? asset.data.initialTokenId},
        toolMode: editor.state.value?.data.toolMode || ToolFn.DEFAULT,
        vision: editor.state.value?.data.vision || {mask: AccessMaskFn.DEFAULT, override: false},
        selectedNodeIds: [],
        view: Transform.DEFAULT
      }
    })));
  }, [database, editor.state, gameID])
}
