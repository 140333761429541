import React, {PropsWithChildren} from "react";
import {BlendFuncSeperate} from "#lib/gl-react/component/opengl/blend-func-seperate.tsx";

export function WallLightBlend({children}: PropsWithChildren<{}>) {
  return <BlendFuncSeperate
    srcRGB={WebGL2RenderingContext.SRC_ALPHA} dstRGB={WebGL2RenderingContext.ZERO}
    srcAlpha={WebGL2RenderingContext.SRC_ALPHA} dstAlpha={WebGL2RenderingContext.ZERO}>
    {children}
  </BlendFuncSeperate>;
}
