import {NameField} from "../name-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {Suspense, useMemo} from "react";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {LightNode, LightNodeOperation, LightNodeRef} from "common/legends/node/light/light-node.ts";
import {MutableRef} from "common/ref";
import {InputTransform} from "./transform/input-transform.tsx";
import {AccessMaskField} from "../access-mask-field.tsx";
import {SelectionMaskField} from "../selection-mask-field.tsx";
import {OriginField} from "./origin-field.tsx";
import {PivotField} from "./pivot-field.tsx";
import {LightShapeButtonBar} from "./image/light-shape-button-bar.tsx";
import {InputLightShape} from "./image/input-light-shape.tsx";
import {useComputedValue} from "#lib/signal/index.ts";
import {ConditionsField} from "./conditions/conditions-field.tsx";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";

export type LightNodePropertiesProps = {
  valueRef: MutableRef<LightNode, LightNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};
export function LightNodeProperties({valueRef, reference, pinned}: LightNodePropertiesProps) {
  const nodeID = useComputedValue(valueRef, value => value.id);
  const {name, originRef, pivotRef, visibilityLayerRef, selectionMaskRef, accessMaskRef, transformRef, shapeRef, conditionsRef} = useMemo(() => LightNodeRef(valueRef), [valueRef]);

  const isShapeSelected = useComputedValue(shapeRef, shape => shape !== undefined);


  return <div className="tab-content flex flex-col py-2 gap-1">
    <BaseComponent><NameField value={name} reference={reference} pinned={pinned} /></BaseComponent>
    <BaseComponent><AccessMaskField value={accessMaskRef} /></BaseComponent>
    <BaseComponent><VisibilityLayerField value={visibilityLayerRef} /></BaseComponent>
    <BaseComponent><SelectionMaskField value={selectionMaskRef} /></BaseComponent>
    <BaseComponent><InputTransform value={transformRef} /></BaseComponent>
    <BaseComponent><OriginField valueRef={originRef} /></BaseComponent>
    <BaseComponent><PivotField valueRef={pivotRef} /></BaseComponent>
    <BaseComponent><ConditionsField value={conditionsRef}/></BaseComponent>

    <BaseComponent>
      <LightShapeButtonBar valueRef={shapeRef} />
      {isShapeSelected && <Suspense fallback={<></>}>
        <InputLightShape nodeID={nodeID} valueRef={shapeRef} />
      </Suspense>}
    </BaseComponent>
  </div>
}