import {InputMask} from "#lib/components/input-mask/index.ts";
import {MutableRef} from "common/ref";
import {EditorVision, EditorVisionFn, EditorVisionOperation} from "../../../../viewport/scene/editor-vision.ts";
import {useMemo} from "react";
import {IconButton} from "#lib/components/index.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeEvil} from "@awesome.me/kit-c9bc6845cd/icons/sharp/solid";
import {useRefValue} from "#lib/signal/index.ts";
import {BooleanFn} from "common/types/generic/index.ts";

export function InputEditorVision({valueRef}: {
  valueRef: MutableRef<EditorVision, EditorVisionOperation[]>
}) {
  const {maskRef, overrideRef} = useMemo(() => EditorVisionFn.expand(valueRef), [valueRef]);
  const override = useRefValue(overrideRef);

  return <>
    <IconButton title={override ? "Token Visibility Disabled" : "Token Visibility Enabled"} className="rounded-md" onClick={() => overrideRef.apply(prev => BooleanFn.set(prev, !prev))}>
      <FontAwesomeIcon icon={override ? faEyeEvil : faEye} />
    </IconButton>
    <InputMask title="Global Access Mask" value={maskRef}/>
  </>
}