import {KeyboardEvent, MouseEvent, RefObject, useCallback, useRef} from "react";
import {ListOperation, Optional, Point, PointFn, Transform, TransformOperation, Tree, TreeOperation, ValueFn} from "common/types/index.ts";
import {Grid, Node, NodeOperation} from "common/legends/index.ts";
import {useKeyEventHandlers} from "../tool-selector/use-key-event-handlers.ts";
import {useMouseEventHandlers, useWheelEventHandlers} from "../tool-selector/use-mouse-event-handlers.ts";
import {useAreaMode} from "./use-area-mode.ts";
import {useEditorAreaTool} from "./use-editor-area-tool.ts";
import {useEditorAreaNode} from "./use-editor-area-node.ts";
import {useComputedValue} from "#lib/signal/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {Spline, SplineFn, SplineOperation} from "common/types/generic/spline/index.ts";
import {isPointInArea} from "./is-point-in-area.ts";
import {MutableRef, Ref} from "common/ref";
import {AreaToolSelection} from "../../../../../common/tool/area/area-tool-select-mode.ts";
import {AreaToolData, AreaToolDataOperation} from "../../../../../common/tool/area/area-tool-data.ts";
import {getWorldPositionFromScreenPosition} from "../../../../../viewport/tool/use-get-world-pos.ts";
import {getScreenPosition} from "../../../../../viewport/tool/use-get-screen-pos.ts";
import {getNodePositionFromWorldPosition} from "../../../../../viewport/tool/use-world-pos-to-node-pos.ts";
import {Tool, ToolOperation} from "../../../../../common/tool/tool.ts";
import {ToolHandlers} from "../../../../../../routes/game/use-tool-handlers.ts";
import {usePanHandlers} from "../../../../../viewport/tool/use-pan-handlers.ts";
import {useTouchHandlers} from "../../../../../viewport/tool/use-touch-handlers.ts";
import {useZoomHandlers} from "../../../../../viewport/tool/use-zoom-tool-handlers.ts";
import {cubicBezier} from "common/math/bezier/bezier.ts";
import {getNodeTransform} from "../../../../../viewport/tool/use-node-pos-to-world-pos.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {Matrix4x4Fn} from "common/math/matrix/matrix4x4.ts";

function getAreaSelections(areas: Spline[], point: Point, distance: number = 16): AreaToolSelection[] {
  let selections: AreaToolSelection[] = [];
  for (let areaIndex = 0; areaIndex < areas.length; areaIndex ++) {
    const area = areas[areaIndex];

    for (let edgeIndex = 0; edgeIndex < area.curves.length + 1; edgeIndex ++) {
      const start = edgeIndex === 0 ? area.start : area.curves[edgeIndex - 1].end;
      const end = edgeIndex === area.curves.length ? area.start : area.curves[edgeIndex].end;
      const controlPoint1 = edgeIndex === 0 ? area.controlPoint2 : area.curves[edgeIndex - 1].controlPoint2;
      const controlPoint2 = edgeIndex === area.curves.length ? area.controlPoint1 : area.curves[edgeIndex].controlPoint1;
      const cp1 = Vector2.add(start, controlPoint1);
      const cp2 = Vector2.add(end, controlPoint2);
      if (Vector2.distance(cp1, point) < distance) {
        selections.push({type: "control-point", areaIndex, edgeIndex, controlPointIndex: 0});
      }
      if (Vector2.distance(cp2, point) < distance) {
        selections.push({type: "control-point", areaIndex, edgeIndex, controlPointIndex: 1});
      }
    }

    for (let vertexIndex = 0; vertexIndex < area.curves.length + 1; vertexIndex ++) {
      const vertex = vertexIndex === 0 ? area.start : area.curves[vertexIndex - 1].end;
      const dist = Vector2.distance(point, vertex);
      if (dist < distance) {
        selections.push({type: "vertex", areaIndex, vertexIndex});
      }
    }

    for (let edgeIndex = 0; edgeIndex < area.curves.length + 1; edgeIndex ++) {
      const lines = cubicBezier(
        edgeIndex === 0 ? area.start : area.curves[edgeIndex - 1].end,
        edgeIndex === area.curves.length ? area.start : area.curves[edgeIndex].end,
        edgeIndex === 0 ? area.controlPoint2 : area.curves[edgeIndex - 1].controlPoint2,
        edgeIndex === area.curves.length ? area.controlPoint1 : area.curves[edgeIndex].controlPoint1,
        8
      );
      for (let i = 0; i < lines.length - 1; i ++) {
        const t = Vector2.pointIntersect(lines[i], lines[i+1], point);
        if (t < 0.001 || t > 0.999) continue;
        const p = Vector2.lerp(lines[i], lines[i+1], t);
        const dist = Vector2.distance(p, point);
        if (dist < distance) {
          selections.push({type: "edge", areaIndex, edgeIndex});
          break;
        }
      }
    }

    if (isPointInArea(point, area)) {
      selections.push({type: "area", areaIndex});
    }
  }

  return selections;
}


function useAreaToolSelectMode(
  areaTool: MutableRef<Optional<AreaToolData>, AreaToolDataOperation[]>,
  canvasRef: RefObject<HTMLCanvasElement>,
  view: MutableRef<Transform, TransformOperation[]>,
  nodesRef: Ref<Node[]>,
  grid: Grid,
  areaNodeRef: MutableRef<Optional<Spline[]>, ListOperation<Spline, SplineOperation>[]>
) {
  const isSelect = useComputedValue(areaTool, areaTool => areaTool?.mode?.type === "select");
  const select = useAreaMode("select", areaTool);

  const onSelectMouseDown = useCallback((ev: MouseEvent) => {
    if (!isSelect) return true;
    const selection = select.value;
    const nodeID = areaTool.value?.nodeID;
    const areaNode = areaNodeRef.value;
    const nodes = nodesRef.value;
    if (areaNode === undefined) return true;
    if (nodeID === undefined) return true;
    const node = Tree.getItemById(nodes, nodeID);
    if (node === undefined) return true;

    const worldPos = getWorldPositionFromScreenPosition(view.value, getScreenPosition(canvasRef.current!, [ev.clientX, ev.clientY]));
    const nodePos = getNodePositionFromWorldPosition(
      nodes,
      nodeID,
      worldPos
    );

    const selections = getAreaSelections(areaNode, Vector2.add(nodePos, node.data.origin))
      .filter(s => s.type !== "control-point" || (selection?.type === "edge" || selection?.type === "control-point") && s.edgeIndex === selection.edgeIndex);
    select.apply(prev => ValueFn.set(prev, selections.length > 0 ? selections[0] : undefined));
    return false;
  }, [isSelect, select, nodesRef, areaNodeRef]);

  const onSelectVertexMouseUp = useCallback((ev: MouseEvent) => {
    const selection = select.value;
    const nodeID = areaTool.value?.nodeID;
    if (nodeID === undefined) return true;
    const nodes = nodesRef.value;
    const node = Tree.getItemById(nodes, nodeID);
    if (node === undefined) return true;

    if (selection?.type !== "vertex") return true;
    const {areaIndex, vertexIndex} = selection;
    const worldPos = getWorldPositionFromScreenPosition(view.value, getScreenPosition(canvasRef.current!, [ev.clientX, ev.clientY]));
    const nodeTransform = getNodeTransform(nodes, nodeID);
    const nodePos = Vector2.divideTransform(worldPos, nodeTransform);

    const snapPos = !ev.shiftKey ? Grid.snap({...grid, subdivisions: grid.subdivisions + 1}, Vector2.add(nodePos, node.data.origin)) : Vector2.add(nodePos, node.data.origin);
    areaNodeRef.apply(prev => {
      if (prev === undefined) return [];
      const area = prev[areaIndex];
      if (area === undefined) return [];
      const vertex = vertexIndex === 0 ? area.start : area.curves[vertexIndex - 1].end;
      const operations = PointFn.set(vertex, snapPos);
      if (operations.length === 0) return [];
      return ListOperation.apply(areaIndex, vertexIndex === 0
        ? [{type: "update-start", operations}]
        : [{type: "update-curves", operations: ListOperation.apply(vertexIndex - 1, [{type: "update-end", operations}])}]
      );
    });
    ev.preventDefault();
    return false;
  }, [select, view, canvasRef, areaNodeRef, nodesRef]);

  const onSelectControlPointMouseUp = useCallback((ev: MouseEvent) => {
    const value = select.value;
    const nodeID = areaTool.value?.nodeID;
    if (nodeID === undefined) return true;
    const nodes = nodesRef.value;
    const node = Tree.getItemById(nodes, nodeID);
    if (node === undefined) return true;
    if (value?.type !== "control-point") return true;
    const {areaIndex, edgeIndex, controlPointIndex} = value;
    const worldPos = getWorldPositionFromScreenPosition(view.value, getScreenPosition(canvasRef.current!, [ev.clientX, ev.clientY]));
    const nodeTransform = getNodeTransform(nodesRef.value, nodeID);
    const nodePos = Vector2.divideTransform(worldPos, nodeTransform);
    const snapPos = !ev.shiftKey ? Grid.snap({...grid, subdivisions: grid.subdivisions + 1}, Vector2.add(nodePos, node.data.origin)) : Vector2.add(nodePos, node.data.origin);
    areaNodeRef.apply(prev => {
      if (prev === undefined) return [];
      const area = prev[areaIndex];
      if (area === undefined) return [];
      const start = edgeIndex === 0 ? area.start : area.curves[edgeIndex - 1].end;
      const end = edgeIndex === area.curves.length ? area.start : area.curves[edgeIndex].end;
      const controlPoint1 = edgeIndex === 0 ? area.controlPoint2 : area.curves[edgeIndex - 1].controlPoint2;
      const controlPoint2 = edgeIndex === area.curves.length ? area.controlPoint1 : area.curves[edgeIndex].controlPoint1;
      const operations = controlPointIndex === 0
        ? PointFn.set(controlPoint1, Vector2.subtract(snapPos, start))
        : PointFn.set(controlPoint2, Vector2.subtract(snapPos, end));
      if (operations.length === 0) return [];
      if (controlPointIndex === 0) {
        return ListOperation.apply(areaIndex, edgeIndex === 0
          ? [{type: "update-control-point-2", operations}]
          : [{type: "update-curves", operations: ListOperation.apply(edgeIndex - 1, [{type: "update-control-point-2", operations}])}]
        );
      } else {
        return ListOperation.apply(areaIndex, edgeIndex === area.curves.length
          ? [{type: "update-control-point-1", operations}]
          : [{type: "update-curves", operations: ListOperation.apply(edgeIndex, [{type: "update-control-point-1", operations}])}]
        );
      }
    });
    ev.preventDefault();
    return false;
  }, [select, view, canvasRef, areaNodeRef, nodesRef]);

  const onSelectMouseUp = useMouseEventHandlers(onSelectVertexMouseUp, onSelectControlPointMouseUp);


  const onSelectKeyDown = useCallback((ev: KeyboardEvent): boolean => {
    if (!isSelect) return true;
    const selection = select.value;
    if (selection === undefined) return true;
    if (ev.key === "Escape") {
      if (!select.value) return true;
      select.apply(mode => ValueFn.set(mode, undefined));
      ev.preventDefault();
      return false;
    } else if (ev.key === "Delete") {
      if (selection?.type === "area") {
        areaNodeRef.apply(prev => {
          if (prev === undefined) return [];
          return ListOperation.delete(selection.areaIndex, prev[selection.areaIndex]);
        });
        select.apply(prev => ValueFn.set(prev, undefined));
      } else if (selection?.type === "vertex") {
        areaNodeRef.apply(prev => {
          if (prev === undefined) return [];
          if (prev[selection.areaIndex].curves.length > 2) {
            return ListOperation.apply(selection.areaIndex, SplineFn.deleteVertex(prev[selection.areaIndex], selection.vertexIndex))
          } else {
            return ListOperation.delete(selection.areaIndex, prev[selection.areaIndex]);
          }
        });
        select.apply(prev => ValueFn.set(prev, undefined));
      }
      return false;
    } else {
      return true;
    }
  }, [select, isSelect, areaNodeRef]);

  return {
    onSelectMouseDown,
    onSelectMouseUp,
    onSelectKeyDown
  };
}

function useAreaToolCreateMode(
  areaTool: MutableRef<Optional<AreaToolData>, AreaToolDataOperation[]>,
  canvasRef: RefObject<HTMLCanvasElement>,
  view: MutableRef<Transform, TransformOperation[]>,
  nodesRef: Ref<Node[]>,
  grid: Grid,
  areaRef: MutableRef<Optional<Spline[]>, ListOperation<Spline, SplineOperation>[]>
) {
  const isCreate = useComputedValue(areaTool, areaTool => areaTool?.mode?.type === "create");
  const create = useAreaMode("create", areaTool);

  const createArea = useCallback(() => {
    areaRef.apply(prev => {
      const nodeID = areaTool.value?.nodeID;
      if (nodeID === undefined) return [];
      if (prev === undefined) return [];
      let spline = create.value;
      if (spline === undefined || spline.curves.length < 2) return [];
      const nodes = nodesRef.value;
      const node = Tree.getItemById(nodes, nodeID);
      if (node === undefined) return [];
      return ListOperation.insert(prev.length, spline);
    });
    create.apply(mode => (mode === undefined) ? [] : ValueFn.set(mode, undefined));
  }, [areaRef, create, nodesRef, areaTool]);

  const onCreateMouseDown = useCallback((ev: MouseEvent): boolean => {
    if (!isCreate) return true;
    if (ev.button === 0 && ev.detail === 2) {
      createArea();
      return false;
    } else if (ev.button === 0) {
      const nodes = nodesRef.value;
      const nodeID = areaTool.value?.nodeID;
      if (nodeID === undefined) return true;
      const node = Tree.getItemById(nodes, nodeID);
      if (node === undefined) return true;
      const nodeTransform = getNodeTransform(nodes, nodeID);

      const worldPos = getWorldPositionFromScreenPosition(view.value, getScreenPosition(canvasRef.current!, [ev.clientX, ev.clientY]));
      const nodePos = Vector2.add(Vector2.multiplyMatrix4x4(worldPos, Matrix4x4Fn.invert(
        Matrix4f.transform(nodeTransform)
      )), node.data.origin);
      const snapPos = !ev.shiftKey ? Grid.snap({...grid, subdivisions: grid.subdivisions + 1}, nodePos) : nodePos;
      // create point in spline
      create.apply(prev => {
        if (prev === undefined) {
          return ValueFn.set(prev, {
            start: snapPos,
            controlPoint1: [0, 0],
            controlPoint2: [0, 0],
            curves: [],
            closed: true
          });
        } else {
          return ValueFn.apply([{
            type: "update-curves",
            operations: ListOperation.insert(prev.curves.length, {
              end: snapPos,
              controlPoint1: [0, 0],
              controlPoint2: [0, 0]
            })
          }]);
        }
      });
      isDragging.current = true;
      return false;
    } else {
      return true;
    }
  }, [view, create, grid, createArea, isCreate, nodesRef, areaTool]);

  let isDragging = useRef(false);
  const onCreateMouseUp = useCallback((_: MouseEvent): boolean => {
    if (!isCreate) return true;
    isDragging.current = false;
    return true;
  }, [isDragging, isCreate]);

  const onCreateKeyDown = useCallback((ev: KeyboardEvent): boolean => {
    if (!isCreate) return true;
    if (create.value === undefined) return true;
    if (ev.key === "Escape") {
      if (!create.value) return true;
      create.apply(mode => ValueFn.set(mode, undefined));
      ev.preventDefault();
      return false;
    } else if (ev.key.toLowerCase() === "z" && ev.ctrlKey) {
      create.apply(spline => {
        if (spline === undefined) return [];
        if (spline.curves.length > 0) {
          return ValueFn.apply([{
            type: "update-curves",
            operations: ListOperation.delete(spline.curves.length - 1, spline.curves[spline.curves.length - 1])
          }]);
        } else {
          return ValueFn.set(spline, undefined);
        }
      });
      return false;
    } else if (ev.key === "Enter") {
      createArea();
      return false;
    } else {
      return true;
    }
  }, [create, createArea, isCreate]);

  return {
    onCreateMouseDown,
    onCreateMouseUp,
    onCreateKeyDown
  };
}

export function useAreaToolHandlers(
  tool: MutableRef<Tool, ToolOperation[]>,
  canvasRef: RefObject<HTMLCanvasElement>,
  viewRef: MutableRef<Transform, TransformOperation[]>,
  grid: Grid,
  nodesRef: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>
): ToolHandlers {
  const areaTool = useEditorAreaTool();
  const areaNode = useEditorAreaNode(areaTool, nodesRef);

  const {onPanMouseDown, onPanMouseMove, onPanMouseUp, onPanMouseEnter, onPanWheel, onPanContextMenu} = usePanHandlers(canvasRef, viewRef, buttons => buttons === 2 || buttons === 4);
  const {onTouchStart, onTouchMove, onTouchEnd} = useTouchHandlers(canvasRef, viewRef);
  const {onZoomWheel, onZoomKeyDown} = useZoomHandlers(canvasRef, viewRef);
  const {onSelectMouseDown, onSelectMouseUp, onSelectKeyDown} = useAreaToolSelectMode(areaTool, canvasRef, viewRef, nodesRef, grid, areaNode);
  const {onCreateKeyDown, onCreateMouseDown, onCreateMouseUp} = useAreaToolCreateMode(areaTool, canvasRef, viewRef, nodesRef, grid, areaNode);

  const onWheel = useWheelEventHandlers(onPanWheel, onZoomWheel);
  const onMouseDown = useMouseEventHandlers(onPanMouseDown, onCreateMouseDown, onSelectMouseDown);
  const onMouseUp = useMouseEventHandlers(onPanMouseUp, onCreateMouseUp, onSelectMouseUp);
  const onMouseMove = useMouseEventHandlers(onPanMouseMove);
  const onKeyDown = useKeyEventHandlers(onZoomKeyDown, onCreateKeyDown, onSelectKeyDown);

  return {
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onMouseEnter: onPanMouseEnter,
    onWheel,
    onKeyDown,
    onContextMenu: onPanContextMenu,
    onKeyUp: useCallback(() => false, []),
    onDrop: useCallback(() => {}, []),
    onTouchStart,
    onTouchMove,
    onTouchEnd
  };
}
