import {Point} from "#common/types/generic/point/index.ts";
import {Matrix4x4, Matrix4x4Fn} from "../matrix/matrix4x4.ts";
import {Transform} from "../../types/index.ts";

export type Vector2 = [number, number];

export const Vector2 = {
  add: ([x1, y1]: Vector2, [x2, y2]: Vector2): Vector2 => {
    return [x1+x2, y1+y2];
  },
  abs: ([x, y]: Vector2): Vector2 => {
    return [Math.abs(x), Math.abs(y)];
  },
  subtract: ([x1, y1]: Vector2, [x2, y2]: Vector2): Vector2 => {
    return [x1-x2, y1-y2];
  },
  multiply: ([x, y]: Vector2, s: number): Vector2 => {
    return [x*s, y*s];
  },
  multiplyMatrix4x4: ([x, y]: Vector2, mat4x4: Matrix4x4): Vector2 => {
    const [nx, ny, _w, _z] = Matrix4x4Fn.multiplyVector(mat4x4, [x, y, 0, 1]);
    return [nx, ny];
  },
  multiplyTransform: ([x, y]: Vector2, transform: Transform): Vector2 => {
    return Vector2.add(
      Vector2.rotate(
        Vector2.multiply(
          [x, y],
          transform.scale
        ),
        transform.rotation * Math.PI / 180
      ),
      transform.position
    );
  },
  divideTransform: ([x, y]: Vector2, transform: Transform): Vector2 => {
    return Vector2.multiply(
      Vector2.rotate(
        Vector2.subtract(
          [x, y],
          transform.position
        ),
        -(transform.rotation * Math.PI / 180)
      ),
      1 / transform.scale
    );
  },
  lerp: (a: Vector2, b: Vector2, t: number): Vector2 => {
    return Vector2.add(a, Vector2.multiply(Vector2.subtract(b, a), t));
  },
  length: ([x, y]: Vector2): number => {
    return Math.pow(x*x+y*y, 0.5);
  },
  normalize: (a: Vector2): Vector2 => {
    return Vector2.multiply(a, 1/Vector2.length(a));
  },
  dot: ([x1, y1]: Vector2, [x2, y2]: Vector2): number => {
    return (x1*x2)+(y1*y2);
  },
  cross: ([x1, y1]: Vector2, [x2, y2]: Vector2): number => {
    return x1*y2-y1*x2;
  },
  distance: (a: Point, b: Point): number => {
    return Vector2.length(Vector2.subtract(b, a))
  },
  pointIntersect: (a: Point, b: Point, c: Point): number => {
    const line = Vector2.subtract(b, a);
    const length = Vector2.length(line);
    let unit = Vector2.multiply(line, 1 / length);
    return Vector2.dot(unit, Vector2.subtract(c, a)) / length;
  },
  lineIntersect: (a: Point, b: Point, c: Point, d: Point): number => {
    const ab = Vector2.subtract(b, a);
    const cd = Vector2.subtract(d, c);
    const ca = Vector2.subtract(a, c);
    const denominator = Vector2.cross(ab, cd);
    if (denominator == 0) return Number.POSITIVE_INFINITY;
    return Vector2.cross(cd, ca) / denominator;
  },
  perpendicular: (a: Point, b: Point): Vector2 => {
    const line = Vector2.subtract(b, a);
    return Vector2.normalize([line[1], -line[0]])
  },
  rotate: ([x, y]: Point, r: number): Point => {
    return [
      Math.cos(r) * x - Math.sin(r) * y,
      Math.sin(r) * x + Math.cos(r) * y
    ]
  },
  equals: ([x1, y1]: Vector2, [x2, y2]: Vector2): boolean => {
    return x1 === x2 && y1 === y2;
  }
};
