import {generateSheetID, Sheet, SheetID, SheetInterface, SheetOperation, SheetPolicy} from "common/legends/index.ts";
import {ButtonBar, IconButton, InputGroup, Select, useToggle} from "#lib/components/index.ts";
import {FaCopy, FaFile, FaLink, FaPlus, FaTrash} from "react-icons/fa";
import {CreateSheetModal} from "./create-sheet-modal.tsx";
import {useObservable, useObservableLoader} from "#lib/qlab/index.ts";
import {ConstantOperation, MapFn, MapRef, Optional, ValueFn, ValueOperation} from "common/types/index.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {MutableRef} from "common/ref";
import {useCallback} from "react";
import {useRefValue} from "#lib/signal/index.ts";

export type SheetFieldProps = {
  sheets: MapRef<SheetID, Sheet, SheetOperation>;
  sheetPolicy: MutableRef<SheetPolicy, ValueOperation<SheetPolicy, ConstantOperation>[]>;
  sheetID: MutableRef<Optional<SheetID>, ValueOperation<Optional<SheetID>, ConstantOperation>[]>;
};
export function SheetField({sheets, sheetID: sheetIDRef, sheetPolicy}: SheetFieldProps) {
  function setSheetID(next: Optional<SheetID>) {
    sheetIDRef.apply(prev => ValueFn.set(prev, next));
  }
  async function deleteSheet(id: Optional<SheetID>) {
    if (id !== undefined) {
      sheets.apply(prev => MapFn.delete(id, prev[id]!));
      setSheetID(undefined);
    }
  }

  const [createSheet, toggleCreateSheet, setCreateSheet] = useToggle(false);
  const onCreate = (sheetID: SheetID, sheet: Sheet) => {
    sheets.apply(_ => MapFn.put(sheetID, sheet));
    setSheetID(sheetID);
    setCreateSheet(false);
  };

  const duplicateSheet = useCallback((sheetID: Optional<SheetID>) => {
    if (!sheetID) return;
    sheets.apply(prev => {
      const sheet = prev[sheetID];
      if (!sheet) return [];
      return MapFn.put(generateSheetID(), sheet);
    });
  }, [])

  const sheetID = useRefValue(sheetIDRef);
  const sheetPolicyLoader = useObservableLoader(sheetPolicy.observe);

  const sheetValues = useObservable(sheets.observe, {}, [sheets.observe]);

  return <div className="flex flex-row gap-0">
    <ButtonBar className="w-full">
      <IconButton onClick={() => sheetPolicy.apply(prev => ValueFn.set(prev, prev === "link" ? "copy" : "link"))} title={
        sheetPolicyLoader.isSuccess && sheetPolicyLoader.data !== "link" ? "Copy Sheet" : "Link Sheet"
      }>
        {sheetPolicyLoader.isSuccess && sheetPolicyLoader.data === "link" && <FaLink />}
        {sheetPolicyLoader.isSuccess && sheetPolicyLoader.data !== "link" && <FaCopy />}
      </IconButton>
      <InputGroup className="flex-1 pr-0 gap-2" title="Sheet">
        <InputGroupIcon><FaFile/></InputGroupIcon>
        <Select aria-placeholder="Sheet" value={sheetID} onChange={ev => setSheetID(ev.target.value === "" ? undefined : ev.target.value as SheetID)}>
          <option value={""}>&nbsp;</option>
          {(Object.keys(sheetValues) as SheetID[]).map((sheetId) =>
            <option key={sheetId} value={sheetId}>{SheetInterface.getName(sheetValues[sheetId]!)}</option>
          )}
        </Select>
      </InputGroup>
      <IconButton title="Create Sheet" onClick={toggleCreateSheet}><FaPlus /></IconButton>
      <IconButton disabled={!sheetID} title="Duplicate Sheet" onClick={() => duplicateSheet(sheetID)}><FaCopy /></IconButton>
      <IconButton disabled={!sheetID} variant="destructive" title="Delete Sheet" onClick={() => deleteSheet(sheetID)}><FaTrash /></IconButton>
      {createSheet && <CreateSheetModal onClose={toggleCreateSheet} onCreate={onCreate} />}
    </ButtonBar>
  </div>
}