import {
  Color,
  ConstantOperation,
  ConstantType,
  ListOperation,
  ListType,
  MapOperation,
  MapType,
  ObjectType,
  SetOperation,
  SetType,
  Type,
  ValueOperation,
  ValueType
} from "../../types/index.ts";
import {generateTokenID, Token, TokenID, TokenOperation, tokenType} from "./token/index.ts";
import {UserID} from "../user/index.ts";
import {VisibilityLayer, VisibilityLayerOperation, visibilityLayerType} from "#common/legends/visibility/index.ts";
import {Sheet, SheetID, SheetOperation, sheetType} from "#common/legends/asset/sheet/index.ts";
import {Mask, MaskOperation, maskType} from "../../types/generic/mask/mask.ts";
import {VisibilityLayerFn} from "../visibility/index.ts";
import {TokenVision} from "./token/token-vision.ts";
import {generateTokenVisionID} from "./token/token-vision-i-d.ts";

export type Asset = {
  ownerIDs: UserID[];
  initialTokenId: TokenID;
  tokens: Token[];
  sheets: Record<SheetID, Sheet>;
  selectionMask: Mask;
  visibilityLayer: VisibilityLayer;
};

export type AssetOperation =
  | {type: "update-owner-i-ds", operations: SetOperation<UserID>[]}
  | {type: "update-initial-token-id", operations: ValueOperation<TokenID, ConstantOperation>[]}
  | {type: "update-tokens", operations: ListOperation<Token, TokenOperation>[]}
  | {type: "update-sheets", operations: MapOperation<SheetID, Sheet, SheetOperation>[]}
  | {type: "update-visibility-layer", operations: VisibilityLayerOperation[]}
  | {type: "update-selection-mask", operations: MaskOperation[]}
  ;
export const AssetOperationFn = {
  updateInitialTokenId: (operations: ValueOperation<TokenID, ConstantOperation>[]): AssetOperation[] => [{type: "update-initial-token-id", operations}],
  updateTokens: (operations: ListOperation<Token, TokenOperation>[]): AssetOperation[] => [{type: "update-tokens", operations}]
} as const;

export const assetType: Type<Asset, AssetOperation> = new ObjectType({
  ownerIDs: new SetType<UserID>(),
  initialTokenId: new ValueType(new ConstantType<TokenID>()),
  tokens: new ListType<Token, TokenOperation>(tokenType),
  sheets: new MapType<SheetID, Sheet, SheetOperation>(sheetType),
  visibilityLayer: visibilityLayerType,
  selectionMask: maskType
}, (value: any) => {
  if (value.visibilityMask) {
    value.accessMask = value.visibilityMask;
    delete value["visibilityMask"];
  }
  if (typeof value.tokens === "object") {
    value.tokens = Object.keys(value.tokens).map(tokenID => ({
      tokenID: tokenID,
      ...value.tokens[tokenID]
    }));
  }
  if (value.selectionMask === undefined) {
    value.selectionMask = 0;
  }
  if (value.accessMask) {
    value.tokens = value.tokens.map((token: Token): Token => ({...token, vision: [{
      tokenVisionID: generateTokenVisionID(),
      name: "Vision",
      offset: [0, 0],
      accessMask: value.accessMask,
      grayscale: false
    } satisfies TokenVision]}));
    delete value["accessMask"];
  }
  return value;
});

declare module "../../qlab/resource/resource.ts" {
  export interface ResourceTypes {
    "asset": typeof assetType
  }
}

export const AssetFn = {
  defaultAsset: (name: string) => {
    const initialTokenID = generateTokenID();
    return {
      ownerIDs: [],
      initialTokenId: initialTokenID,
      tokens: [{
        version: 1,
        tokenID: initialTokenID,
        name,
        icon: undefined,
        sheetId: undefined,
        origin: [32, 32],
        pivot: [32, 32],
        size: [64, 64],
        mountable: true,
        attachable: true,
        sheetPolicy: "copy",
        children: [],
        vision: [{
          tokenVisionID: generateTokenVisionID(),
          name: "Vision",
          offset: [0, 0],
          accessMask: 1,
          grayscale: false
        }],
        light: {intensity: 1, color: Color.WHITE}
      }],
      sheets: {},
      selectionMask: 1,
      visibilityLayer: VisibilityLayerFn.DEFAULT
    } satisfies Asset
  },
  copyAsset(asset: Asset): Asset {
    return ({
      ...asset,
      tokens: asset.tokens.map(Token.copyToken)
    });
  }
}