import {MutableRef} from "common/ref";
import {LightShapeSpotlight, LightShapeSpotlightOperation, LightShapeSpotlightRef} from "common/legends/node/light/light-shape-spotlight.ts";
import {InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {useMemo} from "react";
import {ColorField} from "#lib/components/input/color-field.tsx";

export function LightShapeSpotlightFields({valueRef}: {
  valueRef: MutableRef<LightShapeSpotlight, LightShapeSpotlightOperation[]>
}) {
  const {colorRef, intensityRef, angleRef, falloffAngleRef, falloffRef, falloffStrengthRef, radiusRef} = useMemo(() => LightShapeSpotlightRef(valueRef), [valueRef]);

  return <>
    <InputGroup>
      <InputGroupLabel>Color</InputGroupLabel>
      <ColorField className="flex-1" value={colorRef} />
    </InputGroup>
    <div className="flex flex-row gap-0.5">
      <InputGroup className="flex-1">
        <InputGroupLabel>Intensity</InputGroupLabel>
        <InputNumber value={intensityRef} />
      </InputGroup>
      <InputGroup className="flex-1">
        <InputGroupLabel>Falloff Strength</InputGroupLabel>
        <InputNumber value={falloffStrengthRef} />
      </InputGroup>
    </div>

    <div className="flex flex-row gap-0.5">
      <InputGroup className="flex-1">
        <InputGroupLabel>Radius</InputGroupLabel>
        <InputNumber value={radiusRef} />
      </InputGroup>
      <InputGroup className="flex-1">
        <InputGroupLabel>Falloff Radius</InputGroupLabel>
        <InputNumber value={falloffRef} />
      </InputGroup>
    </div>

    <div className="flex flex-row gap-0.5">
      <InputGroup className="flex-1">
        <InputGroupLabel>Angle</InputGroupLabel>
        <InputNumber value={angleRef} />
      </InputGroup>
      <InputGroup className="flex-1">
        <InputGroupLabel>Falloff Angle</InputGroupLabel>
        <InputNumber value={falloffAngleRef} />
      </InputGroup>
    </div>
  </>
}