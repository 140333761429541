import {Color, ListOperation} from "common/types/index.ts";
import React, {useCallback, useState} from "react";
import {generateDnd5eModifierID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {DiceExpression} from "common/dice/index.ts";
import {Button} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import {Dnd5eModifier, Dnd5eModifierOperation, Dnd5eModifierTypes, getModifierID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";
import {Menu} from "@headlessui/react";
import {usePopper} from "react-popper";
import {createPortal} from "react-dom";
import {Popper} from "#lib/components/popper/popper.tsx";
import {Dnd5eAbilityCheckModifierView} from "./dnd-5e-ability-check-modifier-view.tsx";
import {Dnd5eSavingThrowModifierView} from "./dnd-5e-saving-throw-modifiers-view.tsx";
import {Dnd5eConditionImmunityModifierView} from "./dnd-5e-condition-immunity-modifier-view.tsx";
import {Dnd5eDamageImmunityModifierView} from "./dnd-5e-damage-immunity-modifier-view.tsx";
import {Dnd5eDamageResistanceModifierView} from "./dnd-5e-damage-resistance-modifier-view.tsx";
import {Dnd5eDamageVulnerabilityModifierView} from "./dnd-5e-damage-vulnerability-modifier-view.tsx";
import {Dnd5eDamageReductionModifierView} from "./dnd-5e-damage-reduction-modifier-view.tsx";
import {Dnd5eDamageThresholdModifierView} from "./dnd-5e-damage-threshold-modifier-view.tsx";
import {Dnd5eTraitModifierView} from "./dnd-5e-trait-modifier-view.tsx";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {copyDnd5eModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/copy-dnd-5e-modifier.ts";
import {Dnd5eArmorClassFormulaModifierView} from "./dnd-5e-armor-class-formula-modifier-view.tsx";
import {MathExpressionFn} from "common/math/index.ts";
import {InputStatusIndicatorModifier} from "../../../../../common/character/status-indicator/input-status-indicator-modifier.tsx";
import {Dnd5eMaxHPModifierView} from "./dnd-5e-max-h-p-modifier-view.tsx";
import {usePortal} from "#lib/container/react/external-window/external-portal.tsx";
import {MutableRef} from "common/ref";
import {Dnd5eArmorClassModifierView} from "./dnd-5e-armor-class-modifier-view.tsx";
import {
  Dnd5eActionTemplateSegmentAttackRollHitModifierListItem
} from "../dnd-5e-action/action-template-segment/attack-roll/dnd-5e-action-template-segment-attack-roll-hit-modifier-view.tsx";
import {useTypedRef} from "../../../../../common/use-typed-ref.ts";
import {
  Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierListItem
} from "../dnd-5e-action/action-template-segment/saving-throw/dnd-5e-action-template-segment-saving-throw-difficulty-class-modifier-view.tsx";
import {Dnd5eVariableModifierListItem} from "../dnd-5e-action/action-template-segment/dnd-5e-variable-modifier-view.tsx";
import {
  Dnd5eActionTemplateSegmentRollExpressionModifierListItem
} from "../dnd-5e-action/action-template-segment/roll/dnd-5e-action-template-segment-roll-expression-modifier-view.tsx";

export type Dnd5eModifierViewProps = InputListItemProps<Dnd5eModifier, Dnd5eModifierOperation>;
export function Dnd5eModifierView({item, remove, duplicate}: Dnd5eModifierViewProps) {
  const [dragHandlerRef, dragRefPreview] = useDragListItem<Dnd5eModifier>("legends/modifier", item, remove);
  const [type, typedValue] = useTypedRef<Dnd5eModifierTypes>(item);

  if (type === "ability-check") {
    return <Dnd5eAbilityCheckModifierView item={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  } else if (type === "action::attack-roll::hit-modifier") {
    return <Dnd5eActionTemplateSegmentAttackRollHitModifierListItem item={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} duplicate={duplicate} />;
  } else if (type === "action::saving-throw::difficulty-class") {
    return <Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierListItem item={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} duplicate={duplicate} />;
  } else if (type === "action::roll::expression") {
    return <Dnd5eActionTemplateSegmentRollExpressionModifierListItem item={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} duplicate={duplicate} />;
  } else if (type === "saving-throw") {
    return <Dnd5eSavingThrowModifierView value={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  } else if (type === "condition-immunity") {
    return <Dnd5eConditionImmunityModifierView value={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  } else if (type === "damage-immunity") {
    return <Dnd5eDamageImmunityModifierView value={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "damage-resistance") {
    return <Dnd5eDamageResistanceModifierView value={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "damage-vulnerability") {
    return <Dnd5eDamageVulnerabilityModifierView value={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "damage-reduction") {
    return <Dnd5eDamageReductionModifierView value={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "damage-threshold") {
    return <Dnd5eDamageThresholdModifierView value={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "trait") {
    return <Dnd5eTraitModifierView value={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "armor-class-formula") {
    return <Dnd5eArmorClassFormulaModifierView value={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "armor-class") {
    return <Dnd5eArmorClassModifierView value={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "variable") {
    return <Dnd5eVariableModifierListItem item={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} duplicate={duplicate} />
  } else if (type === "status-indicator") {
    return <InputStatusIndicatorModifier value={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "max-h-p") {
    return <Dnd5eMaxHPModifierView value={typedValue} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  }
  return <></>;
}

export type Dnd5eModifiersViewProps = {
  value: MutableRef<Dnd5eModifier[], ListOperation<Dnd5eModifier, Dnd5eModifierOperation>[]>;
  label?: string;
  readOnly?: boolean;
};
export function Dnd5eModifiersView({value, label = "Modifiers", readOnly}: Dnd5eModifiersViewProps) {
  const addModifier = useCallback((modifier: Dnd5eModifier) => value.apply(prev => ListOperation.insert(prev.length, modifier)), [value.apply]);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end"
  });

  return <div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5">
      <SectionHeader className="flex-1">{label}</SectionHeader>
      {!readOnly && <Menu as="div" className="flex flex-row" ref={ref => setReferenceElement(ref)}>
          <Menu.Button as={Button} className="flex-1"><FaPlus/> Add Modifier</Menu.Button>
        {createPortal(<Menu.Items as={Popper} ref={ref => setPopperElement(ref)}
                                  style={styles.popper} {...attributes.popper}>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "ability-check",
            data: {
              modifierID: generateDnd5eModifierID(),
              abilityChecks: [],
              expression: DiceExpression.parse("0"),
              proficiency: "untrained",
              hasAdvantage: false,
              hasDisadvantage: false
            }
          })}>Ability Check</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "action::attack-roll::hit-modifier",
            data: {
              modifierID: generateDnd5eModifierID(),
              condition: undefined,
              expression: DiceExpression.parse("0")
            }
          })}>Attack Roll</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "saving-throw",
            data: {
              modifierID: generateDnd5eModifierID(),
              savingThrows: [],
              expression: DiceExpression.parse("0"),
              proficiency: "untrained",
              hasAdvantage: false,
              hasDisadvantage: false
            }
          })}>Saving Throw</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "action::saving-throw::difficulty-class",
            data: {
              modifierID: generateDnd5eModifierID(),
              condition: undefined,
              expression: DiceExpression.parse("0")
            }
          })}>Difficulty Class</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "action::roll::expression",
            data: {
              modifierID: generateDnd5eModifierID(),
              condition: undefined,
              rollType: undefined,
              expression: DiceExpression.parse("0")
            }
          })}>Roll</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "condition-immunity",
            data: {
              modifierID: generateDnd5eModifierID(),
              conditions: []
            }
          })}>Condition Immunity</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "damage-immunity",
            data: {
              modifierID: generateDnd5eModifierID(),
              damageTypes: []
            }
          })}>Damage Immunity</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "damage-vulnerability",
            data: {
              modifierID: generateDnd5eModifierID(),
              damageTypes: []
            }
          })}>Damage Vulnerability</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "damage-resistance",
            data: {
              modifierID: generateDnd5eModifierID(),
              damageTypes: []
            }
          })}>Damage Resistance</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "damage-reduction",
            data: {
              modifierID: generateDnd5eModifierID(),
              damageTypes: [],
              amount: 5
            }
          })}>Damage Reduction</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "damage-threshold",
            data: {
              modifierID: generateDnd5eModifierID(),
              amount: 10
            }
          })}>Damage Threshold</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "trait",
            data: {
              modifierID: generateDnd5eModifierID(),
              trait: undefined
            }
          })}>Trait</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "armor-class-formula",
            data: {
              modifierID: generateDnd5eModifierID(),
              expression: MathExpressionFn.assertMathExpression("10+{DEX}"),
              shieldBonus: true
            }
          })}>Armor Class Formula</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "armor-class",
            data: {
              modifierID: generateDnd5eModifierID(),
              modifierType: "MISC",
              expression: MathExpressionFn.assertMathExpression("1")
            }
          })}>Armor Class</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "variable",
            data: {
              modifierID: generateDnd5eModifierID(),
              name: "Variable",
              expression: MathExpressionFn.assertMathExpression("0")
            }
          })}>Variable</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "status-indicator",
            data: {
              modifierID: generateDnd5eModifierID(),
              color: Color.WHITE,
              file: undefined
            }
          })}>Status Indicator</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "max-h-p",
            data: {
              modifierID: generateDnd5eModifierID(),
              expression: MathExpressionFn.assertMathExpression("5")
            }
          })}>Max HP</Menu.Item>
        </Menu.Items>, usePortal())}
      </Menu>}
    </div>

    <InputList<Dnd5eModifier, Dnd5eModifierOperation>
      accept="legends/modifier"
      items={value}
      itemKey={getModifierID}
      copy={copyDnd5eModifier}
      ListItem={Dnd5eModifierView} />
  </div>;
}
