import {
  BooleanOperation,
  booleanType,
  FileReference,
  FileReferenceOperation,
  fileReferenceType,
  NumberOperation,
  numberType,
  ObjectType,
  Size,
  SizeOperation,
  sizeType,
  Type
} from "../../types/index.ts";
import {MutableRef} from "#common/ref";
import {LocalNode, LocalNodeOperation, LocalNodeSignals, localNodeTypePropTypes, localNodeUpdater} from "./local-node.ts";

export type VideoNode = LocalNode & {
  file: FileReference;
  normal: FileReference;
  opacity: number;
  size: Size;
  mountable: boolean;
  attachable: boolean;
};
export type VideoNodeOperation =
  | LocalNodeOperation
  | {type: "update-file", operations: FileReferenceOperation[]}
  | {type: "update-normal", operations: FileReferenceOperation[]}
  | {type: "update-opacity", operations: NumberOperation[]}
  | {type: "update-size", operations: SizeOperation[]}
  | {type: "update-mountable", operations: BooleanOperation[]}
  | {type: "update-attachable", operations: BooleanOperation[]}
  ;
export const videoNodeType: Type<VideoNode, VideoNodeOperation> = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  file: fileReferenceType,
  normal: fileReferenceType,
  opacity: numberType,
  size: sizeType,
  mountable: booleanType,
  attachable: booleanType
}), (value) => {
  value = localNodeUpdater(value);
  return value;
});

export function VideoNodeSignals(value: MutableRef<VideoNode, VideoNodeOperation[]>) {
  return {
    ...LocalNodeSignals(value),
    fileRef: value.map<FileReference, FileReferenceOperation[]>(value => value.file, (_, operations) => [{type: "update-file", operations}]),
    normalRef: value.map<FileReference, FileReferenceOperation[]>(value => value.normal, (_, operations) => [{type: "update-normal", operations}]),
    sizeRef: value.map<Size, SizeOperation[]>(value => value.size, (_, operations) => [{type: "update-size", operations}]),
    opacityRef: value.map<number, NumberOperation[]>(value => value.opacity, (_, operations) => [{type: "update-opacity", operations}]),
    mountableRef: value.map<boolean, BooleanOperation[]>(value => value.mountable, (_, operations) => [{type: "update-mountable", operations}]),
    attachableRef: value.map<boolean, BooleanOperation[]>(value => value.attachable, (_, operations) => [{type: "update-attachable", operations}])
  };
}