import {Dnd5eResource, Dnd5eResourceOperation, Dnd5eResourceSignals, getResourceID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource.ts";
import {ListOperation} from "common/types/index.ts";
import {Button, ButtonBar, IconButton, InputGroup, InputGroupLabel, InputNumber, Select} from "#lib/components/index.ts";
import {FaPlus, FaTrash} from "react-icons/fa";
import React, {useCallback, useMemo} from "react";
import {generateDnd5eResourceID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource-id.ts";
import {InputString} from "#lib/components/input/input-string.tsx";
import {Dnd5eResourceType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource-type.ts";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {twMerge} from "tailwind-merge";
import {MathExpressionFn} from "common/math/index.ts";
import {InputMathExpression} from "#lib/components/input/input-math-expression.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {copyDnd5eResource} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/copy-dnd-5e-resource.ts";
import {MutableRef} from "common/ref";


type Dnd5eResourceListItemProps = InputListItemProps<Dnd5eResource, Dnd5eResourceOperation>;
function Dnd5eResourceListItem({item, remove}: Dnd5eResourceListItemProps) {
  const {name, current, max, resourceType} = useMemo(() => Dnd5eResourceSignals(item), [item]);
  const resourceTypeValue = useRefValue(resourceType);

  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/resource", item, remove);
  return <ButtonBar role="list-item" ref={dragRefPreview} className="rounded-none">
    <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
    <InputGroup className="flex-1">
      <InputString type="text" value={name}/>
    </InputGroup>

    <InputGroup className="basis-20">
      <InputNumber type="number" value={current}/>
    </InputGroup>
    <InputGroup><InputGroupLabel>/</InputGroupLabel></InputGroup>
    <InputGroup className="basis-40">
      <InputMathExpression value={max} />
    </InputGroup>
    <InputGroup className="px-0">
      <Select aria-placeholder="per ..." value={resourceTypeValue === undefined ? "" : resourceTypeValue} onChange={ev => {
        const value = ev.target.value as Dnd5eResourceType | "";
        resourceType.apply(prevValue => [{type: "set", prevValue, nextValue: value === "" ? undefined : value}]);
      }}>
        <option value="">per ...</option>
        <option value="short rest">per short rest</option>
        <option value="long rest">per long rest</option>
      </Select>
    </InputGroup>
    <IconButton variant="destructive" title="Remove Resource" onClick={remove}><FaTrash/></IconButton>
  </ButtonBar>;
}

export type Dnd5eResourcesViewProps = {
  value: MutableRef<Dnd5eResource[], ListOperation<Dnd5eResource, Dnd5eResourceOperation>[]>;
  className?: string;
};

export function Dnd5eResourcesList({value, className}: Dnd5eResourcesViewProps) {
  const addResource = useCallback(() => value.apply(prev => ListOperation.insert(prev.length, {
    resourceID: generateDnd5eResourceID(),
    name: "Resource",
    current: 5,
    max: MathExpressionFn.assertMathExpression("5")
  })), [value.apply]);

  return (<div className={twMerge(
    "flex flex-col gap-1",
    className
  )}>
    <div className="flex flex-row gap-0.5">
      <SectionHeader className="flex-1">Resources</SectionHeader>
      <Button onClick={addResource}><FaPlus/> Add Resource</Button>
    </div>

    <InputList<Dnd5eResource, Dnd5eResourceOperation>
      accept="legends/resource"
      items={value}
      itemKey={getResourceID}
      copy={copyDnd5eResource}
      ListItem={Dnd5eResourceListItem} />

  </div>);
}
