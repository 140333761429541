import {InputList, InputListItemProps} from "#lib/components/list/input-list.tsx";
import {ConnectDragPreview, ConnectDragSource} from "react-dnd";
import React, {useMemo} from "react";
import {Button, ButtonBar, ExpandableLabel, IconButton, Spacer, useToggle} from "#lib/components/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {FaCopy, FaPlus, FaTrash} from "react-icons/fa";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {Dnd5eActionTemplateSegmentRollExpressionListItem} from "./dnd-5e-action-template-segment-roll-expression-list-item.tsx";
import {ListOperation} from "common/types/generic/index.ts";
import {DiceExpression} from "common/dice/dice-expression.ts";
import {generateRollRequestID} from "common/qlab/message/index.ts";
import {Dnd5eActionTemplateSegmentRollModifiersView} from "./dnd-5e-action-template-segment-roll-modifiers-view.tsx";
import {Dnd5eActionTemplateAddRollModifierButton} from "./dnd-5e-action-template-add-roll-modifier-button.tsx";
import {
  Dnd5eActionTemplateSegmentRoll,
  Dnd5eActionTemplateSegmentRollOperation,
  Dnd5eActionTemplateSegmentRollSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/damage/dnd-5e-action-template-segment-roll.ts";
import {
  Dnd5eActionTemplateSegmentRollExpression,
  Dnd5eActionTemplateSegmentRollExpressionOperation
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/damage/dnd-5e-action-template-segment-roll-expression.ts";

export function Dnd5eActionTemplateSegmentRollView({item, remove, duplicate, dragRefPreview, dragHandlerRef}: InputListItemProps<Dnd5eActionTemplateSegmentRoll, Dnd5eActionTemplateSegmentRollOperation> & {
  dragHandlerRef: ConnectDragSource | ConnectDragPreview,
  dragRefPreview: ConnectDragSource | ConnectDragPreview
}) {
  const {expressionsRef, modifiersRef} = useMemo(() => Dnd5eActionTemplateSegmentRollSignals(item), [item])
  const [expanded, toggleExpanded] = useToggle(false);
  const [modifiersExpanded, toggleModifiersExpanded, setModifiersExpanded] = useToggle(false);

  return <div role="list-item" className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>

      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 cursor-pointer" onClick={toggleExpanded}>Roll</SectionHeader>
      </ExpandableLabel>

      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Effect" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>
    {expanded && <Fieldset>
      <ButtonBar>
        <Spacer/>
        <Button onClick={() => expressionsRef.apply(prev => ListOperation.insert(prev.length, {rollID: generateRollRequestID(), rollType: undefined, expression: DiceExpression.parse("0")}))}>
          <FaPlus/> Add Roll
        </Button>
      </ButtonBar>
      <InputList<Dnd5eActionTemplateSegmentRollExpression, Dnd5eActionTemplateSegmentRollExpressionOperation>
          accept={"legends/action-template-segment-roll-expression"}
          items={expressionsRef}
          itemKey={(_, index) => index}
          copy={c => c}
          ListItem={Dnd5eActionTemplateSegmentRollExpressionListItem} />
      <div className="flex flex-col">
          <ExpandableLabel expanded={modifiersExpanded} toggleExpand={toggleModifiersExpanded}>
            <SectionHeader className="flex-1 cursor-pointer" onClick={toggleModifiersExpanded}>Modifiers</SectionHeader>
            <Dnd5eActionTemplateAddRollModifierButton valueRef={modifiersRef} onAddedModifier={() => setModifiersExpanded(true)}/>
          </ExpandableLabel>
        {modifiersExpanded && <Fieldset>
          <Dnd5eActionTemplateSegmentRollModifiersView valueRef={modifiersRef}/>
        </Fieldset>}
      </div>
    </Fieldset>}
  </div>;
}
