import {RollRequests, RollResults} from "common/qlab/message/index.ts";
import {Optional} from "common/types/generic/index.ts";
import {ExpandableLabel, useToggle} from "#lib/components/index.ts";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {Dnd5eActionSegment} from "common/qlab/message/dnd-5e/dnd-5e-action-message.ts";
import {Dnd5eActionSegmentView} from "./dnd-5e-action-segment-view.tsx";

export function Dnd5eActionSectionView({segment, rollRequests, rollResults}: {segment: {
  name: string,
  segments: Dnd5eActionSegment[]
}, rollRequests: RollRequests, rollResults: Optional<RollResults>}) {
  const [expanded, toggleExpanded] = useToggle(true);

  return <>
    <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded} className="w-full">
      <SectionHeader className="w-full">{segment.name}</SectionHeader>
    </ExpandableLabel>
    {expanded && <>
      {segment.segments.map((segment, index) => <Dnd5eActionSegmentView key={index} segment={segment} rollRequests={rollRequests} rollResults={rollResults} />)}
    </>}
  </>
}
