import React, {useMemo} from "react";
import {Transform} from "common/types/index.ts";
import {ElementRenderPass} from "./element-render-pass.tsx";
import {ModelProvider, useModel} from "../../context/pvm-context.ts";
import {OpacityProvider, useOpacity} from "../../context/opacity-context.ts";
import {VideoView} from "./video-view.tsx";
import {VideoNode} from "common/legends/node/video-node.ts";

export function VideoElementRenderPass({value}: {
  value: VideoNode;
}) {
  const model = useModel();
  const valueModel = useMemo(() => Transform.divide(value.transform, model), [value.transform, model]);
  const valueOpacity = value.opacity * useOpacity();

  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        <VideoView file={value.file} normal={value.normal} size={value.size} origin={value.origin} pivot={value.pivot} opacity={valueOpacity} />
        {[...value.children].reverse().map((element) => <ElementRenderPass key={element.data.id} value={element} />)}
      </ModelProvider>
    </OpacityProvider>
  </binder>);
}