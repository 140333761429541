import {InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {Point, PointOperation, PointSignals} from "common/types/index.ts";
import {useMemo} from "react";
import {MutableRef} from "common/ref";

export function PivotField({valueRef}: {
  valueRef: MutableRef<Point, PointOperation[]>;
}) {
  const {x, y} = useMemo(() => PointSignals(valueRef), [valueRef]);

  return (
    <div className="flex flex-row">
      <InputGroup>
        <InputGroupLabel>Pivot</InputGroupLabel>
      </InputGroup>
      <InputGroup className="flex-1">
        <InputGroupLabel>X</InputGroupLabel>
        <InputNumber value={x} />
      </InputGroup>
      <InputGroup className="flex-1">
        <InputGroupLabel>Y</InputGroupLabel>
        <InputNumber value={y} />
      </InputGroup>
    </div>
  );
}