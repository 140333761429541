import {
  BooleanOperation,
  booleanType,
  FileReference,
  FileReferenceOperation,
  fileReferenceType,
  NumberOperation,
  numberType,
  ObjectType,
  Size,
  SizeOperation,
  sizeType,
  Type,
  ValueOperation,
  ValueType
} from "../../types/index.ts";
import {Nullable, NullableType} from "#common/types/generic/nullable/index.ts";
import {MutableRef} from "#common/ref";
import {LocalNode, LocalNodeOperation, LocalNodeSignals, localNodeTypePropTypes, localNodeUpdater} from "./local-node.ts";

export type ImageNode = LocalNode & {
  file: FileReference;
  normal: FileReference;
  opacity: number;
  size: Size;
  mountable: boolean;
  attachable: boolean;
  repeatX: Nullable<number>;
  repeatY: Nullable<number>;
};
export type ImageNodeOperation =
  | LocalNodeOperation
  | {type: "update-file", operations: FileReferenceOperation[]}
  | {type: "update-normal", operations: FileReferenceOperation[]}
  | {type: "update-opacity", operations: NumberOperation[]}
  | {type: "update-size", operations: SizeOperation[]}
  | {type: "update-mountable", operations: BooleanOperation[]}
  | {type: "update-attachable", operations: BooleanOperation[]}
  | {type: "update-repeat-x", operations: ValueOperation<number | null, NumberOperation>[]}
  | {type: "update-repeat-y", operations: ValueOperation<number | null, NumberOperation>[]}
  ;
export const imageNodeType: Type<ImageNode, ImageNodeOperation> = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  file: fileReferenceType,
  normal: fileReferenceType,
  opacity: numberType,
  size: sizeType,
  mountable: booleanType,
  attachable: booleanType,
  repeatX: new ValueType(new NullableType(numberType)),
  repeatY: new ValueType(new NullableType(numberType))
}), (value) => {
  value = localNodeUpdater(value);
  if (!value.conditions) value.conditions = [];
  if (value.selectable) {
    value.selectionMask = value.selectable ? 1 : 0;
    delete value["selectable"];
  }
  if (!value.selectionMask) value.selectionMask = 0;
  if (value["repeatX"] === undefined) value.repeatX = 1;
  if (value["repeatY"] === undefined) value.repeatY = 1;
  if (value["mountable"] === undefined || value["mountable"] === null) value.mountable = false;
  if (value["asset"] !== undefined) {
    value["file"] = value["asset"];
    delete value["asset"]
  }
  if (value["attachable"] === undefined) value["attachable"] = value["mountable"];
  return value;
});

export function ImageNodeSignals(value: MutableRef<ImageNode, ImageNodeOperation[]>) {
  return {
    ...LocalNodeSignals(value),
    fileRef: value.map<FileReference, FileReferenceOperation[]>(value => value.file, (_, operations) => [{type: "update-file", operations}]),
    normalRef: value.map<FileReference, FileReferenceOperation[]>(value => value.normal, (_, operations) => [{type: "update-normal", operations}]),
    sizeRef: value.map<Size, SizeOperation[]>(value => value.size, (_, operations) => [{type: "update-size", operations}]),
    opacityRef: value.map<number, NumberOperation[]>(value => value.opacity, (_, operations) => [{type: "update-opacity", operations}]),
    mountableRef: value.map<boolean, BooleanOperation[]>(value => value.mountable, (_, operations) => [{type: "update-mountable", operations}]),
    attachableRef: value.map<boolean, BooleanOperation[]>(value => value.attachable, (_, operations) => [{type: "update-attachable", operations}]),
    repeatXRef: value.map<Nullable<number>, ValueOperation<Nullable<number>, NumberOperation>[]>(value => value.repeatX, (_, operations) => [{type: "update-repeat-x", operations}]),
    repeatYRef: value.map<Nullable<number>, ValueOperation<Nullable<number>, NumberOperation>[]>(value => value.repeatY, (_, operations) => [{type: "update-repeat-y", operations}]),
  };
}