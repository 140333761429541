import {KeyboardEvent, MouseEvent, RefObject, useCallback, useEffect} from "react";
import {HSLA, Optional, Point, SetOperation, Transform, TransformOperation, TreeOperation} from "common/types/index.ts";
import {Grid, Node, NodeId, NodeOperation} from "common/legends/index.ts";
import {useInstance} from "#lib/qlab/index.ts";
import {filter, map, subscribe} from "common/observable";
import {Measurement, MeasurementOperation} from "common/legends/measurement/index.ts";
import {pipe} from "common/pipe";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useSample} from "#lib/components/index.ts";
import {ContextMenu, ContextMenuOperation} from "./context-menu/context-menu.ts";
import {MutableRef, Ref} from "common/ref";
import {Tool, ToolOperation} from "../../legends/common/tool/tool.ts";
import {usePanToolHandlers} from "../../legends/viewport/tool/use-pan-tool-handlers.ts";
import {useSelectionToolHandlers} from "../../legends/viewport/tool/use-selection-tool-handlers.ts";
import {useMeasurementToolHandlers} from "../../legends/viewport/tool/use-measurement-tool-handlers.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {AssetTokenSelectionRef, NodeSelectionRef, SceneSelectionRef} from "../../legends/panel/nav/editor/state/selection-ref.ts";
import {useWallToolHandlers} from "../../legends/panel/nav/common/tool/wall-editor/use-wall-tool-handlers.ts";
import {useAreaToolHandlers} from "../../legends/panel/nav/common/tool/area-editor/use-area-tool-handlers.ts";
import {useStoreID} from "./model/store-context.tsx";

export type ToolHandlers = {
  onDrop: (screenPos: Point, fn: (worldPos: Point) => Node) => void,
  onWheel: (ev: WheelEvent) => void,
  onTouchStart: (ev: TouchEvent) => void,
  onTouchMove: (ev: TouchEvent) => void,
  onTouchEnd: (ev: TouchEvent) => void,

  onMouseUp?: (ev: MouseEvent<HTMLCanvasElement>) => void,
  onMouseMove?: (ev: MouseEvent<HTMLCanvasElement>) => void,
  onMouseDown?: (ev: MouseEvent<HTMLCanvasElement>) => void,
  onMouseLeave?: (ev: MouseEvent<HTMLCanvasElement>) => void,
  onMouseEnter?: (ev: MouseEvent<HTMLCanvasElement>) => void,
  onContextMenu?: (ev: MouseEvent<HTMLCanvasElement>) => void,
  onKeyDown?: (ev: KeyboardEvent) => void,
  onKeyUp: (ev: KeyboardEvent) => boolean
};

export function useToolHandlers(
  canvasRef: RefObject<HTMLCanvasElement>,
  rootSelectionRef: SceneSelectionRef | AssetTokenSelectionRef,
  view: MutableRef<Transform, TransformOperation[]>,
  measurement: MutableRef<Measurement, MeasurementOperation[]>,
  grid: Grid,
  nodes: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>,
  selectedNodeIds: MutableRef<NodeSelectionRef[], SetOperation<NodeSelectionRef>[]>,
  activeNodeIdRef: Ref<Optional<NodeId>>,
  toolRef: MutableRef<Tool, ToolOperation[]>,
  ping: (p: Point, focus: boolean, color: HSLA) => void,
  color: HSLA,
  contextMenu: MutableRef<ContextMenu, ContextMenuOperation[]>,
  isAccessible: (node: Node) => boolean,
  isVisible: (node: Node) => boolean
): ToolHandlers {
  const panToolHandlers = usePanToolHandlers(canvasRef, view, grid, nodes, activeNodeIdRef, selectedNodeIds, ping, color, isAccessible, isVisible);
  const selectionToolHandlers = useSelectionToolHandlers(canvasRef, rootSelectionRef, view, measurement, grid, nodes, activeNodeIdRef, selectedNodeIds, ping, color, contextMenu, isAccessible, isVisible);
  const measurementToolHandlers = useMeasurementToolHandlers(canvasRef, rootSelectionRef, toolRef, view, measurement, grid, nodes, selectedNodeIds, activeNodeIdRef, ping, color, contextMenu, isAccessible, isVisible);
  const wallToolHandlers = useWallToolHandlers(toolRef, canvasRef, view, grid, nodes);
  const areaToolHandlers = useAreaToolHandlers(toolRef, canvasRef, view, grid, nodes);

  // Broadcast Measurement Changes
  const gameID = useStoreID();
  const userID = useUserID()!;
  const instance = useInstance();
  const broadcastMeasurement = useSample(useCallback((measurement: Measurement) => instance.stream(gameID, {
    type: "measurement",
    userID: userID,
    data: measurement
  }), [instance, userID]), 120);

  useEffect(() => pipe(
    measurement.observe,
    filter(measurement => measurement !== undefined),
    map(measurement => measurement as Measurement),
    subscribe(measurement => broadcastMeasurement(measurement))
  ), [measurement, broadcastMeasurement]);

  const toolModeValue = useRefValue(toolRef);
  if (toolModeValue.type === "selection") {
    return selectionToolHandlers;
  } else if (toolModeValue.type === "measurement") {
    return measurementToolHandlers;
  } else if (toolModeValue.type === "pan") {
    return panToolHandlers;
  } else if (toolModeValue.type === "wall") {
    return wallToolHandlers;
  } else if (toolModeValue.type === "area") {
    return areaToolHandlers;
  } else {
    return selectionToolHandlers;
  }
}
