import {Apply, MapSignals} from "common/types/index.ts";
import {useMemo} from "react";
import {GameSignals} from "common/legends/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {TurnTrackerFn, TurnTrackerSignals} from "common/legends/game/turn-tracker/index.ts";
import {Button, ButtonBar, IconButton} from "#lib/components/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {FaDiceD20} from "react-icons/fa6";
import {PlayerTurnTracker, PlayerTurnTrackerOperation} from "./player-turn-tracker.ts";
import {useGame} from "../../../../routes/game/index.ts";
import {useIsCurrentTurn} from "../../../common/turn-tracker/use-is-current-turn.ts";
import {useEndTurn} from "../../../common/turn-tracker/use-end-turn.ts";
import {useSelectedNodeID, useSelectedSheet} from "../../sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {useRollAbilityCheck} from "../../sheet/editor/dnd-5e-character/dnd-5e-action/use-roll-ability-check.ts";
import {PlayerTurnView} from "./player-turn-view.tsx";
import {useRefValue} from "#lib/signal/index.ts";


export type PlayerTurnTrackerViewProps = {
  value: PlayerTurnTracker;
  apply: Apply<PlayerTurnTracker, PlayerTurnTrackerOperation[]>;
};
export function PlayerTurnTrackerView(_: PlayerTurnTrackerViewProps) {
  const game = useGame();
  const {turnTracker} = useMemo(() => GameSignals(game), [game]);
  const {turns} = useMemo(() => TurnTrackerSignals(turnTracker), [turnTracker]);
  const turnSignals = useRefValue(useMemo(() => MapSignals.expand(turns), [turns]));
  const resolvedTurnTracker = useRefValue(turnTracker);

  const isCurrentTurn = useIsCurrentTurn();

  const endTurn = useEndTurn(turnTracker);

  const selectedCharacter = useSelectedSheet();
  const isCharacterSelected = useObservable(pipe(selectedCharacter.observe, map(c => c !== undefined), distinct()), false, [selectedCharacter.observe]);
  const rollAbility = useRollAbilityCheck(useSelectedNodeID(), selectedCharacter);

  const orderedTurnIDs = TurnTrackerFn.orderedTurnIDs(resolvedTurnTracker.turns);

  return <div className="flex flex-col flex-1">
    <ButtonBar className="m-2 rounded-lg overflow-hidden backdrop-blur-sm pointer-events-auto">
      {resolvedTurnTracker.currentTurnID !== undefined && <IconButton size="small" disabled={!isCharacterSelected} onClick={(ev) => rollAbility("initiative", ev.shiftKey, ev.ctrlKey)} title="Roll Initiative"><FaDiceD20 /></IconButton>}
      {resolvedTurnTracker.round === 0 && <span className="flex-1 flex items-center justify-center font-bold shrink whitespace-nowrap overflow-hidden"></span>}
      {resolvedTurnTracker.round !== 0 && <span className="flex-1 flex items-center justify-center font-bold shrink whitespace-nowrap overflow-hidden">Round {resolvedTurnTracker.round}</span>}
      <Button disabled={!isCurrentTurn} variant={isCurrentTurn ? "primary" : "tertiary"} onClick={endTurn}>End Turn</Button>
    </ButtonBar>

    <div className="shrink-0 flex-grow-0 tab-content gap-0.5 flex flex-col ml-2">
      {orderedTurnIDs
        .filter(TurnTrackerFn.isInRound(orderedTurnIDs, resolvedTurnTracker.currentTurnID))
        .map((turnID) => <PlayerTurnView key={turnID} isCurrentTurn={resolvedTurnTracker.currentTurnID === turnID} value={turnSignals[turnID]!} />)}
      <div className="pointer-events-auto backdrop-blur-sm shrink-0 h-4 bg-zinc-900/80 flex items-center justify-center text-xs italic text-white/60 rounded-lg">Round {resolvedTurnTracker.round+1}</div>
      {orderedTurnIDs
        .filter(TurnTrackerFn.isNextRound(orderedTurnIDs, resolvedTurnTracker.currentTurnID))
        .map((turnID) => <PlayerTurnView key={turnID} isCurrentTurn={false} value={turnSignals[turnID]!} />)}
    </div>
  </div>
}
