import {Dnd5eCharacter} from "../dnd-5e-character.ts";
import {Dnd5eResource} from "../../dnd-5e-resource/dnd-5e-resource.ts";
import {Dnd5eActionTemplate, dnd5eActionTemplateType} from "../../dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {Dnd5eProcess} from "../../dnd-5e-action-definition/process/dnd-5e-process.ts";
import {Dnd5eActionTemplateModifier} from "../../dnd-5e-action-definition/modifier/dnd-5e-action-template-modifier.ts";
import {Dnd5eActionTemplateEffect} from "../../dnd-5e-action-definition/effect/dnd-5e-action-template-effect.ts";
import {Dnd5eFeature, dnd5eFeatureType} from "../../dnd-5e-feature/index.ts";
import {Dnd5eStatBlock} from "../../dnd-5e-stat-block/index.ts";
import {Dnd5eSpell, dnd5eSpellType} from "../dnd-5e-spell.ts";
import {dnd5eRaceType} from "../dnd-5e-race.js";
import {dnd5eCharacterClassType} from "../dnd-5e-character-class.js";
import {dnd5eBackgroundType} from "../dnd-5e-background.js";
import {dnd5eInventoryItemType} from "../dnd-5e-item.js";

function upgradeProcess(process: Dnd5eProcess, resourceNames: {[resourceID: string]: string}): Dnd5eProcess {
  if (process.type !== "adjust-custom-resource") return process;
  return ({
    ...process,
    data: {
      ...process.data,
      resource: resourceNames[process.data.resource] ?? process.data.resource
    }
  });
}
function upgradeModifier(modifier: Dnd5eActionTemplateModifier, resourceNames: {[resourceID: string]: string}): Dnd5eActionTemplateModifier {
  if (modifier.type !== "action::trigger::activation") return modifier;
  return ({
    ...modifier,
    data: {
      ...modifier.data,
      processes: modifier.data.processes.map(process => upgradeProcess(process, resourceNames))
    }
  });
}
function upgradeEffect(actionEffect: Dnd5eActionTemplateEffect, resourceNames: {[resourceID: string]: string}): Dnd5eActionTemplateEffect {
  return ({
    ...actionEffect,
    modifiers: actionEffect.modifiers.map(modifier => upgradeModifier(modifier, resourceNames))
  });
}

function upgradeAction(action: Dnd5eActionTemplate, resourceNames: {[resourceID: string]: string}): Dnd5eActionTemplate {
  const migratedAction = dnd5eActionTemplateType.migrateValue(action);
  return ({
    ...migratedAction,
    data: {
      ...migratedAction.data,
      modifiers: migratedAction.data.modifiers.map(modifier => upgradeModifier(modifier, resourceNames)),
      effects: migratedAction.data.effects.map(effect => upgradeEffect(effect, resourceNames))
    }
  });
}

function upgradeFeature(feature: Dnd5eFeature, resourceNames: {[resourceID: string]: string}): Dnd5eFeature {
  return ({
    ...feature,
    actions: feature.actions.map(action => upgradeAction(action, resourceNames)),
  });
}

function upgradeSpell(spell: Dnd5eSpell, resourceNames: {[resourceID: string]: string}) {
  return ({
    ...spell,
    actions: spell.actions.map(action => upgradeAction(action, resourceNames))
  });
}

export function actionTemplate_upgradeDnd5eStatBlock(statBlock: Dnd5eStatBlock): Dnd5eStatBlock {
  statBlock = {...statBlock};
  statBlock.features = statBlock.features.map(dnd5eFeatureType.migrateValue);
  statBlock.spells = statBlock.spells.map(dnd5eSpellType.migrateValue);

  const resourceNames: {[resourceID: string]: string} = [
    ...statBlock.features.flatMap(feature => feature.resources),
  ].reduce((map: {[resourceID: string]: string}, resource: Dnd5eResource) => {
    map[resource.resourceID] = resource.name;
    return map;
  }, {} satisfies {[resourceID: string]: string});

  return ({
    ...statBlock,
    features: statBlock.features.map(feature => upgradeFeature(feature, resourceNames)),
    spells: statBlock.spells.map(spell => upgradeSpell(spell, resourceNames))
  });
}

export function actionTemplate_upgradeDnd5eCharacter(character: Dnd5eCharacter): Dnd5eCharacter {
  character = {...character};
  character.features = character.features.map(dnd5eFeatureType.migrateValue);
  character.race = dnd5eRaceType.migrateValue(character.race);
  character.background = dnd5eBackgroundType.migrateValue(character.background);
  character.classes = character.classes.map(dnd5eCharacterClassType.migrateValue);
  character.inventory = character.inventory.map(dnd5eInventoryItemType.migrateValue);
  character.spells = character.spells.map(dnd5eSpellType.migrateValue);

  const resourceNames: {[resourceID: string]: string} = [
    ...character.features.flatMap(feature => feature.resources),
    ...character.race.features.flatMap(feature => feature.resources),
    ...character.background.features.flatMap(feature => feature.resources),
    ...character.classes.flatMap(clazz => clazz.features.flatMap(feature => feature.feature.resources)),
    ...character.inventory.flatMap(item => item.resources)
  ].reduce((map: {[resourceID: string]: string}, resource: Dnd5eResource) => {
    map[resource.resourceID] = resource.name;
    return map;
  }, {} satisfies {[resourceID: string]: string});

  return ({
    ...character,
    features: character.features.map(feature => upgradeFeature(feature, resourceNames)),
    background: ({
      ...character.background,
      features: character.background.features.map(feature => upgradeFeature(feature, resourceNames))
    }),
    race: ({
      ...character.race,
      features: character.race.features.map(feature => upgradeFeature(feature, resourceNames))
    }),
    classes: character.classes.map(clazz => ({
      ...clazz,
      features: clazz.features.map(feature => ({
        ...feature,
        feature: upgradeFeature(feature.feature, resourceNames)
      }))
    })),
    spells: character.spells.map(spell => upgradeSpell(spell, resourceNames)),
    inventory: character.inventory.map(item => ({...item, actions: item.actions.map(action => upgradeAction(action, resourceNames))}))
  });
}
