import {LightShapeSpotlight} from "common/legends/node/light/light-shape-spotlight.ts";
import React from "react";
import {Color, Point} from "common/types/generic/index.ts";
import {LightTextureShader} from "./light-texture-shader.tsx";
import {WallShader} from "../../../shader/wall-shader.tsx";
import {LightSpotlightShader} from "../../../shader/light/light-spotlight-shader.tsx";
import {WallLightBlend} from "./wall-light-blend.tsx";
import {WallTintShader} from "../../../shader/wall-tint-shader.tsx";

export function LightShapeSpotlightLightPass({value, origin}: {
  value: LightShapeSpotlight
  origin: Point
}) {
  return (<LightTextureShader>
    <LightSpotlightShader value={value} origin={origin}/>
    <WallTintShader origin={[0, 0]} />
    <WallLightBlend>
      <WallShader origin={[0, 0]} color={Color.CLEAR}/>
    </WallLightBlend>
  </LightTextureShader>);
}