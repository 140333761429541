import {Point, PointFn, PointOperation, pointType} from "../point/index.ts";
import {NumberOperation, numberType} from "../number/index.ts";
import {ObjectType, PropertyRef} from "../object/index.ts";
import {ExtractOperation, ExtractValue} from "../../type/index.ts";
import {ValueType} from "#common/types/index.ts";
import {MutableRef} from "#common/ref";
import {Matrix4x4} from "#common/math/matrix/matrix4x4.ts";

export const transformType = new ValueType(new ObjectType({
  position: pointType,
  scale: numberType,
  rotation: numberType
}));

export type Transform = ExtractValue<typeof transformType>;
export type TransformOperation = ExtractOperation<typeof transformType>;

export const Transform = {
  DEFAULT: {
    position: [0, 0],
    rotation: 0,
    scale: 1
  } as Transform,

  multiply: (a: Transform, b: Transform): Transform => {
    const r = b.rotation * Math.PI / 180;
    const newX = (a.position[0] - b.position[0]) / b.scale;
    const newY = (a.position[1] - b.position[1]) / b.scale;
    return ({
      position: [
        Math.cos(-r) * newX - Math.sin(-r) * newY,
        Math.sin(-r) * newX + Math.cos(-r) * newY
      ],
      rotation: a.rotation - b.rotation,
      scale: a.scale / b.scale
    });
  },

  divide: (a: Transform, b: Transform): Transform => {
    const r = b.rotation * Math.PI / 180;
    const newX = Math.cos(r) * a.position[0] - Math.sin(r) * a.position[1];
    const newY = Math.sin(r) * a.position[0] + Math.cos(r) * a.position[1];
    return ({
      position: [
        newX * b.scale + b.position[0],
        newY * b.scale + b.position[1]
      ],
      rotation: b.rotation + a.rotation,
      scale: a.scale * b.scale
    });
  },

  toMatrix4x4: (transform: Transform): Matrix4x4 => {
    const rot = Math.PI * transform.rotation / -180;
    return [
      Math.cos(rot) * transform.scale, Math.sin(rot) * transform.scale, 0, transform.position[0],
      -Math.sin(rot) * transform.scale, Math.cos(rot) * transform.scale, 0, transform.position[1],
      0, 0, 1, 0,
      0, 0, 0, 1
    ];
  }
};

const updatePosition = (operations: PointOperation[]): TransformOperation[] => [{type: "apply", operations: [{type: "update-position", operations}]}];
const updateRotation = (operations: NumberOperation[]): TransformOperation[] => [{type: "apply", operations: [{type: "update-rotation", operations}]}];

export function TransformSignals(value: MutableRef<Transform, TransformOperation[]>) {
  return ({
    position: PropertyRef<Transform, TransformOperation, Point, PointOperation>(
      value => value.position,
      operations => [{type: "apply", operations: [{type: "update-position", operations}]}]
    )(value),
    rotation: PropertyRef<Transform, TransformOperation, number, NumberOperation>(
      value => value.rotation,
      operations => [{type: "apply", operations: [{type: "update-rotation", operations}]}]
    )(value),
    scale: PropertyRef<Transform, TransformOperation, number, NumberOperation>(
      value => value.scale,
      operations => [{type: "apply", operations: [{type: "update-scale", operations}]}]
    )(value)
  });
}

export const TransformFn = {
  updatePosition,
  updateRotation,
  equals(a: Transform, b: Transform): boolean {
    return (
      PointFn.equals(a.position, b.position) &&
      a.scale === b.scale &&
      a.rotation === b.rotation
    );
  }
} as const;
