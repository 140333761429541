import {AssetViewportPropertiesSignals, useAssetToken, useEditorSelectedNodeIDs} from "./index.ts";
import {NodeDropZone, useTempRef} from "#lib/components/index.ts";
import React, {Suspense, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Grid, Node, PlayerRef, TokenSignals} from "common/legends/index.ts";
import {HSLA, Optional, Point} from "common/types/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {useInstance, useObservable} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {distinct, map, toPromise} from "common/observable";
import {measurementType} from "common/legends/measurement/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useAssetViewportProperties} from "./use-asset-viewport-properties.ts";
import {contextMenuType} from "../../../routes/game/context-menu/context-menu.ts";
import {usePlayer} from "../../../routes/game/use-player.ts";
import {useToolHandlers} from "../../../routes/game/use-tool-handlers.ts";
import {SheetReference} from "../../common/sheet/sheet-reference.ts";
import {LegendsWebGL2Canvas} from "../../../routes/game/index.ts";
import {SheetReferenceProvider} from "../../common/sheet/sheet-reference-context.ts";
import {SelectedNodeIDsProvider} from "../common/context/selected-node-i-ds.ts";
import {MutableRef, NOOP_SIGNAL, Ref} from "common/ref";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {useAsset} from "../../common/character/use-asset.ts";
import {useSuspendPresent} from "../../common/use-optional-signal.ts";
import {AssetView} from "./asset-view.tsx";
import {ProjectionProvider, ViewProvider} from "../common/context/pvm-context.ts";
import {EditorProvider, useEditor} from "../../panel/nav/editor/editor-context.ts";
import {AssetTokenSelectionRef, SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {useEditorTool} from "../../panel/nav/common/tool/tool-selector/use-editor-tool.ts";
import {EditorContextMenu} from "../../panel/nav/editor/editor-context-menu/editor-context-menu.tsx";
import {useVision} from "../common/context/use-vision.ts";
import {VisionFn} from "common/legends/asset/token/vision/vision.ts";
import {useActiveNodeID} from "../common/context/use-active-node-id.ts";

export function AssetViewport() {
  const assetViewportPropertiesRef = useSuspendPresent(useAssetViewportProperties());
  const {tokenReference, visionRef: editorVisionRef, view} = useMemo(() => AssetViewportPropertiesSignals(assetViewportPropertiesRef), [assetViewportPropertiesRef]);
  const {assetID} = useRefValue(tokenReference);
  const asset = useSuspendPresent(useAsset(assetID));
  const token = useSuspendPresent(useAssetToken(asset, useRefValue(tokenReference).tokenID));
  const {children} = useMemo(() => TokenSignals(token), [token]);

  const [size, setSize] = useState<[number, number]>([0, 0]);
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const element = divRef?.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver(() => {
      setSize([element.clientWidth, element.clientHeight]);
    });
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  }, [setSize]);


  const canvasRef = useRef<HTMLCanvasElement>(null);

  const editor = useEditor();
  const rootSelectionRef = useObservable<AssetTokenSelectionRef>(pipe(
    tokenReference.observe,
    map((reference): AssetTokenSelectionRef => ({
      type: "asset-token",
      storeId: reference.gameID,
      resourceId: reference.assetID,
      tokenId: reference.tokenID
    })),
    distinct<AssetTokenSelectionRef>(SelectionRef.equals)
  ), () => {
    if (editor.state.value?.type === "asset") {
      const reference = editor.state.value.data.tokenReference;
      return {type: "asset-token", storeId: reference.gameID, resourceId: reference.assetID, tokenId: reference.tokenID};
    } else throw new Error("Invalid Type.");
  }, [tokenReference]);

  const instance = useInstance();
  const ping = useCallback(async (p: Point, focus: boolean) => {
    const reference = await toPromise(tokenReference.observe);
    instance.stream(reference.gameID, {type: "ping", data: {
      type: "character",
      assetID: reference.assetID,
      tokenID: reference.tokenID,
      position: p,
      focus
    }}).catch(console.error);
  }, [instance, tokenReference]);

  const measurement = useTempRef(measurementType, undefined);

  const selectedNodeIDs = useEditorSelectedNodeIDs();
  const tool = useEditorTool();
  const contextMenu = useTempRef(contextMenuType, undefined);

  const player = usePlayer(useUserID()!);
  const {color} = useMemo(() => PlayerRef(player), [player]);
  const activeNodeIDRef = useActiveNodeID(editorVisionRef, selectedNodeIDs);
  const visionRef = useVision(editorVisionRef, activeNodeIDRef);
  const vision = useRefValue(visionRef);

  const isAccessible = useCallback((node: Node) => node.type === "wall" || node.type === "area" || visionRef.value.some(vision => (node.data.selectionMask & vision.accessMask) > 0), [visionRef]);
  const isVisible = useCallback((node: Node) => visionRef.value.some(vision => VisionFn.canSee(vision, node)), [visionRef]);
  const {onDrop, onWheel, onTouchStart, onTouchMove, onTouchEnd, ...toolHandlers} = useToolHandlers(
    canvasRef,
    rootSelectionRef,
    view,
    measurement,
    Grid.DEFAULT,
    children,
    selectedNodeIDs,
    NOOP_SIGNAL,
    tool,
    ping,
    useObservable(color.observe, [0, 1, 1, 1] as HSLA, [color]),
    contextMenu,
    isAccessible,
    isVisible
);

  const projection = useMemo<Matrix4f>(() => Matrix4f.orthogonal(
    -size[0]/2, size[0]/2,
    size[1]/2, -size[1]/2,
    -100, 100
  ), [size]);


  const sheetID = useComputedValue(token, token => token.sheetId);
  const sheetReference = useMemo((): Ref<Optional<SheetReference>> => {
    return new MutableRef({
      value(): Optional<SheetReference> {
        return sheetID ? {type: "link", assetID: tokenReference.value.assetID, sheetID} : undefined;
      },
      observe: pipe(
        tokenReference.observe,
        map((tokenReference): SheetReference | undefined => sheetID
          ? ({type: "link", assetID: tokenReference.assetID, sheetID})
          : undefined
        ),
        distinct()
      ),
      apply: () => Promise.reject("Unsupported")
    });
  }, [tokenReference.observe, sheetID]);

  useEffect(() => {
    const onBodyWheel = (ev: WheelEvent) => {
      if (ev.ctrlKey) return onWheel(ev);
      return true;
    };
    document.body.addEventListener("wheel", onBodyWheel, {passive: false});
    canvasRef.current?.addEventListener("wheel", onWheel, {passive: false});
    canvasRef.current?.addEventListener("touchstart", onTouchStart, {passive: false});
    canvasRef.current?.addEventListener("touchend", onTouchEnd, {passive: false});
    canvasRef.current?.addEventListener("touchcancel", onTouchEnd, {passive: false});
    canvasRef.current?.addEventListener("touchmove", onTouchMove, {passive: false});
    return () => {
      document.body.removeEventListener("wheel", onBodyWheel);
      canvasRef.current?.removeEventListener("wheel", onWheel);
      canvasRef.current?.removeEventListener("touchstart", onTouchStart);
      canvasRef.current?.removeEventListener("touchend", onTouchEnd);
      canvasRef.current?.removeEventListener("touchmove", onTouchMove);
      canvasRef.current?.removeEventListener("touchcancel", onTouchEnd);
    }
  }, [canvasRef, onWheel, onTouchStart, onTouchMove, onTouchEnd]);

  return (<div className="flex-1 overflow-hidden" ref={divRef}>
    <NodeDropZone storeId={rootSelectionRef.storeId} onDrop={onDrop} contextMenu={contextMenu}>
      <LegendsWebGL2Canvas ref={canvasRef} width={size[0]} height={size[1]} {...toolHandlers} tabIndex={0}>
        <Suspense fallback={<></>}>
          <EditorProvider value={useEditor()}>
            <SheetReferenceProvider value={sheetReference}>
              <SelectedNodeIDsProvider value={selectedNodeIDs}>
                <ProjectionProvider value={projection}>
                  <ViewProvider value={useRefValue(view)}>
                    <AssetView token={token} vision={vision} measurement={measurement} />
                  </ViewProvider>
                </ProjectionProvider>
              </SelectedNodeIDsProvider>
            </SheetReferenceProvider>
          </EditorProvider>
        </Suspense>
      </LegendsWebGL2Canvas>
    </NodeDropZone>
    <EditorContextMenu value={contextMenu} onDrop={onDrop} />
  </div>);
}
