import {Button, ButtonBar, useToggle} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import {applyAll, ListOperation, ListSignal, ListType, NumberOperation, RichTextFn} from "common/types/index.ts";
import {
  Dnd5eCharacterClassFeature,
  Dnd5eCharacterClassFeatureOperation,
  dnd5eCharacterClassFeatureType,
  Dnd5eFeature,
  Dnd5eFeatureOperation,
  dnd5eFeatureType,
  getFeatureID
} from "common/legends/index.ts";
import React, {useCallback, useMemo, useState} from "react";
import {ImportButton} from "#lib/components/button/import-button.tsx";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {Dnd5eFeatureEditor} from "./dnd-5e-feature-editor.tsx";
import {Dnd5eFeatureID, generateDnd5eFeatureID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-feature/dnd-5e-feature-id.ts";
import {InputList} from "#lib/components/list/input-list.tsx";
import {useCharacterClassFeatureByID} from "./use-feature-by-i-d.ts";
import {copyDnd5eFeature} from "common/legends/asset/sheet/dnd-5e/dnd-5e-feature/copy-dnd-5e-feature.ts";
import {InputDnd5eFeature} from "./input-dnd-5e-features.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {distinct, listIdentity, map} from "common/observable";
import {pipe} from "common/pipe";
import {MutableRef} from "common/ref";

type InputDnd5eCharacterClassFeatureLevelProps = {
  value: ListSignal<Dnd5eCharacterClassFeature, Dnd5eCharacterClassFeatureOperation>;
  level: number;
};
function InputDnd5eCharacterClassFeatureLevel({value, level}: InputDnd5eCharacterClassFeatureLevelProps) {
  const [editFeatureID, setEditFeatureID] = useState<Dnd5eFeatureID | undefined>(undefined);
  const onAddFeature = useCallback(() => {
    const featureID = generateDnd5eFeatureID();
    value.apply(prev => ListOperation.insert(
      prev.length,
      {level, feature: {featureID, enabled: true, title: "", source: "", description: RichTextFn.EMPTY, actions: [], actionTemplates: [], modifiers: [], resources: [], variables: [], effects: []}}
    )).then(_ => setEditFeatureID(featureID));
  }, [value, level]);

  const editSignal = useCharacterClassFeatureByID(value, editFeatureID);

  const levelFeatures = useMemo((): ListSignal<Dnd5eFeature, Dnd5eFeatureOperation> => {
    const valueFn = (value: Dnd5eCharacterClassFeature[]): Dnd5eFeature[] => {
      return value.filter(classFeature => classFeature.level === level).map(classFeature => classFeature.feature);
    };
    return new MutableRef({
      value() {return valueFn(value.value)},
      observe: pipe(value.observe, map(valueFn), distinct(listIdentity)),
      apply: fn => value.apply(prev => {
        const findProperIndex = (features: Dnd5eCharacterClassFeature[], index: number): number => {
          let counter = 0;
          for (let i = 0; i < features.length; i ++) {
            if (features[i].level === level) {
              if (counter === index) return i;
              counter ++;
            }
          }
          return -1;
        };
        const featuresType = new ListType(dnd5eCharacterClassFeatureType);

        let convertedOperations: ListOperation<Dnd5eCharacterClassFeature, Dnd5eCharacterClassFeatureOperation>[] = [];
        let value = prev;
        const operations = fn(valueFn(value));
        for (let o = 0; o < operations.length; o ++) {
          const operation = operations[o];
          switch (operation.type) {
            case "delete": {
              const index = findProperIndex(value, operation.index);
              const deleteOps = ListOperation.delete(index, value[index]);
              convertedOperations.push(...deleteOps);
              value = applyAll(featuresType, value, deleteOps);
              break;
            }
            case "insert": {
              const index = findProperIndex(value, operation.index);
              const insertValue: Dnd5eCharacterClassFeature = {level, feature: operation.item};
              const insertOps = ListOperation.insert(index === -1 ? value.length : index, insertValue);
              convertedOperations.push(...insertOps);
              value = applyAll(featuresType, value, insertOps);
              break;
            }
            case "set": {
              const index = findProperIndex(value, operation.index);
              const prevValue: Dnd5eCharacterClassFeature = {level, feature: operation.prev};
              const nextValue: Dnd5eCharacterClassFeature = {level, feature: operation.next};
              const setOps = ListOperation.set(index, prevValue, nextValue);
              convertedOperations.push(...setOps);
              value = applyAll(featuresType, value, setOps);
              break;
            }
            case "apply": {
              const index = findProperIndex(value, operation.index);
              const applyOps = ListOperation.apply<Dnd5eCharacterClassFeature, Dnd5eCharacterClassFeatureOperation>(index, [{type: "update-feature", operations: operation.operations}]);
              convertedOperations.push(...applyOps);
              value = applyAll(featuresType, value, applyOps);
              break;
            }
            case "move": {
              const fromIndex = findProperIndex(value, operation.fromIndex);
              const toIndex = findProperIndex(value, operation.toIndex);
              const moveOps = ListOperation.move(fromIndex, toIndex === -1 ? value.length : toIndex);
              convertedOperations.push(...moveOps);
              value = applyAll(featuresType, value, moveOps);
            }
          }
        }
        return convertedOperations;
      }).then(valueFn)
    });
  }, [value, level])


  return (<div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5">
      <SectionHeader className="flex-1">Level {level}</SectionHeader>
      <ButtonBar>
        <Button onClick={onAddFeature}><FaPlus/> New Feature</Button>
        <ExpandOptions>
          <ImportButton type={dnd5eFeatureType} onImport={(next) => {
            value.apply(prev => ListOperation.insert(prev.length, {level, feature: copyDnd5eFeature(next)}));
          }}>Import Feature</ImportButton>
        </ExpandOptions>
      </ButtonBar>
    </div>
    <InputList<Dnd5eFeature, Dnd5eFeatureOperation>
      accept="legends/feature"
      items={levelFeatures}
      itemKey={getFeatureID}
      copy={copyDnd5eFeature}
      ListItem={InputDnd5eFeature} />
    {editSignal && <Dnd5eFeatureEditor value={editSignal} onClose={() => setEditFeatureID(undefined)}/>}
  </div>);
}

export type InputDnd5eCharacterClassFeaturesProps = {
  value: ListSignal<Dnd5eCharacterClassFeature, Dnd5eCharacterClassFeatureOperation>;
  level: MutableRef<number, NumberOperation[]>;
};

export function InputDnd5eCharacterClassFeatures({value, level}: InputDnd5eCharacterClassFeaturesProps) {
  const currentLevel = useRefValue(level);
  const levels = Array.from(Array(20).keys()).map((_, index) => index + 1);
  const [expanded, toggleShowMore] = useToggle(false);
  return (<div className="flex flex-col gap-1">
    {levels.slice(0, currentLevel).map((item) => <InputDnd5eCharacterClassFeatureLevel key={item} value={value} level={item} />)}
    {!expanded && <Button onClick={toggleShowMore}>Show More...</Button>}
    {expanded && levels.slice(currentLevel).map((item) => <InputDnd5eCharacterClassFeatureLevel key={item} value={value} level={item} />)}
    {expanded && <Button onClick={toggleShowMore}>Show Less...</Button>}
  </div>);
}
