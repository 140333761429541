import {TokenNode} from "common/legends/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import React from "react";
import {Color, Transform} from "common/types/index.ts";
import {TextShader} from "#lib/gl-react/component/text-shader.tsx";
import {ElementHUDPass} from "./element-h-u-d-pass.tsx";
import {HealthIndicatorView} from "./health-indicator-view.tsx";
import {StatusIndicatorsView} from "./status-indicators-view.tsx";
import {SelectionIndicator} from "./selection-indicator.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {ModelProvider, usePVM} from "../../context/pvm-context.ts";
import {useAsset} from "../../../../common/character/use-asset.ts";
import {useIsNodeTurn} from "../../../../common/turn-tracker/use-is-current-turn.ts";
import {SheetReferenceProvider} from "../../../../common/sheet/sheet-reference-context.ts";
import {OpacityProvider, useOpacity} from "../../context/opacity-context.ts";
import {useTokenSheet, useTokenSheetReference} from "../../context/use-token-sheet.ts";
import {useIsSelected} from "../../context/use-is-selected.ts";
import {CurrentTokenNodeProvider} from "../../context/token-node-context.ts";

export function TokenElementHUDPass({value}: {
  value: TokenNode;
}) {
  const {projection, view, model} = usePVM();
  const asset = useRefValue(useAsset(value.tokenReference.assetID));

  const sheetReference = useTokenSheetReference(value);
  const sheet = useTokenSheet(value);

  const isNodeTurn = useObservable(useIsNodeTurn(value.id), false, [value.id]);

  const isSelected = useIsSelected(value.id);
  const token = !asset ? undefined : asset.tokens.find(token => token.tokenID === value.tokenReference.tokenID);
  const valueOpacity = value.opacity * useOpacity();
  let valueModel = Transform.divide(value.transform, model);
  const interfaceModel = {...valueModel, scale: valueModel.scale * value.interfaceScale};

  return (<CurrentTokenNodeProvider value={value}>
    <SheetReferenceProvider value={sheetReference}>
      <ModelProvider value={valueModel}>
        <OpacityProvider value={valueOpacity}>
          {token && <React.Fragment key={value.id}>
            {token.children.map((child) => <ElementHUDPass key={child.data.id} value={child} />)}

            <HealthIndicatorView
              projection={projection} model={interfaceModel} view={view}
              sheetRef={sheet}
              origin={Vector2.multiply(token.origin, 1 / value.interfaceScale)}
              size={Vector2.multiply(token.size, 1 / value.interfaceScale)}
              displayNumber={value.ownerIDs.length > 0}
              />

            <StatusIndicatorsView
              projection={projection} model={interfaceModel} view={view}
              value={sheet}
              opacity={valueOpacity}
              origin={Vector2.multiply(token.origin, 1 / value.interfaceScale)}
              size={Vector2.multiply(token.size, 1 / value.interfaceScale)} />

            {(isSelected || isNodeTurn) && <SelectionIndicator
                projection={projection} view={view} model={interfaceModel}
                origin={Vector2.multiply(token.origin, 1 / value.interfaceScale)}
                size={Vector2.multiply(token.size, 1 / value.interfaceScale)}
                repeatX={1} repeatY={1}
                color={isSelected ? Color.WHITE : Color.GREEN}/>}
            {isSelected && <SelectionIndicator
                projection={projection} view={view} model={interfaceModel}
                origin={[token.origin[0] - token.pivot[0] + 2, token.origin[1] - token.pivot[1] + 2]}
                size={[4, 4]}
                repeatX={1} repeatY={1}
                color={Color.GREEN} />}

            {value.ownerIDs.length > 0 && <TextShader projection={projection} view={view} model={Transform.divide({
              position: [
                ((token.size[0] || 0)/2 - (token.origin[0] || 0)),
                -(token.origin[1] || 32)-2
              ],
              scale: value.interfaceScale,
              rotation: 0
            }, valueModel)} size={22} hTextAlign="center" vTextAlign="top" value={value.name} />}
          </React.Fragment>}
          {[...value.children].reverse().map((child) => <ElementHUDPass key={child.data.id} value={child} />)}
        </OpacityProvider>
      </ModelProvider>
    </SheetReferenceProvider>
  </CurrentTokenNodeProvider>);
}
