import {ListOperation} from "common/types/index.ts";
import {NodeCondition, NodeConditionFn, NodeConditionOperation, NodeConditionTypes} from "common/legends/node/condition/node-condition.ts";
import {ButtonBar, ExpandableLabel, useToggle} from "#lib/components/index.ts";
import React from "react";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {ListControl} from "./list-control.ts";
import {NodeConditionEffectFields} from "./node-condition-effect-fields.tsx";
import {useListSignals} from "../../../../common/use-list-signals.ts";
import {MutableRef} from "common/ref";
import {useTypedRef} from "../../../../common/use-typed-ref.ts";
import {NodeConditionSelfFields} from "./node-condition-self-fields.tsx";
import {AddConditionButton} from "./add-condition-button.tsx";


type ConditionFieldProps = {
  value: MutableRef<NodeCondition, NodeConditionOperation[]>;
  controls: ListControl;
};
function ConditionField({value, controls}: ConditionFieldProps) {
  const [type, typedRef] = useTypedRef<NodeConditionTypes>(value);

  if (type === "effect") {
    return <NodeConditionEffectFields value={typedRef} controls={controls}/>
  } else if (type === "self") {
    return <NodeConditionSelfFields value={typedRef} controls={controls}/>
  } else {
    return <div className="h-8 text-red/40">Unknown Condition</div>
  }
}


export type ConditionsFieldProps = {
  value: MutableRef<NodeCondition[], ListOperation<NodeCondition, NodeConditionOperation>[]>;
}
export function ConditionsField({value}: ConditionsFieldProps) {
  const [expand, toggleExpand] = useToggle(false);
  const conditions = useListSignals(value, NodeConditionFn.getNodeConditionID, NodeConditionFn.copyNodeCondition);

  return <div className="flex flex-col gap-0">
    <ExpandableLabel expanded={expand} toggleExpand={toggleExpand}>
      <span className="flex-1 px-4 cursor-pointer" onClick={toggleExpand}>Conditions</span>
      <ButtonBar>
        <AddConditionButton valueRef={value} />
      </ButtonBar>
    </ExpandableLabel>
    {expand && <Fieldset>
      {conditions.length === 0
        ? <span className="text-white/50 italic text-sm text-center px-4 h-8 flex items-center justify-center">There are no conditions.</span>
        : conditions.map(([key, props]) => <ConditionField key={key} value={props.itemRef} controls={{
          remove: props.remove
        }} />)}
    </Fieldset>}
  </div>
}