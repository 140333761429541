import {MutableRef} from "common/ref";
import {LightShapeSprite, LightShapeSpriteOperation, LightShapeSpriteRef} from "common/legends/node/light/light-shape-sprite.ts";
import {InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {useMemo} from "react";
import {InputFile} from "#lib/components/input/input-file.tsx";
import {SizeField} from "../../size-field.tsx";
import {ColorField} from "#lib/components/input/color-field.tsx";

export function LightShapeSpriteFields({valueRef}: {
  valueRef: MutableRef<LightShapeSprite, LightShapeSpriteOperation[]>
}) {
  const {intensityRef, colorRef, fileRef, sizeRef} = useMemo(() => LightShapeSpriteRef(valueRef), [valueRef]);

  return <>
    <InputGroup className="flex-1">
      <InputGroupLabel>Color</InputGroupLabel>
      <ColorField className="flex-1" value={colorRef} />
    </InputGroup>

    <InputGroup className="flex-1">
      <InputGroupLabel>Intensity</InputGroupLabel>
      <InputNumber value={intensityRef} />
    </InputGroup>

    <InputGroup className="pr-0">
      <InputGroupLabel>Image</InputGroupLabel>
      <InputFile className="flex-1" value={fileRef} />
    </InputGroup>
    <SizeField value={sizeRef} />
  </>
}