import {GroupNode, GroupNodeOperation, GroupNodeSignals} from "common/legends/index.ts";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {NameField} from "../name-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {OpacityField} from "../opacity-field.tsx";
import {SelectionMaskField} from "../selection-mask-field.tsx";
import {InputTransform} from "./transform/input-transform.tsx";
import {useMemo} from "react";
import {OriginField} from "./origin-field.tsx";
import {SizeField} from "../size-field.tsx";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {MutableRef} from "common/ref";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";

export type GroupNodePropertiesProps = {
  value: MutableRef<GroupNode, GroupNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};
export function GroupNodeProperties({value, reference, pinned}: GroupNodePropertiesProps) {
  const {name, transform, size, origin, visibilityLayer, selectionMask, opacity} = useMemo(() => GroupNodeSignals(value), [value]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <BaseComponent><NameField value={name} reference={reference} pinned={pinned} /></BaseComponent>
    <BaseComponent><VisibilityLayerField value={visibilityLayer} /></BaseComponent>
    <BaseComponent><OpacityField value={opacity} /></BaseComponent>
    <BaseComponent><SelectionMaskField value={selectionMask} /></BaseComponent>
    <BaseComponent><InputTransform value={transform} /></BaseComponent>
    <BaseComponent><OriginField valueRef={origin} /></BaseComponent>
    <BaseComponent><SizeField value={size} /></BaseComponent>
  </div>
}