import {NodeId, Sheet, SheetOperation} from "common/legends/index.ts";
import {useCallback} from "react";
import {Observable, toPromise} from "common/observable";
import {Dice, DiceExpression} from "common/dice/index.ts";
import {generateMessageID, generateRollRequestID, QLabMessageID, RollRequestID, RollRequests, RollVariables} from "common/qlab/index.ts";
import {useInstance} from "#lib/qlab/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {encryptValue, Optional} from "common/types/index.ts";
import {Dnd5eSavingThrowType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-saving-throw-modifier.ts";
import {useGetNodeIcon} from "../../../../../common/use-get-node-icon.ts";
import {useGlobalFeatures} from "./use-global-features.ts";
import {getDnd5eSheetActiveModifiers} from "../../dnd-5e/common/dnd-5e-sheet-active-modifiers.ts";
import {getDnd5eSheetSavingThrowModifiers} from "../../dnd-5e/common/dnd-5e-sheet-saving-throw-modifiers.ts";
import {getActiveVariables} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";
import {useSeedTime} from "../../../../../../routes/game/use-seed-time.ts";
import {getDnd5eSavingThrowHasAdvantage, getDnd5eSavingThrowHasDisadvantage} from "../../dnd-5e/common/dnd-5e-sheet-saving-throw-proficiency.ts";
import {MutableRef} from "common/ref";
import {useMessageID} from "../../../../chat/message-context.ts";
import {useStoreID} from "../../../../../../routes/game/model/store-context.tsx";

export function useSendSavingThrow() {
  const instance = useInstance();
  const storeID = useStoreID();
  const userID = useUserID()!;
  const getNodeIcon = useGetNodeIcon();
  const time = useSeedTime();
  return async (nodeID: Optional<NodeId>, savingThrowType: Dnd5eSavingThrowType, formula: DiceExpression, referenceMessageID: Optional<QLabMessageID>, variables?: RollVariables) => {
    const icon = await getNodeIcon(nodeID);
    const messageID = generateMessageID(time());
    const rollID = generateRollRequestID();
    const encryptedRollRequests = await encryptValue({}, () => Object.entries({
      [rollID]: formula
    }).reduce((rollRequests, [rollId, expression]) => {
      rollRequests[rollId as RollRequestID] = {
        expression: expression,
        variables: variables || {},
        visibility: {
          audience: {},
          default: {
            canViewExpression: true,
            canViewResult: true
          }
        }
      };
      return rollRequests;
    }, {} as RollRequests));
    return instance.applyToMessage(storeID, messageID, _ => [{
      type: "set",
      prevValue: undefined,
      nextValue: {
        type: "dnd-5e-saving-throw-message",
        data: {
          userID,
          nodeID,
          icon,
          referenceMessageID,
          savingThrowType: savingThrowType,
          savingThrowRollID: rollID,
          rollRequests: encryptedRollRequests,
          rollResults: undefined
        }
      }
    }]);
  };
}

export function useRollSavingThrow(nodeID: Observable<NodeId | undefined>, sheet: MutableRef<Optional<Sheet>, SheetOperation[]>) {
  const sendSavingThrow = useSendSavingThrow();
  const globalEffects = useGlobalFeatures();
  const referenceMessageID = useMessageID();
  return useCallback(async (savingThrowType: Dnd5eSavingThrowType, forceAdvantage: boolean, forceDisadvantage: boolean) => {
    const resolvedNodeID = await toPromise(nodeID);
    const sheetValue = await toPromise(sheet.observe);
    const globalEffectsValue = await toPromise(globalEffects.observe);
    if (sheetValue === undefined) return;
    const activeModifiers = getDnd5eSheetActiveModifiers(sheetValue, globalEffectsValue);
    const hasHalflingLuck = activeModifiers.some(m => m.type === "trait" && m.data.trait === "halfling-luck");
    const variables = getActiveVariables(sheetValue, globalEffectsValue);

    const hasAdvantage = getDnd5eSavingThrowHasAdvantage(activeModifiers, savingThrowType);
    const hasDisadvantage = getDnd5eSavingThrowHasDisadvantage(activeModifiers, savingThrowType);

    let expressions: DiceExpression[] = [];
    if ((hasAdvantage && !forceDisadvantage) || (forceAdvantage && !forceDisadvantage)) expressions.push(Dice.assertDiceExpression(`2d20kh1${hasHalflingLuck ? "ro1" : ""}`));
    else if ((hasDisadvantage && !forceAdvantage) || (forceDisadvantage && !forceAdvantage)) expressions.push(Dice.assertDiceExpression(`2d20kl1${hasHalflingLuck ? "ro1" : ""}`));
    else expressions.push(Dice.assertDiceExpression(`1d20${hasHalflingLuck ? "ro1" : ""}`));

    const modifiers = getDnd5eSheetSavingThrowModifiers(sheetValue, globalEffectsValue, savingThrowType);
    if (modifiers && modifiers.length > 0) expressions.push(modifiers);

    let expression = Dice.assertDiceExpression(expressions.join("+"));
    await sendSavingThrow(resolvedNodeID, savingThrowType, expression, referenceMessageID, variables);
  }, [nodeID, sheet.observe, sendSavingThrow, referenceMessageID]);
}
