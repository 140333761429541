import {
  Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier,
  Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation,
  Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/saving-throw/dnd-5e-action-template-segment-saving-throw-difficulty-class-modifier.ts";
import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {InputListItemProps} from "#lib/components/list/input-list.tsx";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {ConnectDragPreview, ConnectDragSource} from "react-dnd";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import React, {useMemo} from "react";
import {FaCopy, FaTrash} from "react-icons/fa";
import {InputDiceExpression} from "../../../../dice-expression/input-dice-expression.tsx";


export function Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierListItem({item, remove, duplicate, dragRefPreview, dragHandlerRef}: InputListItemProps<Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier, Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation> & {
  dragHandlerRef: ConnectDragSource | ConnectDragPreview,
  dragRefPreview: ConnectDragSource | ConnectDragPreview
}) {
  const [expanded, toggleExpanded] = useToggle(false);
  const {expressionRef} = useMemo(() => Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierSignals(item), [item]);
  return <div className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 cursor-pointer" onClick={toggleExpanded}>Difficulty Class</SectionHeader>
      </ExpandableLabel>
      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Effect" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>

    {expanded && <Fieldset>
      <InputGroup>
        <InputGroupLabel>Expression</InputGroupLabel>
        <InputDiceExpression value={expressionRef} />
      </InputGroup>
    </Fieldset>}
  </div>
}