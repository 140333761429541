import {Button, ButtonBar, InputGroup, InputGroupLabel, Select} from "#lib/components/index.ts";
import React, {forwardRef, Suspense, useCallback, useMemo} from "react";
import {BooleanFn, ListOperation, Optional} from "common/types/index.ts";
import {Dnd5eSpellLevel, Sheet, SheetOperation} from "common/legends/index.ts";
import {distinct, map} from "common/observable";
import {pipe} from "common/pipe";
import {Dnd5eResource} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource.ts";
import {
  findDnd5eCharacterSheetResource,
  findDnd5eStatBlockSheetResource,
  useSheetActionAvailableResources,
  useSheetActionResources
} from "../../dnd-5e-action/use-sheet-action-resources.ts";
import {useItemQty} from "../../dnd-5e-action/use-sheet-items.ts";
import {useSelectedNodeIDRef} from "../../use-selected-sheet.ts";
import {DiceButton} from "#lib/components/button/dice-button.tsx";
import {FavoriteButton} from "#lib/components/button/favorite-button.tsx";
import {
  getMaxPactSlots,
  getMaxSpellSlotsByClasses,
  getMaxSpellSlotsBySpellCasterLevel,
  getPactSlotLevel
} from "common/legends/asset/sheet/dnd-5e/character/get-max-spell-slots-by-classes.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {Dnd5eSpellSlotLevel} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-spell-slot-level.ts";
import {MathExpressionFn} from "common/math/index.ts";
import {useSuspendPresent} from "../../../../../../common/use-optional-signal.ts";
import {useActionSourceReference} from "../../dnd-5e-action/get-action-signal.ts";
import {useSheetReference} from "../../../../../../common/sheet/sheet-reference-context.ts";
import {Dnd5eActionSource} from "../../../../../nav/common/quick-access-menu/dnd-5e/dnd-5e-action-source.ts";
import {useActionSourceEditors} from "../../../../../nav/common/use-action-source-editors.ts";
import {useDnd5eActionSource} from "../../../../../nav/common/quick-access-menu/dnd-5e/use-dnd-5e-action-source.ts";
import {MutableRef, Ref} from "common/ref";
import {
  Dnd5eActionTemplate,
  Dnd5eActionTemplateFn,
  Dnd5eActionTemplateOperation,
  Dnd5eActionTemplateSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {Dnd5eActionTemplateEffectID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/effect/dnd-5e-action-template-effect-id.ts";
import {useRollActionTemplate} from "../../dnd-5e-action/use-roll-template.ts";
import {
  Dnd5eActionTemplateEffect,
  Dnd5eActionTemplateEffectFn,
  Dnd5eActionTemplateEffectOperation
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/effect/dnd-5e-action-template-effect.ts";
import {getActiveVariables} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";
import {useGlobalFeatures} from "../../dnd-5e-action/use-global-features.ts";

function useSheetResource(sheet: Ref<Optional<Sheet>>, name: string): Ref<Optional<Dnd5eResource>> {
  return useMemo(() => {
    const valueFn = (sheet: Optional<Sheet>) => {
      if (sheet?.type === "dnd-5e-character") {
        return findDnd5eCharacterSheetResource(sheet.data, name);
      } else if (sheet?.type === "dnd-5e-stat-block") {
        return findDnd5eStatBlockSheetResource(sheet.data, name);
      } else {
        return undefined;
      }
    };
    return new MutableRef({
      value(): Optional<Dnd5eResource> {return valueFn(sheet.value)},
      observe: pipe(sheet.observe, map(valueFn), distinct()),
      apply: () => {throw new Error("Unsupported.")}
    });
  }, [sheet, name])
}

export type ItemResourceDisplayProps = {
  sheetRef: Ref<Optional<Sheet>>;
  name: string;
};

export function ItemResourceDisplay(props: ItemResourceDisplayProps) {
  const qty = useRefValue(useItemQty(props.sheetRef, props.name));
  return <span className="text-xs" title={props.name}>
    {qty}
  </span>
}


export type SpellResourceDisplayProps = {
  sheetRef: Ref<Optional<Sheet>>;
  level: Dnd5eSpellSlotLevel;
};

export function SpellResourceDisplay({sheetRef, level}: SpellResourceDisplayProps) {
  const spellLevel = useComputedValue(sheetRef, (sheet): Dnd5eSpellLevel => {
    if (sheet?.type === "dnd-5e-character") {
      return level === "pact-slot"
        ? getPactSlotLevel(sheet.data.classes)
        : level;
    } else if (sheet?.type === "dnd-5e-stat-block") {
      return level === "pact-slot" ? "1" : level;
    } else {
      return level === "pact-slot" ? "1" : level;
    }
  }, [level]);

  const [current, max] = useComputedValue(sheetRef, (sheet): [number, number] => {
    if (sheet?.type === "dnd-5e-character") {
      const maxPactSlots = getMaxPactSlots(sheet.data.classes, spellLevel);
      const maxSpellSlots = getMaxSpellSlotsByClasses(sheet.data.classes, spellLevel);
      return [
        Math.min(maxPactSlots, sheet.data.pactSlots) + Math.min(maxSpellSlots, sheet.data.spellSlots[spellLevel] || 0),
        maxPactSlots + maxSpellSlots
      ];
    } else if (sheet?.type === "dnd-5e-stat-block") {
      const maxSpellSlots = getMaxSpellSlotsBySpellCasterLevel(sheet.data.spellCasterLevel, spellLevel);
      return [
        Math.min(maxSpellSlots, sheet.data.spellSlots[spellLevel] || 0),
        maxSpellSlots
      ];
    } else {
      return [0,0];
    }
  }, [spellLevel]);
  return <span className="text-xs" title={`Spell Level ${spellLevel}`}>{current}/{max}</span>
}


export type ResourceResourceDisplayProps = {
  sheetRef: MutableRef<Optional<Sheet>, SheetOperation[]>;
  name: string;
};

export function ResourceResourceDisplay({sheetRef, name}: ResourceResourceDisplayProps) {
  const resourceRef = useSheetResource(sheetRef, name);
  const resource = useRefValue(resourceRef);
  const globalFeaturesRef = useGlobalFeatures();
  const variables = useRefValue(useMemo(() => MutableRef.all(sheetRef, globalFeaturesRef).map(([sheet, globalFeatures]) => getActiveVariables(sheet, globalFeatures)), [sheetRef, globalFeaturesRef]));
  return <span className="text-xs" title={name}>
    {resource?.current ? resource.current : 0}/{resource?.max ? MathExpressionFn.executeMathExpression(resource.max, variables) : 0}
  </span>
}

type Dnd5eCharacterCombatActionProps = {
  sheetRef: MutableRef<Optional<Sheet>, SheetOperation[]>;
  actionRef: MutableRef<Optional<Dnd5eActionTemplate>, Dnd5eActionTemplateOperation[]>;
};

export const LoadingDnd5eCharacterCombatAction = forwardRef(function LoadingDnd5eCharacterCombatAction(props: Dnd5eCharacterCombatActionProps, ref: React.Ref<HTMLDivElement>) {
  return (<Suspense>
    <Dnd5eCharacterCombatAction ref={ref} {...props} />
  </Suspense>);
});



export const Dnd5eCharacterCombatAction = forwardRef(function Dnd5eCharacterCombatAction({sheetRef, actionRef}: Dnd5eCharacterCombatActionProps, ref: React.Ref<HTMLDivElement>) {
  const presentActionRef = useSuspendPresent(actionRef);

  const {favoriteRef} = useMemo(() => Dnd5eActionTemplateSignals(presentActionRef), [actionRef]);
  const action = useRefValue(presentActionRef);

  const setActive = useCallback((actionEffectID: Dnd5eActionTemplateEffectID, active: boolean) => {
    presentActionRef.apply(prev => {
      const i = prev.data.effects.findIndex(m => m.actionEffectID === actionEffectID);
      if (i === -1) return [];
      return [{type: "custom", operations: [{type: "update-effects", operations: ListOperation.apply(i, [{
        type: "update-enabled", operations: BooleanFn.set(prev.data.effects[i].enabled, active)
      }])}]}];
    });
  }, [actionRef]);

  const actionResourcesRef = useSheetActionResources(sheetRef, presentActionRef);
  const actionResources = useRefValue(actionResourcesRef);

  const rollAction = useRollActionTemplate(useSelectedNodeIDRef(), sheetRef, presentActionRef);
  const actionEnabled = useRefValue(useSheetActionAvailableResources(sheetRef, presentActionRef));

  const effectGroups = new Set(action.data.effects.map(Dnd5eActionTemplateEffectFn.getGroup).filter(g => g !== undefined) as string[]);
  const groupValues = new Map([...effectGroups.values()].map(group => {
    const enabledEffects = action.data.effects.filter(effect => Dnd5eActionTemplateEffectFn.getGroup(effect) === group)
      .filter(effect => effect.enabled);
    return enabledEffects.length > 0 ? [group!, enabledEffects[0].actionEffectID] : [group!, undefined];
  }));

  const setGroupEffect = (group: string, actionEffectID: Optional<Dnd5eActionTemplateEffectID>) => {
    presentActionRef.apply(prev => {
      const groupEffects = prev.data.effects.filter(e => Dnd5eActionTemplateEffectFn.getGroup(e) === group);
      const disableEffects = groupEffects.filter(e => e.actionEffectID !== actionEffectID).filter(e => e.enabled);
      const enableEffects = groupEffects.filter(e => e.actionEffectID === actionEffectID).filter(e => !e.enabled);
      return [{type: "custom", operations:
        [{type: "update-effects", operations: [
          ...disableEffects.flatMap(e => ListOperation.apply(prev.data.effects.indexOf(e), [{
            type: "update-enabled",
            operations: BooleanFn.set(e.enabled, false)
          }])) satisfies ListOperation<Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation>[],
          ...enableEffects.flatMap(e => ListOperation.apply(prev.data.effects.indexOf(e), [{
            type: "update-enabled",
            operations: BooleanFn.set(e.enabled, true)
          }])) satisfies ListOperation<Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation>[]
        ]}]
      }] satisfies Dnd5eActionTemplateOperation[];
    });
  }

  const actionEditors = useActionSourceEditors();
  const actionSource: Optional<Dnd5eActionSource> = useActionSourceReference(useRefValue(useSheetReference()), action.data.actionTemplateID);
  const openActionSource = () => {
    actionEditors.apply(prev => {
      if (actionSource === undefined) return [];
      return ListOperation.insert(prev.length, actionSource);
    });
  };

  const source = useDnd5eActionSource(actionSource);
  const sourceName = useComputedValue(source, source => {
    return source?.item ?? source?.title ?? source?.name ?? "Unknown";
  });


  return (<div ref={ref} className="flex flex-row">
    <FavoriteButton value={favoriteRef} />
    <InputGroup className="flex-1" disabled={!actionEnabled}>
      <InputGroupLabel className="flex-1 cursor-pointer" title={`Open ${sourceName}`} onClick={openActionSource} disabled={!actionEnabled}>{Dnd5eActionTemplateFn.getAbbreviation(action).substring(Dnd5eActionTemplateFn.getAbbreviation(action).lastIndexOf("/") + 1).trim()}</InputGroupLabel>
    </InputGroup>

    {[...effectGroups.values()].map(group => <InputGroup key={group} className="gap-0.5 pl-2 pr-0">
      <InputGroupLabel className="px-0">
        {group}
        <Select value={groupValues.get(group) || ""} onChange={e => {
          const actionEffectID = e.target.value;
          setGroupEffect(group, actionEffectID === "" ? undefined : actionEffectID as Dnd5eActionTemplateEffectID);
        }}>
          <option value={""}></option>
          {action.data.effects.filter(a => Dnd5eActionTemplateEffectFn.getGroup(a) === group).map(effect => <option key={effect.actionEffectID} value={effect.actionEffectID}>{effect.name.substring(effect.name.indexOf(":") + 1).trim()}</option>)}
        </Select>
      </InputGroupLabel>
    </InputGroup>)}
    <div className="flex flex-row gap-0.5">
      {action.data.effects.filter(e => Dnd5eActionTemplateEffectFn.getGroup(e) === undefined).map(effect => <Button key={effect.actionEffectID} className="px-2" variant={effect.enabled ? "primary" : "tertiary"} title={effect.name} onClick={() => setActive(effect.actionEffectID, !effect.enabled)}>
        {effect.abbreviation}
      </Button>)}
    </div>
    {actionResources.map((m, index) => <InputGroup key={index} className="px-2">
      <InputGroupLabel disabled className="justify-center min-w-4">
        {m.type === "spell-slot" && <SpellResourceDisplay sheetRef={sheetRef} level={m.data.level} />}
        {m.type === "custom-resource" && m.data.name !== undefined && <ResourceResourceDisplay sheetRef={sheetRef} name={m.data.name} />}
        {m.type === "item" && <ItemResourceDisplay sheetRef={sheetRef} name={m.data.name} />}
      </InputGroupLabel>
    </InputGroup>)}
    <ButtonBar>
      <DiceButton disabled={!actionEnabled} title="Roll Action" onClick={ev => rollAction(ev.shiftKey, ev.ctrlKey)} />
    </ButtonBar>
  </div>);
});
