import {ValueFn} from "common/types/index.ts";
import {Button} from "#lib/components/index.ts";
import {NodeId} from "common/legends/index.ts";
import {useCallback} from "react";
import {FaWrench} from "react-icons/fa6";
import {WallToolCreateModeFn} from "../../../../common/tool/wall/wall-tool-create-mode.ts";
import {useEditorTool} from "../../../nav/common/tool/tool-selector/use-editor-tool.ts";

export type InputWallSegmentsProps = {
  nodeID: NodeId
}
export function InputWallGraph({nodeID}: InputWallSegmentsProps) {
  const tool = useEditorTool();
  const setWallEditMode = useCallback(() => {
    tool.apply(prev => ValueFn.set(prev, {type: "wall",data: {nodeID, mode: WallToolCreateModeFn.DEFAULT}}))
  }, [tool]);
  return <Button onClick={setWallEditMode}><FaWrench /> Edit Walls</Button>
}
